<ngx-spinner bdColor="rgba(0, 0, 0, 0.1)" size="large" color="var(--blue)" type="square-jelly-box"
             [fullScreen]="true">

  <br/><br/><p style="color: var(--blue); font-weight: bolder; font-size: large"> Cargando... </p>
</ngx-spinner>

<!-- FORMULARIO DE INFORMACIÓN EL EVENTO-->
<div id="event-info-form" class="container">
  <p style="text-align:center;padding-top:20px;">
    <img class="logo" src="/assets/img/aee.png" alt="AEE" align="middle">
  </p>

  <h1>
    {{title}}
  </h1>

  <hr/>
  <app-progress-bar step="3"></app-progress-bar>
  <hr/>

  <!-- Info. del asistente -->
  <h3 class="eb-heading" id="field_infoEvento" style="display: block;" *ngIf="!this.submit">Información del Evento</h3>

  <div  *ngIf="!this.error">
    <!--h4>{{this.evento?.descripcion}}</h4-->

      <!-- Programas -->
      <mat-tab-group>
        <mat-tab *ngFor="let programa of this.evento?.programas" label="{{programa.descripcion}}">
          <br/>
          <h4>
            <mat-checkbox color="primary" (change)="checkAllJornadas($event,programa)" [(ngModel)]="programa.checked">
            </mat-checkbox>
            &nbsp;&nbsp;Seleccionar programa completo
          </h4>

          <!-- Jornadas -->
          <div *ngFor="let jornada of programa.jornadas">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header class="jornada">
                <mat-panel-title>
                  <mat-checkbox color="primary"
                                (change)="checkAllSesiones($event,jornada)"
                                (click)="$event.stopPropagation()"
                                (keydown)="$event.stopPropagation()"
                                [(ngModel)]="jornada.checked"
                                *ngIf="this.evento.tipo>1">
                  </mat-checkbox>
                  &nbsp;<span style="color:darkblue;">Día {{jornada.fecha | date: 'dd/MM/yyyy'}} - {{jornada.descripcion}}</span>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <!-- Sesiones -->
              <div style="padding-left:30px">
                <ul *ngFor="let sesion of jornada.sesiones" style="list-style-type:none;">
                  <li>
                    <mat-checkbox color="primary"
                                  (click)="$event.stopPropagation()"
                                  (keydown)="$event.stopPropagation()"
                                  [(ngModel)]="sesion.checked"
                                  *ngIf="(sesion.grupoCoincidencia === null || sesion.grupoCoincidencia === undefined) && this.evento.tipo>2">
                    </mat-checkbox>
                    <span *ngIf="(sesion.grupoCoincidencia !== null && sesion.grupoCoincidencia !== undefined) && this.evento.tipo>2">
                      <mat-radio-button value="{{sesion.id}}"
                                        name="{{sesion.grupoCoincidencia}}"
                                        (click)="checkRadio(jornada,sesion)"
                                        [checked]="sesion.checked">
                      </mat-radio-button>
                    </span>
                    &nbsp;&nbsp;
                    <i>{{sesion.horaInicio | date: 'HH:mm'}} - {{sesion.horaFin | date: 'HH:mm'}}</i>&nbsp;&nbsp;{{sesion.descripcion}}
                  </li>
                </ul>
              </div>
            </mat-expansion-panel>
          </div>
        </mat-tab>
      </mat-tab-group>

      <hr *ngIf="this.evento?.adicionales.size>0"/>

      <!-- Adicionales -->
      <h3 *ngIf="this.evento?.adicionales.size>0">
        <mat-checkbox color="primary" (change)="checkAllAdicionales($event)"></mat-checkbox>
        &nbsp;&nbsp;Actividades Complementarias
      </h3>
      <ul *ngFor="let adicional of this.evento?.adicionales" style="list-style-type:none;">
        <li>
          <mat-checkbox color="primary"
                        (click)="$event.stopPropagation()"
                        (keydown)="$event.stopPropagation()"
                        [(ngModel)]="adicional.checked">
          </mat-checkbox>

          <i *ngIf="this.evento.tipo==2">{{adicional.jornadas[0]?.fecha | date: 'dd/MM/yyyy'}}</i>&nbsp;&nbsp;
          <i *ngIf="this.evento.tipo==3">{{adicional.jornadas[0]?.fecha | date: 'dd/MM/yyyy'}} >
            {{adicional.jornadas[0]?.sesiones[0]?.horaInicio | date: 'HH:mm'}} - {{adicional.jornadas[0]?.sesiones[0]?.horaFin | date: 'HH:mm'}}</i>
          &nbsp;&nbsp;{{adicional.descripcion}}
        </li>
      </ul>
      <hr/>
      <br/>

    <form [formGroup]="eventInfoForm" (ngSubmit)="onSubmit()">
      <!--Comentarios-->
      <div class="row">
        <div class="col" style="width:100%;">
          <mat-form-field appearance="outline" style="width:100%;">
            <mat-label>Comentarios</mat-label>
            <textarea matInput type="text" formControlName="comentariosForm" id="comentariosForm" style="display:inline-block" matTextareaAutosize matAutosizeMinRows=4 matAutosizeMaxRows=5></textarea>
          </mat-form-field>
        </div>
      </div>
      <hr/>
      <br/>


        <!-- Botones de accion -->
        <div class="row" style="text-align:center;">
          <div id="botones" class="col">
            <input type="submit" class="btn btn-default btn-primary" id="btn-submit-doInscription" value="Siguiente" ><!--disabled-->&nbsp;
            <input type="button" class="btn btn-default btn-secondary" id="btn-submit-cancel" value="Cancelar" (click)="cancel()">
          </div>
        </div>
      </form>
      <br/><br/>
  </div>

  <!-- En caso de error -->
  <div class="container" *ngIf="this.error">
    <div class="row">
      <div class="alert alert-danger col" style="text-align:center;">
        <i class="material-icons">error</i>
        Se ha producido un error en el proceso de inscripción y no se puede continuar. Por favor vuelva a intentar realizar la inscripción.
      </div>
    </div>
    <div class="row" style="text-align:center;">
      <div id="botones_error" class="col">
        <input type="button" class="btn btn-default btn-danger" id="btn-submit-error-cancel" value="Salir" (click)="cancel()">
      </div>
    </div>
  </div>
</div>



