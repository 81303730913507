<div class="row" [formGroup]="enterpriseForm">
  <div class="col">
    <mat-form-field appearance="outline" class="autocomplete-full-width">
      <mat-label>País <span class="star">&nbsp;*</span></mat-label>
      <input matInput
             type="text"
             placeholder="Seleccione un país"
             aria-label="Pais"
             formControlName="paisForm"
             [matAutocomplete]="auto"
             autocomplete="off">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let pais of filteredPaises | async" [value]="pais">
          {{pais}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</div>
