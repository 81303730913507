import { Injectable } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class RedsysApiService {
  baseApiUrl = environment.redsys.api.url;
  environment = environment;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json',
                               'Access-Control-Allow-Origin': '*' ,
                               'Access-Control-Allow-Headers': 'accept',
                               Language: navigator.language,
                               Authorization: 'Bearer ' + sessionStorage.getItem('token')})
  };

  constructor(private http: HttpClient) {}

  getRedsysApiData(paramImporte,contactosSelected,idProyecto,idCliente): Observable<any>
  {
    let urlOK=environment.redsys.api.urlOK+'?action=paymentOK'+
                                '&idProyecto='+idProyecto+
                                '&idCliente='+idCliente+
                                '&contactosSelected='+contactosSelected+
                                '&tipoPago=2&emailEnvio='+sessionStorage.getItem('email')+"&donacion="+sessionStorage.getItem('donacion');
    let urlKO=environment.redsys.api.urlKO+'?action=paymentKO';

    let dataReq = {
              fuc: environment.redsys.api.fuc,
              terminal: environment.redsys.api.terminal,
              moneda: environment.redsys.api.moneda,
              curr: environment.redsys.api.curr,
              trans: environment.redsys.api.trans,
              url: environment.redsys.api.url,
              urlOK: urlOK,
              urlKO: urlKO,
              version: environment.redsys.api.version,
              amount:paramImporte,
              signature:environment.redsys.api.kc
    }

    if (environment.debug) console.log(dataReq);
    return this.http.post<any>(this.baseApiUrl, dataReq, this.httpOptions).pipe(
      tap(_ => this.log('Get Redsys API Data OK')),
      catchError(this.handleError<any>('ERROR Get Redsys API Data'))
    );
  }

  decodeRedsysApiData(merchantParameters): Observable<any> {

    let dataReq = {
              merchantParameters:merchantParameters
    }

    if (environment.debug) console.log(dataReq);
    return this.http.post<any>(this.baseApiUrl, dataReq, this.httpOptions).pipe(
      tap(_ => this.log('Decode Redsys API Data OK')),
      catchError(this.handleError<any>('ERROR Decode Redsys API Data'))
    );
  }

  /** Log a TokenService message  */
  private log(message: string) {
    if (environment.debug) console.log(message);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead

      let errorGenerico = "Se ha producido un error, vuelva a intentarlo más tarde";

      if (error.message==null || error.message=='')
          error.message = errorGenerico;

      this.showError(errorGenerico);
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  public showError(errorMessage)
  {
      Swal.fire({
        icon: 'error',
        titleText: errorMessage,
        confirmButtonColor: 'var(--blue)',
        allowOutsideClick: false
      })
  }


}
