<ngx-spinner bdColor="rgba(0, 0, 0, 0.1)" size="large" color="var(--blue)" type="square-jelly-box"
             [fullScreen]="true">

  <br/><br/><p style="color: var(--blue); font-weight: bolder; font-size: large"> Cargando... </p>
</ngx-spinner>

<!-- CAMPAÑA DONACIONES UCRANIA -->
<div *ngIf="displayCampaignForm" [formGroup]="campaignForm" id="campaign-form" class="container">
    <p style="text-align:center;padding-top:20px;">
        <img class="logo" src="/assets/img/aee.png" alt="AEE" align="middle">
    </p>

    <hr/>

    <p style="text-align:center;padding-top:20px;">
        <img src="/assets/img/eolica.webp" style="width: 800px; height: 340px;" width="800" height="340">
    </p>

    <hr/>

    <b>{{this.evento.descripcion}}</b>
    <br/><br/>
    Seleccione una opción:<br/><br/>
    <mat-radio-group formControlName="donacion">
        <mat-radio-button [value]="1"
                          [checked]="true"
                          (change)="this.campaignForm.controls['donacion'].setValue(1)">
        </mat-radio-button><b>Inscribirse</b> al evento y <b>donar</b> comprando la Fila 0  <br/>

        <mat-radio-button [value]="2"
                          [checked]="false"
                          (change)="this.campaignForm.controls['donacion'].setValue(2)">
        </mat-radio-button><b>Fila 0</b> – No asistiré al evento, pero quiero donar la compra de la Fila 0 <br/>

        <mat-radio-button [value]="3"
                          [checked]="false"
                          (change)="this.campaignForm.controls['donacion'].setValue(3)">
        </mat-radio-button>Realizar la <b>inscripción</b> solamente al evento <br/>
    </mat-radio-group>
    <p style="text-align:center;">
        <input type="button" value="Continuar" id="btn-submit-email" class="btn btn-success" (click)="selectDonacion()">
    </p>
</div>

<!-- FORMULARIO INICIAL -->
<div id="init-form" class="container" *ngIf="displayInitForm" >
  <p style="text-align:center;padding-top:20px;">
    <img class="logo" src="/assets/img/aee.png" alt="AEE" align="middle">
  </p>

  <h1>
    {{title}}
  </h1>

  <hr/>
  <app-progress-bar step="1"></app-progress-bar>
  <hr/>


  <h3 class="">Introduzca la siguiente información para inscribirse en el evento: <b>{{this.evento.descripcion}}</b></h3>

  <div class="alert alert-info" *ngIf="!this.submit">
      <i class="material-icons">info</i>
      <strong>&nbsp;IMPORTANTE:&nbsp;</strong>
      El CIF o NIF de la empresa a facturar debe seguir el formato: Letra + Número SIN guiones ni espacio.<br/>
      <p style="font-size:small">
        (En el caso de ser persona física a quien se emita la factura el NIF debe seguir el formato Números + Letra SIN guiones ni espacios.
        En el caso de NIE debe seguir el formato Letra + Números + Letra SIN guiones ni espacios.
        Para entidades internacionales utilizar Código ISO del país + VAT number)
      </p>
  </div>
  <hr/>

  <!--Formulario-->
  <form [formGroup]="initForm" (ngSubmit)="onSubmit()">
      <div class="row" *ngIf="!this.submit">
          <div class="col" style="text-align:center;">
              <mat-form-field appearance="outline">
                  <mat-label>CIF de la Empresa <span class="star">&nbsp;*</span></mat-label>
                  <input matInput type="text" formControlName="cifForm" id="cifForm">
              </mat-form-field>

              <!--Boton -->
              &nbsp;<input type="submit" value="Buscar" id="btn-submit-init" class="btn btn-default btn-primary" [disabled]="!initForm.valid">
              <!--Texto Error -->
              <mat-error *ngIf="initForm.get('cifForm').hasError('required') && initForm.get('cifForm').touched">
                  <strong>&nbsp;<i class="material-icons">warning</i>&nbsp;ERROR:&nbsp;</strong><span>CIF Obligatorio</span>
              </mat-error>
              <mat-error *ngIf="initForm.get('cifForm').hasError('cifInvalid') && initForm.get('cifForm').touched">
                <strong>&nbsp;<i class="material-icons">warning</i>&nbsp;ERROR:&nbsp;</strong><span>CIF no valido</span>
              </mat-error>
          </div>
    </div>
    <hr *ngIf="!this.submit">
    <!--p  *ngIf="!this.submit" style="text-align:center;">
      <a (click)="this.displayInitForm=false;this.displayCampaignForm=true;"
         class="btn btn-default btn-secondary"
         type="btn">
          Atrás
      </a>
    </p-->
  </form>

  <div class="row" *ngIf="this.submit">
    <app-enterprise-form></app-enterprise-form>
  </div>
</div>
