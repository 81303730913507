export const environment = {
  production: true,
  debug: true,
  pruebas: true,
  timeout: 420000,
  campaignURL: 'https://www.accem.es/civicrm?cid=0&page=CiviCRM&q=civicrm/contribute/transact&reset=1&id=16',
  app: {
    title: 'Inscripciones a Eventos',
    titleRegistro: 'Registro en la AEE',
    phonePattern: '(\\+34|0034|34)?([0-9]){9}',
    cifPattern: /^[0-9a-zA-Z]+$/,
    //cifPattern: '([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])',
  },
  api: {
          //Produccion
            //url: 'https://erpprivada.aeeolica.org/AEE',
          //Pruebas Nestor
            //url: 'https://soporte.ahora.es/AEE',
          //Pruebas AZURE
            url: 'https://erpprivada.aeeolica.org/PRUEBAS_AEE',

  },
  redsys: {
    url: 'https://sis-t.redsys.es:25443/sis/realizarPago',
    api: {
      url: 'https://pruebas.aeeolica.org/tpv/restServiceRedsys.php',
      kc: 'sq7HjrUOBfKmC576ILgskD5srU870gJ7',
      fuc:"055697387",
      terminal:"001",
      moneda:"978",
      curr:"EUR",
      trans:"0",
      urlOK:"https://pruebas.aeeolica.org/end-form",
      urlKO:"https://pruebas.aeeolica.org/end-form",
      version:"HMAC_SHA256_V1",
    }
  }
};
