 import { Component, EventEmitter, Input, OnInit, OnChanges, Output, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RestApiService } from '../../services/rest-api.service';
import { UtilsService } from '../../services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ChangeDetectorRef } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Contacto } from '../../Model/Contacto';

import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface contacto {
      checked: boolean,
      idContacto: '',
      nombre:''
}

@Component({
  selector: 'app-add-contact-form',
  templateUrl: './add-contact-form.component.html',
  styleUrls: ['./add-contact-form.component.scss']
})

export class AddContactFormComponent implements OnInit, OnChanges  {

  @Input() contactoModify: Contacto;
  @Input() disabled:boolean;
  @Input() modal:boolean;

  contactosSelected = [];
  environment = environment;
  title = environment.app.title;
  contactForm: FormGroup;
  email = '';
  emailAdicional2 = '';
  idCliente = '';

  submit = false;


  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private restApiService: RestApiService,
              public  utils: UtilsService,
              private spinner: NgxSpinnerService,
              private cdRef:ChangeDetectorRef,
              private modalDialog: MatDialog) { }

  //Evita que se produzca el ERROR Error: ExpressionChangedAfterItHasBeenCheckedError
  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    this.spinner.show();

    this.email = sessionStorage.getItem("email");
    this.emailAdicional2 = sessionStorage.getItem("emailAdicional2");
    this.idCliente = sessionStorage.getItem("idCliente");

    this.initializeForm(); //Se hace despues de obtener los datos del cliente y el email para inicializar los valores del formulario con esa info.
    this.spinner.hide();
  }

  ngOnChanges() {
    if (this.contactoModify !== undefined && this.contactoModify !== null) {
        //Deshabilitamos la modificacion de contactos.
        this.disabled = true;

        this.contactForm.controls['emailContactoForm'].disable();
        this.contactForm.controls['emailContactoForm'].disable();
        this.contactForm.controls['nifContactoForm'].disable();
        this.contactForm.controls['nombreApellidosContactoForm'].disable();
        this.contactForm.controls['cargoContactoForm'].disable();
        this.contactForm.controls['telefonoFijoContactoForm'].disable();
        this.contactForm.controls['telefonoMovilContactoForm'].disable();
        this.contactForm.controls['emailAdicional1ContactoForm'].disable();
        this.contactForm.controls['emailAdicional2ContactoForm'].disable();
        this.contactForm.controls['facebookContactoForm'].disable();
        this.contactForm.controls['twitterContactoForm'].disable();
        this.contactForm.controls['linkedinContactoForm'].disable();
        this.contactForm.controls['skypeContactoForm'].disable();

        this.contactForm.controls['emailContactoForm'].patchValue(this.contactoModify?.email);
        this.contactForm.controls['nifContactoForm'].patchValue(this.contactoModify?.nif);
        this.contactForm.controls['nombreApellidosContactoForm'].patchValue(this.contactoModify?.nombre);
        this.contactForm.controls['cargoContactoForm'].patchValue(this.contactoModify?.cargo);
        this.contactForm.controls['telefonoFijoContactoForm'].patchValue(this.contactoModify?.telefonoFijo);
        this.contactForm.controls['telefonoMovilContactoForm'].patchValue(this.contactoModify?.telefonoMovil);
        this.contactForm.controls['emailAdicional1ContactoForm'].patchValue(this.contactoModify?.emailAdicional1);
        this.contactForm.controls['emailAdicional2ContactoForm'].patchValue(this.contactoModify?.emailAdicional2);
        this.contactForm.controls['facebookContactoForm'].patchValue(this.contactoModify?.facebook);
        this.contactForm.controls['twitterContactoForm'].patchValue(this.contactoModify?.twitter);
        this.contactForm.controls['linkedinContactoForm'].patchValue(this.contactoModify?.linkedin);
        this.contactForm.controls['skypeContactoForm'].patchValue(this.contactoModify?.skype);
    }
  }

  initializeForm() {

    if (this.emailAdicional2==null && this.email!==null) this.emailAdicional2 = this.email;
    this.contactForm = this.formBuilder.group({
      emailContactoForm: new FormControl('',Validators.required),
      nifContactoForm: new FormControl('',Validators.required),
      nombreApellidosContactoForm: new FormControl('',Validators.required),
      cargoContactoForm: new FormControl('',Validators.required),
      telefonoFijoContactoForm: new FormControl(''),//,Validators.pattern(environment.app.phonePattern)
      telefonoMovilContactoForm: new FormControl('',Validators.required),
      emailAdicional1ContactoForm: new FormControl(''),
      emailAdicional2ContactoForm: new FormControl(this.emailAdicional2),
      facebookContactoForm: new FormControl(''),
      twitterContactoForm: new FormControl(''),
      linkedinContactoForm: new FormControl(''),
      skypeContactoForm: new FormControl(''),
      sedeForm: new FormControl('',Validators.required),
      departamentoForm: new FormControl('',Validators.required),
      rgpdForm: new FormControl('',Validators.required)
    });
    //if (this.email) this.contactForm.controls['emailContactoForm'].disable();
    if (this.emailAdicional2) this.contactForm.controls['emailAdicional2ContactoForm'].disable();
  }

  onSubmit() {
    if (environment.debug) console.log("Submit Contact Form");
    this.submit = true;
    if (!this.disabled) {
        if (this.formValidator()) {
           let contacto = {} as Contacto;
           contacto.idCliente = this.idCliente;
           contacto.email = this.contactForm.controls['emailContactoForm'].value,
           contacto.nif = this.contactForm.controls['nifContactoForm'].value,
           contacto.nombre = this.contactForm.controls['nombreApellidosContactoForm'].value,
           contacto.cargo = this.contactForm.controls['cargoContactoForm'].value,
           contacto.sede = this.contactForm.controls['sedeForm'].value.id,
           contacto.departamento = this.contactForm.controls['departamentoForm'].value,
           contacto.telefonoFijo = this.contactForm.controls['telefonoFijoContactoForm'].value,
           contacto.telefonoMovil = this.contactForm.controls['telefonoMovilContactoForm'].value,
           contacto.emailAdicional1 = this.contactForm.controls['emailAdicional1ContactoForm'].value,
           contacto.emailAdicional2 = this.contactForm.controls['emailAdicional2ContactoForm'].value,
           contacto.facebook = this.contactForm.controls['facebookContactoForm'].value,
           contacto.twitter = this.contactForm.controls['twitterContactoForm'].value,
           contacto.linkedin = this.contactForm.controls['linkedinContactoForm'].value,
           contacto.skype = this.contactForm.controls['skypeContactoForm'].value

           this.restApiService.setContactData(contacto,false,false).subscribe(data => {
                if (data)
                {
                   this.submit = true;
                   this.restApiService.showSuccess("Contacto dado de alta correctamente.");
                   this.contactosSelected.push(data.Data.IdContacto[0].IdContacto);

                   sessionStorage.setItem('contactosSelected', JSON.stringify(this.contactosSelected));
                }else{
                   this.restApiService.showError("Ha ocurrido un error al guardar el contacto");
                }
           });
        }else{
            this.restApiService.showError("Debe completar los campos obligatorios.");
            this.submit = false;
        }
    }else{
      this.contactosSelected.push(this.contactoModify.id);
    }

    if (this.submit) {
        sessionStorage.setItem('contactosSelected', JSON.stringify(this.contactosSelected));
        if (this.modal!==undefined && this.modal==false) this.router.navigate(['event-info-form']);
        else this.closeModal();
    }
  }

  formValidator() {
      if (this.contactForm.get('emailContactoForm').hasError('required') ||
          this.contactForm.get('nifContactoForm').hasError('required') ||
          this.contactForm.get('nombreApellidosContactoForm').hasError('required') ||
          this.contactForm.get('cargoContactoForm').hasError('required') ||
          this.contactForm.get('sedeForm').hasError('required') ||
          this.contactForm.get('departamentoForm').hasError('required') ||
          this.contactForm.get('telefonoFijoContactoForm').hasError('required')||
          this.contactForm.get('telefonoMovilContactoForm').hasError('required')||
          this.contactForm.get('rgpdForm').hasError('required'))
            return false;
      else
            return true;
  }

  cancel() {
    if (environment.debug) console.log("Cancel");
    if (this.modal!==undefined && this.modal==false) location.reload();
    else this.closeModal();
  }

  closeModal() {
    this.modalDialog.closeAll();
  }


}
