<ngx-spinner bdColor="rgba(0, 0, 0, 0.1)" size="large" color="var(--blue)" type="square-jelly-box"
             [fullScreen]="true">

  <br/><br/><p style="color: var(--blue); font-weight: bolder; font-size: large"> Cargando... </p>
</ngx-spinner>

<!-- FORMULARIO DE CONTACTO-->
<div id="workgroup-form-cont" class="container">

  <p style="text-align:center;padding-top:20px;">
    <img class="logo" src="/assets/img/aee.png" alt="AEE" align="middle">
  </p>

  <h1>{{titleRegistro}}</h1>

  <hr/>
  <app-progress-bar-cont step="4"></app-progress-bar-cont>
  <hr/>
  <!-- *ngFor="let workGroup of workGroupsList" -->
         
  <hr>
  <div  *ngIf="!this.exito">
    <!-- Primero las listas de distribucion aqui (misma estructura de grupos de trabajo) -->

    <!-- Segundo el resto de grupos de trabajo -->
      <mat-tab-group>
        <mat-tab label="Seleccione sus grupos de trabajo">
          <br/>
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header >
                <mat-panel-title>
                  <mat-checkbox color="primary"
                                (click)="$event.stopPropagation()"
                                (keydown)="$event.stopPropagation()"
                                (change)="checkAllGroups($event)">
                  </mat-checkbox>
                  &nbsp;<span style="color:darkblue;">Grupos de Trabajo</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div *ngFor="let workGroup of workGroupsList">
                <ul style="list-style-type:none;">
                  <li>
                    <span *ngIf="workGroup.isSubgroup" style="margin-left:50px;"></span>
                    <mat-checkbox color="primary"
                                  (click)="$event.stopPropagation()"
                                  (keydown)="$event.stopPropagation()"
                                  [(ngModel)]="workGroup.checked"
                                  change>
                    </mat-checkbox>
                    <i></i>&nbsp;&nbsp;{{workGroup.descripcion}}
                  </li>
                </ul>
              </div>
            </mat-expansion-panel>
          <br/>
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header >
              <mat-panel-title>
                <mat-checkbox color="primary"
                              (click)="$event.stopPropagation()"
                              (keydown)="$event.stopPropagation()"
                              (change)="checkAllLists($event)">
                </mat-checkbox>
                &nbsp;<span style="color:darkblue;">Listas de Distribución</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngFor="let listDistri of listasDistriList">
              <ul style="list-style-type:none;">
                <li>
                  <span *ngIf="listDistri.isSubgroup" style="margin-left:50px;"></span>
                  <mat-checkbox color="primary"
                                (click)="$event.stopPropagation()"
                                (keydown)="$event.stopPropagation()"
                                [(ngModel)]="listDistri.checked"
                                change>
                  </mat-checkbox>
                  <i></i>&nbsp;&nbsp;{{listDistri.descripcion}}
                </li>
              </ul>
            </div>
          </mat-expansion-panel>
        </mat-tab>
      </mat-tab-group>

      <form [formGroup]="workForm" (ngSubmit)="onSubmit()">
        <hr>
        <!-- Botones de accion -->
        <div class="row" style="text-align:center;">
          <div id="botones" class="col">
            <input type="submit" class="btn btn-default btn-primary" id="btn-submit-doInscription" value="Finalizar Registro" [disabled]="!workForm.valid"><!--disabled-->&nbsp;
            <input type="button" class="btn btn-default btn-secondary" id="btn-submit-cancel" value="Cancelar" (click)="cancel()">
          </div>
        </div>
        <br/><br/>
      </form>
  </div>
  <div class="container" *ngIf="this.exito">
    <div class="row">
      <div class="alert alert-success col" style="text-align:center;">
        Sus datos se han guardado correctamente, ya puede cerrar la página.
      </div>
    </div>
  </div>
</div>
