import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RestApiService } from './services/rest-api.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  environment = environment;
  title = environment.app.title;

  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private restApiService: RestApiService
  ) {
  }

  ngOnInit(){
      //sessionStorage.clear();
  }
}
