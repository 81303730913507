import { Injectable } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})
export class RestApiService {
  environment = environment;

  httpOptions = {};

  constructor(private http: HttpClient) {
  }

  getApiBaseUrl() {
    if (sessionStorage.getItem('token')==null)
    {
        this.getToken().subscribe(data => {
            sessionStorage.setItem('token',data.access_token);
            this.httpOptions = {
                headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' ,
                                           'Access-Control-Allow-Headers': 'accept',
                                           'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
                                           Language: navigator.language,
                                           Authorization: 'Bearer ' + sessionStorage.getItem('token')})
            };
            return environment.api.url;
        });
    }else{
          this.httpOptions = {
            headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' ,
                                       'Access-Control-Allow-Headers': 'accept',
                                       'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
                                       Language: navigator.language,
                                       Authorization: 'Bearer ' + sessionStorage.getItem('token')})
          };
          return environment.api.url;
    }

  }

  getToken() {
    let httpOptions = {
      headers: new HttpHeaders({ 'Accept': 'application/json',
                                 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'})
    };
    const url = environment.api.url + '/token';
    const data = 'grant_type=password&username=admin&password=Scn*28860';
    return this.http.post<any>(url,data,httpOptions).pipe(
      tap(_ => console.log('Token Access Get OK')),
      catchError(this.handleError<any>('error get token'))
    );
  }


  getEventData(idProyecto) {
    const url = this.getApiBaseUrl() + '/webapi/object/Evento?filter=[IdProyecto]='+idProyecto;
    if (sessionStorage.getItem('token')!==null)
    {
      let httpOptions = {
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' ,
                                       'Access-Control-Allow-Headers': 'accept',
                                       'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
                                       Language: navigator.language,
                                       Authorization: 'Bearer ' + sessionStorage.getItem('token')})
      };
      this.httpOptions = httpOptions;

      return this.http.get<any>(url,this.httpOptions).pipe(
        tap(_ => console.log('Get Event Data OK')),
        catchError(this.handleError<any>('error get Event Data'))
      );
    }
  }

  getEventInfoData(idProyecto,idCliente) {
    const url = this.getApiBaseUrl() + '/webapi/list/Evento_Sesion/vistaTotal?filter=pes.IdProyecto='+idProyecto;
    return this.http.get<any>(url,this.httpOptions).pipe(
      tap(_ => console.log('Get Event Info Data OK')),
      catchError(this.handleError<any>('error get event info data'))
    );
  }

  getClientesAsociadosAContacto(idContacto){
    const url = this.getApiBaseUrl() + "/webapi/list/contacto/vista_Cliente_Contacto?filter=contactos.idcontacto='"+idContacto+"'";
    return this.http.get<any>(url,this.httpOptions).pipe(
      tap(_ => console.log('getClientesAsociadosAContacto OK')),
      catchError(this.handleError<any>('error getClientesAsociadosAContacto Data'))
    );
  }

  deleteAsociacionContactoCliente(idContacto, idCliente){
    let httpOptions = {
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' ,
                                 'Access-Control-Allow-Headers': 'accept',
                                 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
                                 Language: navigator.language,
                                 Authorization: 'Bearer ' + sessionStorage.getItem('token')})
    };
    let data = 'IdContacto='+ idContacto +'&IdCliente=' + idCliente;
    const url = this.getApiBaseUrl() + "/webapi/exec/pPers_FLX_Desasociar_Contacto";
    return this.http.post<any>(url, data, httpOptions).pipe(
    tap(_ => console.log('deleteAsociacionContactoCliente OK')),
    catchError(this.handleError<any>('error deleteAsociacionContactoCliente'))
    );
  }

  getGruposAInscribirContacto(idContacto){
    //const url = this.getApiBaseUrl() + "/webapi/list/contacto/gruposSubgruposAInscribir?filter=idcontacto='"+idContacto+"' AND pgt.ListaDistribucion = 0"; // and listadistribucion=0
    const url = this.getApiBaseUrl() + "/webapi/list/contacto/contactosGruposSubGruposTotal?filter=contactos.idcontacto='"+idContacto+"' AND Visible=1 AND ListaDistribucion=0"; // and listadistribucion=0

    return this.http.get<any>(url,this.httpOptions).pipe(
    tap(_ => console.log('getGruposAInscribirContacto OK')),
    catchError(this.handleError<any>('error getGruposAInscribirContacto'))
    );
  }

  getListasDistribucionAInscribirContacto(idContacto){
      //const url = this.getApiBaseUrl() + "/webapi/list/contacto/gruposSubgruposAInscribir?filter=idcontacto='"+idContacto+"' AND pgt.ListaDistribucion=1"; // and listadistribucion=1
      const url = this.getApiBaseUrl() + "/webapi/list/contacto/contactosGruposSubGruposTotal?filter=contactos.idcontacto='"+idContacto+"' AND Visible=1 AND ListaDistribucion=1";

      return this.http.get<any>(url,this.httpOptions).pipe(
      tap(_ => console.log('getGruposAInscribirContacto OK')),
      catchError(this.handleError<any>('error getGruposAInscribirContacto'))
      );
  }

  asociarContactoAGrupos(idContacto,docs){
    const url = this.getApiBaseUrl() + "/webapi/exec/Contacto_Asociar_aGrupo";
        const data =  'IdContacto='+idContacto
                    + '&IdDocObjetos='+docs.toString().replaceAll(",","|");

        return this.http.post<any>(url,data,this.httpOptions).pipe(
    tap(_ => console.log('getGruposAInscribirContacto OK')),
    catchError(this.handleError<any>('error getGruposAInscribirContacto'))
    );
  }

  desasociarGrupoContacto(idContacto,idDoc,tipoObjeto){
      const url = this.getApiBaseUrl() + "/webapi/exec/Desasociar_ContactosObjeto_General";
          const data =  'IdContacto='+idContacto
                      + '&IdDocObjeto='+idDoc
                      + '&Objeto='+tipoObjeto;

          return this.http.post<any>(url,data,this.httpOptions).pipe(
      tap(_ => console.log('getGruposAInscribirContacto OK')),
      catchError(this.handleError<any>('error getGruposAInscribirContacto'))
      );
  }

  enviarCodigoVerificacion(idContacto){
      const url = this.getApiBaseUrl() + "/webapi/exec/Pers_FLX_Enviar_Codigo_Verificacion";
          const data =  'IdContacto='+idContacto;

          return this.http.post<any>(url,data,this.httpOptions).pipe(
      tap(_ => console.log('enviarCodigoVerificacion OK')),
      catchError(this.handleError<any>('error enviarCodigoVerificacion'))
      );
  }

  comprobarCodigoVerificacion(idContacto,codigo){
        const url = this.getApiBaseUrl() + "/webapi/exec/Pers_FLX_Validar_Contacto";
            const data =  'IdContacto='+idContacto
                        + '&Codigo='+codigo;

            return this.http.post<any>(url,data,this.httpOptions).pipe(
        tap(_ => console.log('enviarCodigoVerificacion OK')),
        catchError(this.handleError<any>('error enviarCodigoVerificacion'))
        );
  }

  getEnterpriseData(cif) {
    //const url = this.getApiBaseUrl() + "/webapi/list/Cliente?filter=[Nif]='"+cif+"'";
    const url = this.getApiBaseUrl() + "/webapi/list/cliente/ClienteDefaultList?filter=Clientes_datos.NIF='"+cif+"'";
    return this.http.get<any>(url,this.httpOptions).pipe(
      tap(_ => console.log('Get Enterprise Data OK')),
      catchError(this.handleError<any>('error get Enterprise Data'))
    );
  }

  getSedesData(idCliente) {
    const url = this.getApiBaseUrl() + "/webapi/list/Sede?filter=[padre]='"+idCliente+"'";
    return this.http.get<any>(url,this.httpOptions).pipe(
      tap(_ => console.log('Get Sedes Data OK')),
      catchError(this.handleError<any>('error get Sedes Data'))
    );
  }

  setEnterpriseData(empresa) {
    if (environment.debug) console.log("Guardando cliente (empresa)");
    let httpOptions = {
      headers: new HttpHeaders({ 'Accept': 'application/json',
                                 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'})
    };

    const url = this.getApiBaseUrl() + "/webapi/exec/Pers_FLX_Insertar_Cliente_Desde_Web";
    const data =  'Nif='+empresa.cif
                + '&RazonSocial='+empresa.razonSocial
                + '&Cliente='+empresa.nombreComercial
                + '&CodPostal='+empresa.codigoPostal
                + '&PaisDescrip='+empresa.pais
                + '&ProvinciaDescrip='+empresa.provincia
                + '&PoblacionDescrip='+empresa.poblacion
                + '&Direccion='+empresa.direccion
                + '&E_Mail='+empresa.email
                + '&NumTelefono='+empresa.telefono
    return this.http.post<any>(url,data,this.httpOptions).pipe(
      tap(_ => console.log('Set Enterprise Data OK')),
      catchError(this.handleError<any>('error Set Enterprise Data'))
    );
  }

   setContactoASedeCliente(clienteEmpresa) {
    if (environment.debug) console.log("Guardando cliente a empresa");
    let httpOptions = {
      headers: new HttpHeaders({ 'Accept': 'application/json',
                                 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'})
    };

    const url = this.getApiBaseUrl() + "/webapi/exec/Asociar_Contacto_a_SedeCliente";
    const data =  'IdContacto='+clienteEmpresa.idContacto
                + '&IdCliente='+clienteEmpresa.idCliente
                + '&IdDocObjeto='+clienteEmpresa.idDocObjeto
                + '&Objeto=Planta'
                + '&IdTipo='+clienteEmpresa.idTipo
                + '&Departamento='+clienteEmpresa.departamento
    return this.http.post<any>(url,data,this.httpOptions).pipe(
      tap(_ => console.log('Set Cliente Empresa Data OK')),
      catchError(this.handleError<any>('error Set Cliente Empresa Data'))
    );
  }

  setSedeData(empresa) {
    if (environment.debug) console.log("Guardando sede (empresa)");
    let httpOptions = {
      headers: new HttpHeaders({ 'Accept': 'application/json',
                                 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'})
    };

    const url = this.getApiBaseUrl() + "/webapi/exec/Pers_FLX_Insertar_Sede_Desde_Web";
    const data =  'IdClientePadre='+empresa.id
                + '&Direccion='+empresa.direccion
                + '&E_Mail='+empresa.email
                + '&CodPostal='+empresa.codigoPostal
                + '&PaisDescrip='+empresa.pais
                + '&ProvinciaDescrip='+empresa.provincia
                + '&PoblacionDescrip='+empresa.poblacion
    return this.http.post<any>(url,data,this.httpOptions).pipe(
      tap(_ => console.log('Set Sede Data OK')),
      catchError(this.handleError<any>('error Set Sede Data'))
    );
  }

  getContactData(email,idEmpresa) {
    const url = this.getApiBaseUrl() + "/webapi/list/Contacto_Cliente?filter=[IdClientePadre]='"+idEmpresa+"' and ([E_Mail]='"+email+"' or [Pers_E_Mail_Adicional]='"+email+"' or [Pers_E_Mail_Adicional2]='"+email+"')";
    return this.http.get<any>(url,this.httpOptions).pipe(
      tap(_ => console.log('Get Contact Data OK')),
      catchError(this.handleError<any>('error get Contact Data'))
    );
  }
  getContactDataByNif(nif) {
    const url = this.getApiBaseUrl() + "/webapi/list/Contacto_Cliente?filter=[NIF]='"+nif+"'";
    return this.http.get<any>(url,this.httpOptions).pipe(
        tap(_ => console.log('Get Contact Data by NIF OK')),
        catchError(this.handleError<any>('error get Contact Data by NIF'))
    );
  }
  getContactDataByEmail(email) {
      const url = this.getApiBaseUrl() + "/webapi/list/Contacto_Cliente?filter=[E_Mail]='"+email+"'";
      return this.http.get<any>(url,this.httpOptions).pipe(
          tap(_ => console.log('Get Contact Data by Email OK')),
          catchError(this.handleError<any>('error get Contact Data by Email'))
      );
  }

  setContactData(contacto,isModify, esTomaDeDatos) {
    if (environment.debug) console.log("Guardando contacto");

    let httpOptions = {
      headers: new HttpHeaders({ 'Accept': 'application/json',
                                 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'})
    };

    let url = this.getApiBaseUrl();

    if (!isModify)
        url = url + "/webapi/exec/Pers_FLX_Insertar_Contacto_Desde_Web";
    else
        url = url + "/webapi/object/Contacto/"+contacto.id;

    let data =  '';
    if (!isModify && !esTomaDeDatos){
        data = data + 'Padre='+contacto.sede+"&"; //TODO NOTA- Esto no se enviará en la toma de datos.
        data = data + '&Pers_IdDepartamento='+contacto.departamento+"&";
    }

    data = data + 'NIF='+contacto.nif
                + '&Nombre='+contacto.nombre
                + '&Cargo='+contacto.cargo
                + '&TelefonoTrabajo='+contacto.telefonoFijo
                //+ '&ExtensionTrabajo='+contacto.extensionTfno //??
                //+ '&TelefonoCasa='+contacto.telefonoCasa //??
                + '&TelefonoMovil='+contacto.telefonoMovil
                + '&E_Mail='+contacto.email
                + '&Pers_E_Mail_Adicional='+contacto.emailAdicional1
                + '&Pers_E_Mail_Adicional2='+contacto.emailAdicional2
                //+ '&Observaciones='+contacto.observaciones //??
                + '&Pers_Skype='+contacto.skype
                + '&Pers_Twitter='+contacto.twitter
                + '&Pers_Facebook='+contacto.facebook
                + '&Pers_LinkedIn='+contacto.linkedin;

    let retorno = null;

    if (!isModify)
        retorno = this.http.post<any>(url,data,this.httpOptions).pipe(
          tap(_ => console.log('Set Contact Data OK')),
          catchError(this.handleError<any>('error Set Contact Data')));
    else
        retorno = this.http.put<any>(url,data,this.httpOptions).pipe(
              tap(_ => console.log('Set Contact Data OK')),
              catchError(this.handleError<any>('error Set Contact Data')));

    return retorno;
  }

  getDepartamentosData() {
    const url = this.getApiBaseUrl() + "/webapi/list/Departamento_Contacto/DepartamentosContacto";
    return this.http.get<any>(url,this.httpOptions).pipe(
        tap(_ => console.log('Get Departamentos Data OK')),
        catchError(this.handleError<any>('error get Departamentos Data'))
    );
  }

  getCountriesData() {
    const url = this.getApiBaseUrl() + "/webapi/list/Geo_Paises_Obj/Paises";
    return this.http.get<any>(url,this.httpOptions).pipe(
      tap(_ => console.log('Get Countries Data OK')),
      catchError(this.handleError<any>('error get Countries Data'))
    );
  }

  getProvincesData(idPais) {
    if (idPais!==null)
    {
      const url = this.getApiBaseUrl() + "/webapi/list/Geo_Paises_Obj/Provincias?filter=Geo_Paises.IdPais='"+idPais+"'";
      return this.http.get<any>(url,this.httpOptions).pipe(
        tap(_ => console.log('Get Provinces Data OK')),
        catchError(this.handleError<any>('error get Provinces Data'))
      );
    }
  }

  getPopulationsData(idPais,idProvincia) {
    if (idPais!==null && idProvincia!==null)
    {
      const url = this.getApiBaseUrl() + "/webapi/list/Geo_Paises_Obj/Poblaciones?filter=Geo_Paises.IdPais='"+idPais+"' AND Geo_Provincias.IdProvincia='"+idProvincia+"'";
      return this.http.get<any>(url,this.httpOptions).pipe(
        tap(_ => console.log('Get Populations Data OK')),
        catchError(this.handleError<any>('error get Populations Data'))
      );
    }
  }

  getPostalCodesData(idPais,idProvincia,idPoblacion) {
    if (idPais!==null && idProvincia!==null && idPoblacion!==null)
    {
      const url = this.getApiBaseUrl() + "/webapi/list/Geo_Paises_Obj/CodPostales?filter=Geo_Paises.IdPais='"+idPais+"' AND Geo_Provincias.IdProvincia='"+idProvincia+"' AND Geo_Poblaciones.IdPoblacion='"+idPoblacion+"'";
      return this.http.get<any>(url,this.httpOptions).pipe(
        tap(_ => console.log('Get PostalCodes Data OK')),
        //catchError(this.handleError<any>('error get PostalCodes Data')) //TODO Comentamos temporalmente
      );
    }
  }

  calcularPrecio(idProyecto,idContacto,idCliente,docs) {
    if (environment.debug) console.log("Calcular Precio");
    const url = this.getApiBaseUrl() + "/webapi/exec/pPers_FLX_calculaPrecioInscripcionPorAsistente";
    const data =  'IdProyecto='+idProyecto
                + '&IdContacto='+idContacto
                + '&IdCliente='+idCliente
                + '&IdDocs='+docs

    return this.http.post<any>(url,data,this.httpOptions).pipe(
      tap(_ => console.log('Calcular Precio OK')),
      catchError(this.handleError<any>('error Calcular Precio Data'))
    );
  }

  guardarInscripcion(idProyecto,contactosSelected,docs,idCliente,comentarios) {
    if (environment.debug) console.log("Guardar Inscripcion");
    const url = this.getApiBaseUrl() + "/webapi/exec/Pers_FLX_Evento_Inscripcion";
    const data =  'IdProyecto='+idProyecto
                + '&IdContactos='+contactosSelected
                + '&IdDocs='+docs
                + '&IdCliente='+idCliente
                + '&Observaciones='+comentarios

    return this.http.post<any>(url,data,this.httpOptions).pipe(
      tap(_ => console.log('Guardar Inscripcion OK')),
      catchError(this.handleError<any>('error Guardar Inscripcion Data'))
    );
  }

  finalizarInscripcion(contactosSelected,idProyecto,tipoPago,idCliente,idTicket,idCompra,idTransaccion,emailEnvio) {
    if (environment.debug) console.log("Guardar Inscripcion");
    const url = this.getApiBaseUrl() + "/webapi/exec/Pers_FLX_Evento_Inscripcion_Finalizar";
    const data =  'IdContactos='+contactosSelected
                + '&IdProyecto='+idProyecto
                + '&TipoPago='+tipoPago
                + '&IdCliente='+idCliente
                + '&IdTicket='+idTicket
                + '&IdCompra='+idCompra
                + '&IdTransaccion='+idTransaccion
                + '&EmailEnvio='+emailEnvio;

    return this.http.post<any>(url,data,this.httpOptions).pipe(
      tap(_ => console.log('Finalizar Inscripcion OK')),
      catchError(this.handleError<any>('error Finalizar Inscripcion Data'))
    );
  }

  /** Log a TokenService message  */
  private log(message: string) {
    if (environment.debug) console.log(message);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead

      let errorGenerico = "Se ha producido un error, vuelva a intentarlo más tarde";

      if (error.message==null || error.message=='')
          error.message = errorGenerico;

      this.showError(errorGenerico);
      if (environment.debug) console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  public showError(errorMessage) {
    Swal.fire({
        icon: 'error',
        titleText: errorMessage,
        confirmButtonColor: 'var(--blue)',
        allowOutsideClick: false
    });
  }

  public showSuccess(message) {
    Swal.fire({
        icon: 'success',
        titleText: message,
        confirmButtonColor: 'var(--blue)',
        allowOutsideClick: false
    });
  }

  public showWarning(message) {
    Swal.fire({
        icon: 'warning',
        titleText: message,
        confirmButtonColor: 'var(--blue)',
        allowOutsideClick: false
    });
  }

}
