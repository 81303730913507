<div class="row" [formGroup]="departamentoForm">
  <div class="col" *ngIf="departamentos!==null && departamentos!==undefined && departamentos.length>0">
    <mat-form-field appearance="outline">
      <mat-label>Departamento <span class="star" *ngIf="this.required">&nbsp;*</span></mat-label>
      <mat-select formControlName="departamentoForm" class="multiple-selector" [multiple]="multiple"  [disabled]="this.disabled"
                  [disableOptionCentering]="true" #selectDepartamentos style="width:350px;">
        <mat-option value="0">
          <ngx-mat-select-search placeholderLabel="Buscar"
                                 [showToggleAllCheckbox]="false"
                                 [formControl]="departamentoFilter"
                                 noEntriesFoundLabel="No se han encontrado datos">
          </ngx-mat-select-search>
        </mat-option>

        <ng-container *ngFor="let departamento of departamentos  | searchFilter:departamentoFilter.value">
          <mat-option [value]="departamento.id" title="{{departamento.nombre}}">
            {{departamento.nombre}}
          </mat-option>
        </ng-container>
        <!--/div-->
      </mat-select>
    </mat-form-field>
  </div>
</div>
