import { AbstractControl, ValidatorFn } from "@angular/forms";

export function NifValidator(): ValidatorFn
{
  return (control: AbstractControl): { [key: string]: any } => {
    var resultado: boolean = isValidNIFNIE(control.value);
    return resultado ? { cifInvalid: { value: control.value } } : null;
  };
}

function isValidNIFNIE(value)
{
  //TODO Revisar... no funciona!
			var nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
			var nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
			var str = value.toString().toUpperCase();

			if (nifRexp.test(str) || nieRexp.test(str)) {
			  return false;
			}else{
        if (!nieRexp.test(str)) {
          return true;
        }else{
          var nie = str
            .replace(/^[X]/, '0')
            .replace(/^[Y]/, '1')
            .replace(/^[Z]/, '2');

          var letter = str.substr(-1);
          var charIndex = parseInt(nie.substr(0, 8)) % 23;

          var validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
          if (validChars.charAt(charIndex) === letter){
            return true;
          }
        }
      }
			return false;
}

