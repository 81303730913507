import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RestApiService } from '../../services/rest-api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { ChangeDetectorRef } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Empresa } from '../../Model/Empresa';
import { Pais } from '../../Model/Pais';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
//import { PaisInputComponent } from './pais-input/pais-input.component';

@Component({
  selector: 'app-add-enterprise-form-cont',
  templateUrl: './add-enterprise-form-cont.component.html',
  styleUrls: ['./add-enterprise-form-cont.component.scss']
})

export class AddEnterpriseFormContComponent implements OnInit {

  @Input() modal:boolean;
  environment = environment;

  enterpriseForm: FormGroup;
  empresa: Empresa;
  cif: string;
  idCliente= '';
  submit = false;
  paisSelected: string;
  provinciaSelected: string;
  poblacionSelected: string;
  codigoPostalSelected: number;

//  @ViewChild(PaisInputComponent) paisComponent: PaisInputComponent;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private restApiService: RestApiService,
              private spinner: NgxSpinnerService,
              private cdRef:ChangeDetectorRef,
              private modalDialog: MatDialog) { }

  //Evita que se produzca el ERROR Error: ExpressionChangedAfterItHasBeenCheckedError
  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    this.spinner.show();
    this.initializeForm();
    this.spinner.hide();
  }

  initializeForm() {
    this.enterpriseForm = this.formBuilder.group({
          cifForm: new FormControl('',Validators.required),
          razonSocialForm: new FormControl('',Validators.required),
          nombreComercialForm: new FormControl('',Validators.required),
          paisForm: new FormControl('',Validators.required),
          codigoPostalForm: new FormControl('',Validators.required),
          provinciaForm: new FormControl('',Validators.required),
          poblacionForm: new FormControl('',Validators.required),
          direccionForm: new FormControl('',Validators.required),
          telefonoEmpresaForm: new FormControl('')
        });
  }

  onSubmit() {
    if (this.empresa.id == undefined) {
        if (environment.debug) console.log("Submit Empresa");
        this.submit = true;
        if (this.formValidator()) {
           let empresa = {} as Empresa;
           empresa.cif = this.enterpriseForm.controls['cifForm'].value,
           empresa.razonSocial = this.enterpriseForm.controls['razonSocialForm'].value,
           empresa.nombreComercial = this.enterpriseForm.controls['nombreComercialForm'].value,
           empresa.pais = this.enterpriseForm.controls['paisForm'].value,
           empresa.codigoPostal = this.enterpriseForm.controls['codigoPostalForm'].value,
           empresa.provincia = this.enterpriseForm.controls['provinciaForm'].value,
           empresa.poblacion = this.enterpriseForm.controls['poblacionForm'].value,
           empresa.direccion = this.enterpriseForm.controls['direccionForm'].value,
           //empresa.email = this.enterpriseForm.controls['emailEmpresaForm'].value,
           empresa.telefono = this.enterpriseForm.controls['telefonoEmpresaForm'].value

          this.restApiService.setEnterpriseData(empresa).subscribe(dataEnterprise => {
            if (dataEnterprise) {
                this.restApiService.showSuccess("Empresa guardada correctamente.");
                sessionStorage.setItem("idCliente",dataEnterprise.Data.IdCliente[0].IdCliente);
            }else{
                sessionStorage.setItem("idCliente",'');
                this.restApiService.showError("Ha ocurrido un error al guardar la empresa");
            }

            this.router.navigate(['enterprise-form-cont']);
          });
        }else{
          this.restApiService.showError("Debe completar los campos obligatorios.");
        }
    }else{
        sessionStorage.setItem("idCliente",this.empresa.id);
        this.router.navigate(['enterprise-form-cont']);
    }
  }

  formValidator() {
    if (this.enterpriseForm.get('cifForm').hasError('required') ||
        this.enterpriseForm.get('razonSocialForm').hasError('required') ||
        this.enterpriseForm.get('nombreComercialForm').hasError('required') ||
        this.enterpriseForm.get('paisForm').hasError('required') ||
        this.enterpriseForm.get('codigoPostalForm').hasError('required') ||
        this.enterpriseForm.get('provinciaForm').hasError('required')||
        this.enterpriseForm.get('poblacionForm').hasError('required')||
        this.enterpriseForm.get('direccionForm').hasError('required')) //this.enterpriseForm.get('emailEmpresaForm').hasError('required')
          return false;
    else
          return true;
  }

  cancel() {
    if (environment.debug) console.log("Cancel");
    if (this.modal!==undefined && this.modal==false) location.reload();
        else this.closeModal();
  }

  onNoClick(): void {
    this.cancel();
  }

  closeModal() {
    this.modalDialog.closeAll();
  }

}
