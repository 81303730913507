import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RestApiService } from '../services/rest-api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { ChangeDetectorRef } from '@angular/core';
import { environment } from '../../environments/environment';
import { Empresa } from '../Model/Empresa';
import { Contacto } from '../Model/Contacto';
import { Pais } from '../Model/Pais';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UtilsService } from '../services/utils.service';
import { AddEnterpriseFormContComponent } from '../components/add-enterprise-form-cont/add-enterprise-form-cont.component';
import { ModalSelectEnterpriseFormContComponent } from '../modal-select-enterprise-form-cont/modal-select-enterprise-form-cont.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
//import { PaisInputComponent } from '../components/pais-input/pais-input.component';

@Component({
  selector: 'app-enterprise-form-cont',
  templateUrl: './enterprise-form-cont.component.html',
  styleUrls: ['./enterprise-form-cont.component.scss']
})

export class EnterpriseFormContComponent implements OnInit {
  environment = environment;
  titleRegistro = environment.app.titleRegistro;

  initCifForm: FormGroup;
  enterpriseForm: FormGroup;
  empresa: Empresa;
  cif: string;
  idCliente: string = '';
  idSede: string = '';
  idContacto: string = '';
  submit: boolean = false;
  submitCif: boolean = false;
  paisSelected: string;
  provinciaSelected: string;
  poblacionSelected: string;
  codigoPostalSelected: number;
  sinClientes: boolean = false;
  displayInitCifForm: boolean = false;
  clienteAsociado: boolean = false;
  mapaClienteContactoSede: Map<string, Contacto>;
  listaContactoClientes: Contacto[] = [];

//  @ViewChild(PaisInputComponent) paisComponent: PaisInputComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource<Empresa>();
  tableHeaders: string[] = ['checklist', 'razonSocial', 'nombreComercial', 'cif', 'sede', 'buttons'];

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private restApiService: RestApiService,
              private spinner: NgxSpinnerService,
              private cdRef:ChangeDetectorRef,
              public  utils: UtilsService,
              public dialog: MatDialog) { }

  //Evita que se produzca el ERROR Error: ExpressionChangedAfterItHasBeenCheckedError
  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    this.spinner.show();
    this.mapaClienteContactoSede = new Map();
    this.initializeForm();
    this.empresa = {} as Empresa;
    let contactosSelected: string[] = JSON.parse(sessionStorage.getItem("contactosSelected"));
    if(contactosSelected !== null){
        this.idCliente = sessionStorage.getItem("idCliente");
        this.idContacto = contactosSelected[0];
        if (this.idContacto !== null) this.getClientesAsociadosAContacto();
    }else{
        this.restApiService.showError("Ha ocurrido un error, vuelva a intentarlo");
        this.spinner.hide();
        this.router.navigate(['init-form-cont']);
    }
  }

  /*
    Método que ya habiendo guardado la empresa/cliente el componente 'entreprise-from', ahora se asocia dicho cliente con el contacto
  */
  clienteGuardadoEnEnterprise() {
    //Se recoge el cliente creado en el componente 'enterprise-form' llamado desde el html de este componente
    this.idCliente = sessionStorage.getItem("idCliente");

    //Se realiza la asociación entre cliente y contacto
    //TODO: Llamar al servicio con el idCliente y el idContacto para asociarlos
    debugger;//NO QUITAR, hasta acabar dicho TODO

  }

  initializeForm() {
    this.initCifForm = this.formBuilder.group({
          cifForm: ['',[Validators.required, Validators.pattern(environment.app.cifPattern)]]
    });
    this.displayInitCifForm = false;

    this.enterpriseForm = this.formBuilder.group({
          cifForm: new FormControl('',Validators.required),
          razonSocialForm: new FormControl('',Validators.required),
          nombreComercialForm: new FormControl('',Validators.required),
          paisForm: new FormControl('',Validators.required),
          codigoPostalForm: new FormControl('',Validators.required),
          provinciaForm: new FormControl('',Validators.required),
          poblacionForm: new FormControl('',Validators.required),
          direccionForm: new FormControl('',Validators.required),
          //emailEmpresaForm: new FormControl('',[Validators.required, Validators.email]),
          telefonoEmpresaForm: new FormControl('')
    });
  }

  getClientesAsociadosAContacto(){
    this.spinner.show();
    this.restApiService.getClientesAsociadosAContacto(this.idContacto).subscribe( dataContactos => {
        //Se obtiene la lista de contactos con sus sedes para rellenar el objeto result que se mostrará en la tabla
        this.listaContactoClientes = JSON.parse(sessionStorage.getItem("listaContactoClientes"));
        //sessionStorage.removeItem("listaContactoClientes");
        if(this.listaContactoClientes!== null && this.listaContactoClientes !== undefined){

          this.listaContactoClientes.forEach(contacto => {
              this.mapaClienteContactoSede.set(contacto.idCliente, contacto);
          });
        }
        if(dataContactos !== undefined && dataContactos.length > 0){
            let result: Empresa[] = [];
            dataContactos.forEach(dataContacto => {
                result.push({
                    //checked: false,
                    id: dataContacto.IdCliente, //Es el idCliente de la empresa + el id de la sede
                    cif: dataContacto.Nif,
                    razonSocial: dataContacto.RazonSocial,
                    nombreComercial: dataContacto.Cliente,
                    pais: dataContacto.Pais,
                    idPais: dataContacto.IdPais,
                    codigoPostal: dataContacto.CodPostal,
                    provincia: dataContacto.Provincia,
                    idProvincia: dataContacto.IdProvincia,
                    poblacion: dataContacto.Ciudad,
                    idPoblacion: dataContacto.IdPoblacion,
                    direccion: dataContacto.Direccion,
                    email: dataContacto.E_Mail,
                    telefono: dataContacto.NumTelefono,

                    //NOTA: ESTA INFORMACIÓN NO SE ESTA MOSTRANDO CUANDO AGREGAS UNA EMPRESA PORQUE HAY QUE AÑADIRLA EN EL LOCALSTORAGE
                    nombreSede: this.mapaClienteContactoSede.get(dataContacto.Padre)?.nombreSede,    //Padre será el idCliente
                    idDocSede: this.mapaClienteContactoSede.get(dataContacto.Padre)?.idDocSede,
                  });
            });

            this.dataSource = new MatTableDataSource(result);
            this.dataSource.sort = this.sort;
            this.spinner.hide();
        }else{
            this.spinner.hide();
            this.sinClientes = true;

            this.restApiService.showWarning("No se han encontrado clientes para el contacto. Asocie un nuevo cliente al contacto");
        }
    });
  }

  onSubmit(){
    this.router.navigate(['workgroup-form-cont']);
  }

  onSubmitCif(){
    let cif = this.initCifForm.get('cifForm').value;
    sessionStorage.setItem("cif", cif);
    this.submitCif = true;
  }

  onSubmitAddClient() {
    if (this.empresa.id == undefined) {
        if (environment.debug) console.log("Submit Empresa");
        this.submit = true;
        if (this.formValidator()) {
           let empresa = {} as Empresa;
           empresa.cif = this.cif,//this.enterpriseForm.controls['cifForm'].value,
           empresa.razonSocial = this.enterpriseForm.controls['razonSocialForm'].value,
           empresa.nombreComercial = this.enterpriseForm.controls['nombreComercialForm'].value,
           empresa.pais = this.enterpriseForm.controls['paisForm'].value,
           empresa.codigoPostal = this.enterpriseForm.controls['codigoPostalForm'].value,
           empresa.provincia = this.enterpriseForm.controls['provinciaForm'].value,
           empresa.poblacion = this.enterpriseForm.controls['poblacionForm'].value,
           empresa.direccion = this.enterpriseForm.controls['direccionForm'].value,
           //empresa.email = this.enterpriseForm.controls['emailEmpresaForm'].value,
           empresa.telefono = this.enterpriseForm.controls['telefonoEmpresaForm'].value

          this.restApiService.setEnterpriseData(empresa).subscribe(dataEnterprise => {
            if (dataEnterprise) {
                this.restApiService.showSuccess("Empresa guardada correctamente.");
                sessionStorage.setItem("idCliente", dataEnterprise.Data.IdCliente[0].IdCliente);

                //Ha ido todo OK, mostramos la tabla y se realiza la llamada para obtener los clientes
                this.sinClientes = true;
                this.getClientesAsociadosAContacto();
            }else{
                sessionStorage.setItem("idCliente",'');
                this.restApiService.showError("Ha ocurrido un error al guardar la empresa");
            }
          });
        }else{
          this.restApiService.showError("Debe completar los campos obligatorios.");
        }
    }
  }

//   selectedClientsValidator() {
//     //Se comprueba si hay más de un cliente seleccionado
//     let clientesSeleccionados: number = 0;
//     this.dataSource.filteredData.forEach(client => {
//       if(client.checked)
//         clientesSeleccionados++;
//     });
//
//     if(clientesSeleccionados == 0 || clientesSeleccionados > 1){
//         this.restApiService.showWarning("Debe seleccionar un cliente");
//         return false;
//     }
//
//     return true;
//   }

  formValidator() {
    if (this.enterpriseForm.get('cifForm').hasError('required') ||
        this.enterpriseForm.get('razonSocialForm').hasError('required') ||
        this.enterpriseForm.get('nombreComercialForm').hasError('required') ||
        this.enterpriseForm.get('paisForm').hasError('required') ||
        this.enterpriseForm.get('codigoPostalForm').hasError('required') ||
        this.enterpriseForm.get('provinciaForm').hasError('required')||
        this.enterpriseForm.get('poblacionForm').hasError('required')||
        this.enterpriseForm.get('direccionForm').hasError('required')) //this.enterpriseForm.get('emailEmpresaForm').hasError('required')
          return false;
    else
          return true;
  }

  cancel() {
    if (environment.debug) console.log("Cancel");
    this.router.navigate(['init-form-cont']);
  }

  applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openModalAsociarEmpresa(){
    //TODO: Probar el alta de cliente cuando la gente del BACK suba el desarrollo de PROD a DEV... si lo tienen que poner en DEV...
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {idCliente: undefined};
    dialogConfig.height ="90%";
    dialogConfig.width ="30%";
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(ModalSelectEnterpriseFormContComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      //Si ha ido todo OK se devuelve el id del cliente que se quiere asociar con el contacto
      if(result){
        this.idSede = result.idSede.idClienteSede;
      }
        this.spinner.show();
        this.getClientesAsociadosAContacto();
        this.spinner.hide();
        
        //TODO: Realizar la llamada a la API de AEE para agregar la asociación
        //Dentro de la respuesta(enviando 'result.idCliente', 'result.idSede' y 'this.idContacto') se realiza la siguiente llamada:
        //this.clienteGuardadoEnEnterprise(this.idCliente, this.idContacto);
        //NO BORRAR, hasta que la gente de BACK termine el desarrollo para poder agregar una asociación entre cliente y contacto
      
    });
  }

  //Método que elimina la asociación del contacto con el cliente
  deleteAssociationContactClient(client) {
    let title_text = "Se va a proceder a la eliminación de la asociación contacto-cliente con";
    let title_verificacion = "¿Desea continuar?";
    let btn_text = "Si";
    let btn_text_cancelar = "No";
    let response_success = "Asociación eliminada con exito";
    let response_error = "Se ha producido un error";

    Swal.fire({
      titleText: title_text + ' ' + client.razonSocial,
      text: title_verificacion,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'var(--blue)',
      confirmButtonText: btn_text,
      cancelButtonText: btn_text_cancelar,
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        this.restApiService.deleteAsociacionContactoCliente(this.idContacto, client.id).subscribe(data => {
          if (data) {
            Swal.fire({
              icon: 'success',
              title: response_success,
              confirmButtonColor: 'var(--blue)',
              allowOutsideClick: false
            }).then((result) => {
              //Si ha ido todo correcto al eliminar la asociación, se elimina el registro de la tabla
              if (result.isConfirmed) {
                this.eliminacionClienteListaFiltrada(client);
              }
            })
          } else {
            this.spinner.hide();
            this.restApiService.showError(response_error);
          }
        }), (error => {
          this.restApiService.showError(response_error);
        });
      }
    });
  }

  /**
   * Método que elimina el cliente asociado con el contacto de la lista de la tabla obtenida con el id de contacto.
   * @param  {string}
   * @return  {boolean}
   */
  eliminacionClienteListaFiltrada(cliente){
    let indice = -1;
    this.dataSource.filteredData.forEach(client => {
      //Si se encuentra el cliente, se guarda el indice en el que se encuentra en el array
      if(client.id === cliente.id)
        indice = this.dataSource.filteredData.indexOf(client);
    });
    if (indice !== -1){
      this.dataSource.filteredData.splice(indice, 1);//Se elimina a ese cliente asociado eliminado
      this.dataSource = new MatTableDataSource(this.dataSource.filteredData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

}
