import { Component, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from "@angular/material/select"
import { MatOption } from "@angular/material/core"
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { CodigoPostal } from '../../Model/CodigoPostal';
import { RestApiService } from '../../services/rest-api.service';

@Component({
  selector: 'app-codigo-postal-input',
  templateUrl: './codigo-postal-input.component.html',
  styleUrls: ['./codigo-postal-input.component.css']
})

export class CodigoPostalInputComponent {
  codigosPostales: CodigoPostal[] = [];
  codigosPostalesStr: string[] = [];
  filteredCodigosPostales: Observable<string[]>;
  @Input() enterpriseForm: FormGroup;
  @Input() idPaisSelected: string;
  @Input() idProvinciaSelected: string;
  @Input() idPoblacionSelected: string;

  constructor(private restApiService: RestApiService) {
  }

  ngOnInit(): void {
    if (this.idPaisSelected!==undefined && this.idPaisSelected!==null && this.idPaisSelected!=='' &&
        this.idProvinciaSelected!==undefined && this.idProvinciaSelected!==null && this.idProvinciaSelected!=='' &&
        this.idPoblacionSelected!==undefined && this.idPoblacionSelected!==null && this.idPoblacionSelected!=='')
    {
      this.getPostalCodes();
    }
  }

  ngOnChanges(){
    if (this.idPaisSelected!==undefined && this.idPaisSelected!==null && this.idPaisSelected!=='' &&
        this.idProvinciaSelected!==undefined && this.idProvinciaSelected!==null && this.idProvinciaSelected!=='' &&
        this.idPoblacionSelected!==undefined && this.idPoblacionSelected!==null && this.idPoblacionSelected!=='')
    {
      this.getPostalCodes();
    }
  }

  private getPostalCodes()
  {
    this.codigosPostales = [];
    this.codigosPostalesStr = [];

    this.restApiService.getPostalCodesData(this.idPaisSelected, this.idProvinciaSelected, this.idPoblacionSelected).subscribe( dataPostalCodes => {
        if (dataPostalCodes)
        {
          dataPostalCodes.forEach(postalCode => {
            let codigoPostal = {} as CodigoPostal;
            codigoPostal.idPais = postalCode.IdPais;
            codigoPostal.idProvincia = postalCode.IdProvincia;
            codigoPostal.idPoblacion = postalCode.IdPoblacion;
            codigoPostal.codigoPostal = postalCode.CP;
            //if (environment.debug) console.log(codigoPostal.codigoPostal);
            this.codigosPostales.push(codigoPostal);
            this.codigosPostalesStr.push(codigoPostal.codigoPostal);
          });
          this.filteredCodigosPostales = this.enterpriseForm.controls['codigoPostalForm'].valueChanges
            .pipe(
              startWith(''),
              map(value => this._filterCodigoPostal(value))
            );

          this._filterCodigoPostal(this.enterpriseForm.controls['codigoPostalForm'].value);
        }
    });
  }

  private _filterCodigoPostal(value: string): string[] {
    const filterValue = value.toLowerCase();
    let returnValue = this.codigosPostalesStr.filter(option => option?.toLowerCase().includes(filterValue));
    if (returnValue.length==1)
    {
      let codigoPostal = this.codigosPostales.find(codigoPostal => codigoPostal.codigoPostal == returnValue[0]);
      //this.enterpriseForm.controls['direccionForm'].enable();
    }
    return returnValue;
  }

}
