<div class="row">
    <div class="col" >
        <div class="wrapper-progressBar">
            <ul class="progressBar">
                <li [ngClass]="{active: step.toString() === '1' ||  step.toString() === '2' ||  step.toString() === '3'  ||  step.toString() === '4' }">
                    <span>Inicio</span>
                  </li>
                <li [ngClass]="{active: step.toString() === '2' ||  step.toString() === '3' ||  step.toString() === '4' }">
                    <span>Datos del Contacto</span>
                  </li>
                <li [ngClass]="{active: step.toString() === '3' ||  step.toString() === '4' }">
                    <span>Empresa/s</span>
                </li>
                <li [ngClass]="{active: step.toString() === '4'}">
                  <span>Grupo/s de Trabajo</span>
                </li>
            </ul>
        </div>
    </div>
</div>
