<div class="row" [formGroup]="enterpriseForm">
  <div class="col">
    <mat-form-field appearance="outline" class="autocomplete-full-width">
      <mat-label>Código Postal <span class="star">&nbsp;*</span></mat-label>
      <input matInput
             type="text"
             placeholder="Seleccione un código postal"
             aria-label="Código Postal"
             formControlName="codigoPostalForm"
             [matAutocomplete]="auto"
             autocomplete="off">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let codigoPostal of filteredCodigosPostales | async" [value]="codigoPostal">
          {{codigoPostal}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</div>
