import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';

@Injectable({
 providedIn: 'root'
})

export class UtilsService {
  drop(event: CdkDragDrop<string[]>, tableHeaders) {

    moveItemInArray(tableHeaders, event.previousIndex, event.currentIndex);
    let objToSave: object = {};

    if (sessionStorage.getItem('tableColsOrder')) {
      objToSave = JSON.parse(sessionStorage.getItem('tableColsOrder'));
    }
    objToSave[this.constructor.name] = tableHeaders;
    sessionStorage.setItem('tableColsOrder', JSON.stringify(objToSave));

  }
}
