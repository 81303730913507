import { Component, EventEmitter, Input, OnInit, Output, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RestApiService } from '../services/rest-api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { ChangeDetectorRef } from '@angular/core';
import { environment } from '../../environments/environment';
import { Empresa } from '../Model/Empresa';
import { Pais } from '../Model/Pais';
import { ClienteEmpresa } from '../Model/ClienteEmpresa';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-modal-select-enterprise-form-cont',
  templateUrl: './modal-select-enterprise-form-cont.component.html',
  styleUrls: ['./modal-select-enterprise-form-cont.component.scss']
})

export class ModalSelectEnterpriseFormContComponent implements OnInit {
  environment = environment;
  idCliente: string;
  idSede: string;
  displayInitCifForm: boolean = true;
  displayEnterpriseForm: boolean = false;
  displaySedeForm: boolean = false;
  sedeSeleccionada: boolean = false;
  enterpriseForm: FormGroup;
  departamentoForm: FormGroup;
  initCifForm: FormGroup;
  empresa: Empresa;
  razonSocialCliente: string;
  cif: string;
  submit: boolean = false;
  paisSelected: string;
  provinciaSelected: string;
  poblacionSelected: string;
  codigoPostalSelected: number;
  clienteEmpresa: ClienteEmpresa;

//  @ViewChild(PaisInputComponent) paisComponent: PaisInputComponent;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private restApiService: RestApiService,
              private spinner: NgxSpinnerService,
              private cdRef:ChangeDetectorRef,
              private modal: MatDialogRef<ModalSelectEnterpriseFormContComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  //Evita que se produzca el ERROR Error: ExpressionChangedAfterItHasBeenCheckedError
  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    this.idCliente = this.data.idCliente;
    this.initializeForm();
  }

  initializeForm() {
    this.initCifForm = this.formBuilder.group({
          cifForm: ['',[Validators.required, Validators.pattern(environment.app.cifPattern)]]
    });

    this.enterpriseForm = this.formBuilder.group({
      cifForm: new FormControl('',Validators.required),
      razonSocialForm: new FormControl('',Validators.required),
      nombreComercialForm: new FormControl('',Validators.required),
      paisForm: new FormControl('',Validators.required),
      codigoPostalForm: new FormControl('',Validators.required),
      provinciaForm: new FormControl('',Validators.required),
      poblacionForm: new FormControl('',Validators.required),
      direccionForm: new FormControl('',Validators.required),
      sedeForm: new FormControl('',Validators.required),
      departamentoForm: new FormControl('',Validators.required),
      //emailEmpresaForm: new FormControl('',[Validators.required, Validators.email]),
      telefonoEmpresaForm: new FormControl('')
    });
  }

  onSubmitSeleccionadaSede(){
    if (this.enterpriseForm.controls['sedeForm'].value != null){
        this.idSede = this.enterpriseForm.controls['sedeForm'].value;
        this.sedeSeleccionada = true;
        this.closeModal();
    }
  }

  onSubmitCif(){
    this.cif = this.initCifForm.get('cifForm').value;
    this.searchEmpresa();
  }

  searchEmpresa(){
    this.displayEnterpriseForm = false;
    this.empresa = {} as Empresa;
    this.restApiService.getEnterpriseData(this.cif).subscribe( dataEnterprise => {
        if (dataEnterprise !== 'undefined' && dataEnterprise.length > 0){
          if (environment.debug) console.log("Información de la Empresa obtenida");
          this.empresa.id = dataEnterprise[0]?.IdCliente;
          this.empresa.cif = this.cif; //dataEnterprise[0]?.Nif;
          this.empresa.razonSocial = dataEnterprise[0]?.RazonSocial;
          this.empresa.nombreComercial = dataEnterprise[0]?.Cliente;
          this.empresa.idPais = dataEnterprise[0]?.IdPais;
          this.empresa.pais = dataEnterprise[0]?.Pais;
          this.empresa.idProvincia = dataEnterprise[0]?.IdProvincia;
          this.empresa.provincia = dataEnterprise[0]?.Provincia;
          this.empresa.idPoblacion = dataEnterprise[0]?.IdPoblacion;
          this.empresa.poblacion = dataEnterprise[0]?.Ciudad;
          this.empresa.direccion = dataEnterprise[0]?.Direccion;
          this.empresa.codigoPostal = dataEnterprise[0]?.CodPostal;
          //this.empresa.email = dataEnterprise[0]?.E_Mail;
          this.empresa.telefono = dataEnterprise[0]?.NumTelefono;

          //Ha ido todo OK, seteamos el idCliente para asociar después el contacto-cliente y se muestra el nuevo formulario para la sede
          this.idCliente = this.empresa.id;
          sessionStorage.setItem("idCliente", this.idCliente);
          this.razonSocialCliente = this.empresa.razonSocial;
          this.displaySedeForm = true;

          //Actualizamos los campos del formulario para pasarle la info al componente 'app-sede-select' .
          this.enterpriseForm.controls['cifForm'].patchValue(this.empresa.cif);
          this.enterpriseForm.controls['razonSocialForm'].patchValue(this.empresa.razonSocial);
          this.enterpriseForm.controls['nombreComercialForm'].patchValue(this.empresa.nombreComercial);
          //this.enterpriseForm.controls['emailEmpresaForm'].patchValue(this.empresa.email);
          this.enterpriseForm.controls['telefonoEmpresaForm'].patchValue(this.empresa.telefono);

          if (this.empresa.idPais == null)
            this.paisSelected = this.empresa.pais;

          this.enterpriseForm.controls['paisForm'].patchValue(this.empresa.pais);

          if (this.empresa.idProvincia == null)
              this.provinciaSelected = this.empresa.provincia;

          this.enterpriseForm.controls['provinciaForm'].patchValue(this.empresa.provincia);

          if (this.empresa.idPoblacion == null)
              this.poblacionSelected = this.empresa.poblacion;

          this.enterpriseForm.controls['poblacionForm'].patchValue(this.empresa.poblacion);

          if (this.empresa.codigoPostal !== null)
              this.codigoPostalSelected = this.empresa.codigoPostal;

          this.enterpriseForm.controls['codigoPostalForm'].patchValue(this.empresa.codigoPostal);

          if (this.empresa.direccion !== null)
              this.enterpriseForm.controls['direccionForm'].patchValue(this.empresa.direccion);

        }else{
          //Si no se encuentra resultados
          this.displayInitCifForm = false;
          this.displayEnterpriseForm = true;
          this.displaySedeForm = false;

          if (environment.debug) console.log("Error al obtener la información de la Empresa");
          this.empresa.cif = this.cif;
          this.enterpriseForm.controls['cifForm'].patchValue(this.empresa.cif);

          this.enterpriseForm.controls['cifForm'].disable();
          this.enterpriseForm.controls['razonSocialForm'].enable();
          this.enterpriseForm.controls['nombreComercialForm'].enable();
          this.enterpriseForm.controls['paisForm'].enable();
          this.enterpriseForm.controls['provinciaForm'].enable();
          this.enterpriseForm.controls['poblacionForm'].enable();
          this.enterpriseForm.controls['codigoPostalForm'].enable();
          this.enterpriseForm.controls['direccionForm'].enable();
          //this.enterpriseForm.controls['emailEmpresaForm'].enable();
          this.enterpriseForm.controls['telefonoEmpresaForm'].enable();
        }
        this.spinner.hide();
    });
  }

  onSubmit() {
    if (this.empresa.id == 'undefined' || this.empresa.id == null) {
      if (environment.debug) console.log("Submit Empresa");
      this.submit = true;
      if (this.formValidator()) {
         let empresa = {} as Empresa;
         empresa.cif = this.cif,//this.enterpriseForm.controls['cifForm'].value,
         empresa.razonSocial = this.enterpriseForm.controls['razonSocialForm'].value,
         empresa.nombreComercial = this.enterpriseForm.controls['nombreComercialForm'].value,
         empresa.pais = this.enterpriseForm.controls['paisForm'].value,
         empresa.codigoPostal = this.enterpriseForm.controls['codigoPostalForm'].value,
         empresa.provincia = this.enterpriseForm.controls['provinciaForm'].value,
         empresa.poblacion = this.enterpriseForm.controls['poblacionForm'].value,
         empresa.direccion = this.enterpriseForm.controls['direccionForm'].value,
         //empresa.email = this.enterpriseForm.controls['emailEmpresaForm'].value,
         empresa.telefono = this.enterpriseForm.controls['telefonoEmpresaForm'].value
        
        this.restApiService.setEnterpriseData(empresa).subscribe(dataEnterprise => {
          if (dataEnterprise) {
                  this.restApiService.showSuccess("Empresa guardada correctamente.");
                  //TODO: HAY QUE AÑADIR EN EL LOCALSTORAGE "listaContactoClientes" la info del nombreSede al contacto.
                  this.searchEmpresa();
              }else{
                this.restApiService.showError("Ha ocurrido un error al guardar la empresa");
              }
        });
      }else{
        this.restApiService.showError("Debe completar los campos obligatorios.");
      }
    }
    else{
      this.clienteEmpresa = {} as ClienteEmpresa;
      let contactosSelected: string[] = JSON.parse(sessionStorage.getItem("contactosSelected"));
      this.clienteEmpresa.idContacto = contactosSelected[0];
      this.clienteEmpresa.idDocObjeto = this.enterpriseForm.controls['sedeForm'].value.idDocSede;
      if(this.clienteEmpresa.idDocObjeto === null){
        this.clienteEmpresa.objeto = "Planta";
      } else {
        this.clienteEmpresa.objeto = "Cliente";
      }
      this.clienteEmpresa.idTipo = "98";
      this.clienteEmpresa.idCliente =  this.empresa.id;
      this.clienteEmpresa.departamento = this.enterpriseForm.controls['departamentoForm'].value;
      console.log(this.clienteEmpresa);
      this.restApiService.setContactoASedeCliente(this.clienteEmpresa).subscribe(dataEnterprise => {
        console.log(dataEnterprise);
        if (dataEnterprise!==null || dataEnterprise!== 'undefined')
        {
          if (dataEnterprise.Success || dataEnterprise.Data?.retorno[0].Estado==1)
          {
            this.restApiService.showSuccess("Empresa asociada correctamente.");
          }else{
            //sessionStorage.setItem("idCliente",'');
            this.restApiService.showError("Ha ocurrido un error al asociada la empresa:" + +dataEnterprise.Data.retorno[0].Mensaje);
          }
        }else{
          this.restApiService.showError("Error al asociada la empresa. Vuelva a intentarlo más tarde.");
        }
      });

    }
  }

  formValidator() {
    if (this.enterpriseForm.get('paisForm').hasError('required') ||
        this.enterpriseForm.get('codigoPostalForm').hasError('required') ||
        this.enterpriseForm.get('provinciaForm').hasError('required')||
        this.enterpriseForm.get('poblacionForm').hasError('required')||
        this.enterpriseForm.get('direccionForm').hasError('required')) //|| this.enterpriseForm.get('emailEmpresaForm').hasError('required')
          return false;
    else
          return true;
  }

  cancel() {
    if (environment.debug) console.log("Cancel");
    this.closeModal();
  }

  onNoClick(): void {
    this.cancel();
  }

  closeModal() {
    if(this.sedeSeleccionada)
        this.modal.close({idCliente: this.idCliente, idSede: this.idSede});
    else
        this.modal.close();
  }

}
