import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RestApiService } from '../services/rest-api.service';
import { UtilsService } from '../services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ChangeDetectorRef } from '@angular/core';
import { environment } from '../../environments/environment';
import { Contacto } from '../Model/Contacto';
import {AddContactFormContComponent} from '../components/add-contact-form-cont/add-contact-form-cont.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';


export interface contacto {
      checked: boolean,
      idContacto: '',
      nombre:''
}

@Component({
  selector: 'app-contact-form-cont',
  templateUrl: './contact-form-cont.component.html',
  styleUrls: ['./contact-form-cont.component.scss']
})
export class ContactFormContComponent implements OnInit {
  environment = environment;
  titleRegistro = environment.app.titleRegistro;
  contactForm: FormGroup;
  nif: string = '';
  idCliente: string = '';
  contactos = [] as Contacto[];
  contactosSelected = [];
  contactoModify: Contacto;
  listaContactoClientes: Contacto[] = [];
  submit: boolean = false;
  modal: boolean = false;
  contactosUnicos = [];

  dataSource = new MatTableDataSource<Contacto>();
  tableHeaders: string[] = [
    'checklist',
    'nombre'
  ];
  @ViewChild('TABLECONTACTOS') table: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private restApiService: RestApiService,
              public  utils: UtilsService,
              private spinner: NgxSpinnerService,
              private cdRef:ChangeDetectorRef,
              public dialog: MatDialog) { }

  //Evita que se produzca el ERROR Error: ExpressionChangedAfterItHasBeenCheckedError
  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
        this.spinner.show();
        this.nif = sessionStorage.getItem("nif");
        //this.initializeForm();
        if (environment.debug) console.log("Accediendo a contact-form con nif -> "+this.nif);

        if (this.nif !== undefined && this.nif !== null && this.nif !== '') {
            this.getContactData();
        }else{
          this.spinner.hide();
        }
  }

  getContactData() {
    let nifContactoExist = sessionStorage.getItem('nifExist');
    let emailContactoExist = sessionStorage.getItem('emailExist')
    if(nifContactoExist === "si"){
      this.restApiService.getContactDataByNif(this.nif).subscribe( dataContact => {
          if (dataContact){
              if (environment.debug) console.log("Información de Contacto/s obtenida");
              dataContact.forEach(data => {

                  if (data.Pers_E_Mail_Adicional === "null" )data.Pers_E_Mail_Adicional = "";
                  if (data.Pers_E_Mail_Adicional === "null" )data.Pers_E_Mail_Adicional = "";
                  if (data.TelefonoTrabajo === "null" )data.TelefonoTrabajo = "";
                  if (data.facebook === "null" )data.facebook = ""; 
                  if (data.twitter === "null" )data.twitter = ""; 
                  if (data.linkedin === "null" )data.linkedin = ""; 
                  if (data.skype === "null" )data.skype = ""; 

                  if (environment.debug) console.log(" - "+data.Nombre);
                  let contacto = {} as Contacto;
                  contacto.id = data.IdContacto;
                  contacto.email = data.E_Mail;
                  contacto.nif = data.NIF;
                  contacto.nombre = data.Nombre;
                  contacto.cargo = data.Cargo;
                  contacto.telefonoFijo = data.TelefonoTrabajo;
                  contacto.telefonoMovil = data.TelefonoMovil;
                  contacto.emailAdicional1 = data.Pers_E_Mail_Adicional;
                  contacto.emailAdicional2 = data.Pers_E_Mail_Adicional2;
                  contacto.facebook = data.Pers_Facebook;
                  contacto.twitter = data.Pers_Twitter;
                  contacto.linkedin = data.Pers_LinkedIn;
                  contacto.skype = data.Pers_Skype;
                  contacto.idCliente = data.IdClientePadre;
                  contacto.idSede = data.IdSede;
                  contacto.nombreSede = data.IdSede_flxtext;
                  contacto.idDocSede = data.IdDocSede;

                  //Se introduce el contacto en la lista de contactos-cliente
                  this.listaContactoClientes.push(contacto);
                  this.contactos.push(contacto);
              });
              this.dataSource = new MatTableDataSource(this.contactos);
              this.dataSource.sort = this.sort;

              //Para poder obtener la lista en el componente de 'enterprise-from-cont'
              sessionStorage.setItem("listaContactoClientes", JSON.stringify(this.listaContactoClientes));

              for(let i = 1; i < this.contactos.length; i++){
                  if (this.contactos[0].nombre === this.contactos[i].nombre)
                      this.contactos.splice(i, 1);
              }
              setTimeout(() => this.dataSource.paginator = this.paginator);
              this.contactoModify = this.contactos[0];
              sessionStorage.setItem("idContacto", this.contactos[0].id.toString());
            }else{
              if (environment.debug) console.log("No hay datos de contacto para el NIF: "+this.nif);
            }

            this.spinner.hide();
            //Ejemplo por si queremos poner un delay al spinner.
            /*setTimeout(() => {
                this.spinner.hide();
            }, 1000);*/
      });
    } else if (nifContactoExist == "no" && emailContactoExist == "si"){
      let email = sessionStorage.getItem("email");
      this.restApiService.getContactDataByEmail(email).subscribe( dataContact => {
        if (dataContact){
            if (environment.debug) console.log("Información de Contacto/s obtenida");
            dataContact.forEach(data => {

                if (data.Pers_E_Mail_Adicional === "null" )data.Pers_E_Mail_Adicional = "";
                if (data.Pers_E_Mail_Adicional === "null" )data.Pers_E_Mail_Adicional = "";
                if (data.TelefonoTrabajo === "null" )data.TelefonoTrabajo = "";
                if (data.facebook === "null" )data.facebook = ""; 
                if (data.twitter === "null" )data.twitter = ""; 
                if (data.linkedin === "null" )data.linkedin = ""; 
                if (data.skype === "null" )data.skype = ""; 

                if (environment.debug) console.log(" - "+data.Nombre);
                let contacto = {} as Contacto;
                contacto.id = data.IdContacto;
                contacto.email = data.E_Mail;
                contacto.nif = data.NIF;
                contacto.nombre = data.Nombre;
                contacto.cargo = data.Cargo;
                contacto.telefonoFijo = data.TelefonoTrabajo;
                contacto.telefonoMovil = data.TelefonoMovil;
                contacto.emailAdicional1 = data.Pers_E_Mail_Adicional;
                contacto.emailAdicional2 = data.Pers_E_Mail_Adicional2;
                contacto.facebook = data.Pers_Facebook;
                contacto.twitter = data.Pers_Twitter;
                contacto.linkedin = data.Pers_LinkedIn;
                contacto.skype = data.Pers_Skype;
                contacto.idCliente = data.IdClientePadre;
                contacto.idSede = data.IdSede;
                contacto.nombreSede = data.IdSede_flxtext;
                contacto.idDocSede = data.IdDocSede;

                //Se introduce el contacto en la lista de contactos-cliente
                this.listaContactoClientes.push(contacto);
                this.contactos.push(contacto);
            });
            this.dataSource = new MatTableDataSource(this.contactos);
            this.dataSource.sort = this.sort;

            //Para poder obtener la lista en el componente de 'enterprise-from-cont'
            sessionStorage.setItem("listaContactoClientes", JSON.stringify(this.listaContactoClientes));

            for(let i = 1; i < this.contactos.length; i++){
                if (this.contactos[0].nombre === this.contactos[i].nombre)
                    this.contactos.splice(i, 1);
            }
            setTimeout(() => this.dataSource.paginator = this.paginator);
            this.contactoModify = this.contactos[0];
            sessionStorage.setItem("idContacto", this.contactos[0].id.toString());
          }else{
            if (environment.debug) console.log("No hay datos de contacto para el NIF: "+this.nif);
          }

          this.spinner.hide();
        });
    }
  }

  cancel() {
    if (environment.debug) console.log("Cancel");
    this.router.navigate(['init-form-cont']);
  }

  openModalAddContacto(){
    this.modal = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
        idCliente: this.idCliente,
        modal: true
    };
    //dialogConfig.width = "70%";
    dialogConfig.height ="90%";
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(AddContactFormContComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(modalClose => {
        this.modal = false;
        this.spinner.show();
        this.getContactData();
        this.spinner.hide();
    });
  }
}
