 import { Component, EventEmitter, Input, OnInit, OnChanges, Output, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup,FormArray, NgModel, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RestApiService } from '../services/rest-api.service';
import { UtilsService } from '../services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { ChangeDetectorRef } from '@angular/core';
import { environment } from '../../environments/environment';
import { GrupoTrabajo } from '../Model/GrupoTrabajo';

import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface contacto {
      checked: boolean,
      idContacto: '',
      nombre:''
}

@Component({
  selector: 'app-workgroup-form-cont',
  templateUrl: './workgroup-form-cont.component.html',
  styleUrls: ['./workgroup-form-cont.component.scss']
})

export class WorkgroupFormCont implements OnInit, OnChanges  {

  groupsOrigin = [];
  workGroupsList = [] as GrupoTrabajo[];
  listasDistriList = [] as GrupoTrabajo[];
  listaSubmit = [] as GrupoTrabajo[];
  environment = environment;
  titleRegistro = environment.app.titleRegistro;
  workForm: FormGroup;
  idCliente = '';
  idContacto = '';
  groupCheck: boolean = false;
  workGroup: GrupoTrabajo;
  idDocs: any = '';
  exito: boolean = false;

  submit = false;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private restApiService: RestApiService,
              public  utils: UtilsService,
              private spinner: NgxSpinnerService,
              private cdRef:ChangeDetectorRef,
              private modalDialog: MatDialog) { }

  //Evita que se produzca el ERROR Error: ExpressionChangedAfterItHasBeenCheckedError
  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    this.spinner.show();
    this.idCliente = sessionStorage.getItem("idCliente");
    let contactosSelected: string[] = JSON.parse(sessionStorage.getItem("contactosSelected"));
    this.idContacto = contactosSelected[0];
    this.initializeForm();
    this.getWorkGroupsInfo();
    this.getListasDistriInfo();
    this.spinner.hide();
  }

  ngOnChanges() {
  }
  checkAllGroups(event) {
    this.workGroupsList.forEach(workGroup => {
      workGroup.checked = event.checked;
    });
  }
  checkAllLists(event) {
      this.listasDistriList.forEach(listDistri => {
        listDistri.checked = event.checked;
      });
  }

  initializeForm() {
    this.workForm = this.formBuilder.group({});
  }

  getWorkGroupsInfo(){
    this.restApiService.getGruposAInscribirContacto(this.idContacto).subscribe( dataGroup => {
      if (dataGroup){
          if (environment.debug) console.log("Información de Grupo de Trabajo obtenida");
          dataGroup.forEach(data => {
              if (environment.debug) console.log(" - "+data.descripcion);
              let workGroup = {} as GrupoTrabajo;

              if (data.IdSubGrupo == 0)
              {
                    workGroup.descripcion = data.DescripGrupo;
                    workGroup.isSubgroup = false;
              }else{
                    workGroup.descripcion = data.DescripSubGrupo; //data.DescripGrupo + " - " +
                    workGroup.isSubgroup = true;
              }
              workGroup.idDoc = data.IdDoc;

              if (data.Inscrito == 1)
              {
                    workGroup.checked = true;
                    this.groupsOrigin.push(workGroup.idDoc);
              }

              this.workGroupsList.push(workGroup);
          })
      }
    });
  }
  getListasDistriInfo(){
      this.restApiService.getListasDistribucionAInscribirContacto(this.idContacto).subscribe(dataList => {
          if (dataList){
              if (environment.debug) console.log("Información de Listas de Distribución obtenida");
              dataList.forEach(data => {
                  if (environment.debug) console.log(" - "+data.DescripGrupo);
                  let listDistri = {} as GrupoTrabajo;

                  if (data.IdSubGrupo == 0)
                  {
                      listDistri.descripcion = data.DescripGrupo;
                      listDistri.isSubgroup = false;
                  }else{
                      listDistri.descripcion = data.DescripSubGrupo; //data.DescripGrupo + " - " +
                      listDistri.isSubgroup = true;
                  }
                  listDistri.idDoc = data.IdDoc;

                  if (data.Inscrito == 1)
                  {
                      listDistri.checked = true;
                      this.groupsOrigin.push(listDistri.idDoc);
                  }

                  this.listasDistriList.push(listDistri);
              })
          }
      });
  }
  cancel(){
    this.router.navigate(['init-form-cont']);
  }

  onSubmit(){

    this.listasDistriList.forEach(listDistri =>{
      this.listaSubmit.push(listDistri);
    });

    this.workGroupsList.forEach(listDistri =>{
      this.listaSubmit.push(listDistri);
    });

    this.listaSubmit.forEach(workGroup => {
      if(workGroup.checked)
      {
        //si esta marcado lo añadiré. Nota: se puede comprobar que si estaba marcado de origen no hace falta volverlo a añadir.
         this.addIdDoc(workGroup);
      }else{
        //si no esta marcado pero esta en los que estaban marcados originalmente lo desasociamos.
        if (this.groupsOrigin.includes(workGroup.idDoc))
        {
            console.log("Desasociar idDoc > "+workGroup.idDoc);
            let tipoObjeto = "Pers_GrupoTrabajo";
            if (workGroup.isSubgroup) tipoObjeto = "Pers_SubgrupoTrabajo";

            this.restApiService.desasociarGrupoContacto(this.idContacto,workGroup.idDoc,tipoObjeto).subscribe(data =>
            {
                if (data!==null || data!== undefined)
                {
                  if (data.Success)
                  {
                    console.log("Grupo idDoc > "+workGroup.idDoc+" desasociado correctamente")
                  }
                }
            });
        }
      }
    });

    if (this.idDocs!=='')
    {
      console.log(this.idContacto,this.idDocs)
      this.restApiService.asociarContactoAGrupos(this.idContacto,this.idDocs).subscribe(data =>
      {
        if (data!==null || data!== undefined)
        {
          if (data.Success || data.Data?.retorno[0].Estado==1)
          {
            if (environment.debug) console.log("Grupos guardados correctamente.");

            this.exito = true;

          }else{
            if (environment.debug) console.log("Error al guardar los grupos: "+data.Data.retorno[0].Mensaje);
            this.restApiService.showError("Error al guardar los grupos. Vuelva a intentarlo más tarde. Error: "+data.Data.retorno[0].Mensaje);
            this.exito = false;
            this.listaSubmit = [];
          }
        }else{
          if (environment.debug) console.log("Error al guardar los grupos");
          this.restApiService.showError("Error al guardar los grupos. Vuelva a intentarlo más tarde.");
          this.exito = false;
          this.listaSubmit = [];
        }
      });
    }else{
          this.restApiService.showError("Tiene que seleccionar al menos 1 grupo");
          this.exito = false;
          this.listaSubmit = [];
    }
  }

  addIdDoc(element)
  {
    if (this.idDocs==='')
    {
      this.idDocs = element.idDoc;
    }else{
      this.idDocs = this.idDocs + ","+element.idDoc;
    }
  }
}
 