<ngx-spinner bdColor="rgba(0, 0, 0, 0.1)" size="large" color="var(--blue)" type="square-jelly-box"
             [fullScreen]="true">

  <br/><br/><p style="color: var(--blue); font-weight: bolder; font-size: large"> Cargando... </p>
</ngx-spinner>

<!-- FORMULARIO DE EMPRESA-->
<div id="enterprise-form" class="container">
  <!-- Formulario -->

  <p style="text-align:center;padding-top:20px;">
    <img class="logo" src="/assets/img/aee.png" alt="AEE" align="middle">
  </p>

  <h1>{{titleRegistro}}</h1>

  <hr/>
  <app-progress-bar-cont step="3"></app-progress-bar-cont>
  <hr/>

  <h3 class="eb-heading" id="field_listado_contactos" style="display: block;">Empresa/s Asociada/s</h3>

  <div>
    <div class="alert alert-info">
      <i class="material-icons">info</i>
      <p style="font-size:small">
        Compruebe sus empresas asociadas. Puede añadir nuevas o eliminar las antiguas.
      </p>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field>
          <mat-label>Filtrar</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Filtrar por nombre" #input size="40">
        </mat-form-field>
      </div>
      <div class="col" style="text-align:right">
        <button class="btn btn-success" type="button"(click)="openModalAsociarEmpresa()">
          <i class="material-icons" style="vertical-align: middle;" >person_add</i> Asociar Empresa
        </button>
      </div>
    </div>

    <div class="row">
        <div class="table-responsive">
          <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z2 table" cdkDropList cdkDropListOrientation="horizontal"
                 (cdkDropListDropped)="this.utils.drop($event, this.tableHeaders)" multiTemplateDataRows>

            <ng-container matColumnDef="checklist">
              <th mat-header-cell cdkDrag *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <!--mat-checkbox [(ngModel)]="element.checked"></mat-checkbox-->
              </td>
            </ng-container>

            <ng-container matColumnDef="razonSocial" cdkDrag mat-sort-header>
              <th mat-header-cell *matHeaderCellDef> Razon Social </th>
              <td mat-cell *matCellDef="let element"> {{element.razonSocial}} </td>
            </ng-container>

            <ng-container matColumnDef="nombreComercial" cdkDrag mat-sort-header>
              <th mat-header-cell *matHeaderCellDef> Nombre Comercial </th>
              <td mat-cell *matCellDef="let element"> {{element.nombreComercial}} </td>
            </ng-container>

            <ng-container matColumnDef="cif" cdkDrag mat-sort-header>
              <th mat-header-cell *matHeaderCellDef> CIF </th>
              <td mat-cell *matCellDef="let element"> {{element.cif}} </td>
            </ng-container>

            <ng-container matColumnDef="sede" cdkDrag mat-sort-header>
              <th mat-header-cell *matHeaderCellDef> Sede </th>
              <td mat-cell *matCellDef="let element"> {{element.nombreSede}} </td>
            </ng-container>

            <ng-container matColumnDef="buttons">
              <th mat-header-cell cdkDrag *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                  <span class="material-icons" style="cursor: pointer;" (click)="deleteAssociationContactClient(element)">delete</span>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="tableHeaders"></tr>
            <tr mat-row *matRowDef="let row; columns: tableHeaders;"></tr>
          </table>
          <div>
            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>
          </div>
        </div>
    </div>

    <hr>
    <!-- Botones de accion -->
    <div class="row" style="text-align:center;">
      <div id="botonesListadoNuevoCliente" class="col">
        <input type="submit" class="btn btn-default btn-primary" id="btn-submit-listado-NuevoCliente" value="Siguiente" (click)="onSubmit()"><!--disabled-->&nbsp;
        <input type="button" class="btn btn-default btn-secondary" id="btn-submit-cancelListado-NuevoCliente" value="Cancelar" (click)="cancel()">
      </div>
    </div>
    <br/><br/>
  </div>
</div>



