import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RestApiService } from '../services/rest-api.service';
import { UtilsService } from '../services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ChangeDetectorRef } from '@angular/core';
import { environment } from '../../environments/environment';
import { Contacto } from '../Model/Contacto';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {AddContactFormComponent} from '../components/add-contact-form/add-contact-form.component';

export interface contacto {
      checked: boolean,
      idContacto: '',
      nombre:''
}

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  environment = environment;
  title = environment.app.title;
  contactForm: FormGroup;
  email = '';
  idCliente = '';
  contactos = [] as Contacto[];
  contactosSelected = [];
  contactoModify:Contacto;
  submit = false;
  disabled = false;
  modal = false;

  dataSource = new MatTableDataSource<Contacto>();
  tableHeaders: string[] = [
    'checklist',
    'nombre'
  ];
  @ViewChild('TABLECONTACTOS') table: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private restApiService: RestApiService,
              public  utils: UtilsService,
              private spinner: NgxSpinnerService,
              private cdRef:ChangeDetectorRef,
              public dialog: MatDialog) { }

  //Evita que se produzca el ERROR Error: ExpressionChangedAfterItHasBeenCheckedError
  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
        this.spinner.show();

        this.email = sessionStorage.getItem("email");
        this.idCliente = sessionStorage.getItem("idCliente");

        //this.initializeForm(); //Se hace despues de obtener los datos del cliente y el email para inicializar los valores del formulario con esa info.

        if (environment.debug) console.log("Accediendo a contact-form con Email -> "+this.email);

        if (this.email!==undefined && this.email!==null && this.email!=='')
        {
            this.getContactData();
        }else{
          this.spinner.hide();
        }
  }

  getContactData()
  {
      this.contactos = [];
      this.restApiService.getContactData(this.email,this.idCliente).subscribe( dataContact => {
          let mapaIdsContactos = new Map();
          if (dataContact){
            if (environment.debug) console.log("Información de Contacto/s obtenida");
            dataContact.forEach(data => {

              if (data.getPers_E_Mail_Adicional === "null" )data.Pers_E_Mail_Adicional = "";
              if (data.emailAdicional2 === "null" )data.emailAdicional2 = "";
              if (data.facebook === "null" )data.facebook = ""; 
              if (data.twitter === "null" )data.twitter = ""; 
              if (data.linkedin === "null" )data.linkedin = ""; 
              if (data.skype === "null" )data.skype = ""; 

              if (environment.debug) console.log(" - "+data.Nombre);
               let contacto = {} as Contacto;
               contacto.id = data.IdContacto,
               contacto.email = data.E_Mail,
               contacto.nif = data.NIF,
               contacto.nombre = data.Nombre,
               contacto.cargo = data.Cargo,
               contacto.telefonoFijo = data.TelefonoTrabajo,
               contacto.telefonoMovil = data.TelefonoMovil,
               contacto.emailAdicional1 = data.Pers_E_Mail_Adicional,
               contacto.emailAdicional2 = data.Pers_E_Mail_Adicional2
               contacto.facebook = data.Pers_Facebook,
               contacto.twitter = data.Pers_Twitter,
               contacto.linkedin = data.Pers_LinkedIn,
               contacto.skype = data.Pers_Skype

               //Comprobamos si ya hemos metido ese contacto anteriormente.
               if (mapaIdsContactos.get(contacto.id) == undefined )
               {
                  this.contactos.push(contacto);
                  mapaIdsContactos.set(contacto.id,contacto);
               }else{
                  if (environment.debug) console.log("Contacto duplicado: "+contacto.id);
               }
            });
            this.dataSource = new MatTableDataSource(this.contactos);
            this.dataSource.sort = this.sort;
            setTimeout(() => this.dataSource.paginator = this.paginator);
            if (this.contactos.length==1)
            {
                this.contactoModify = this.contactos[0];
                //Deshabilitamos la modificacion de contactos.
                this.disabled = true;
            }else{
                this.dataSource.filterPredicate = function(data, filter: string): boolean {
                  return data.nombre.toLowerCase().includes(filter);
                };
            }
          }else{
            if (environment.debug) console.log("No hay datos de contacto para el email: "+this.email);
          }

          this.spinner.hide();
          //Ejemplo por si queremos poner un delay al spinner.
          /*setTimeout(() => {
              this.spinner.hide();
          }, 1000);*/
      });
  }

  onSubmit()
  {
    if (environment.debug) console.log("Submit Contact Form");
    this.submit = true;
    if (!this.disabled)
    {
        if (this.contactos.length==0 && this.contactosSelected.length==0 && !this.disabled)
        {
            if (this.formValidator())
            {
              //NOTA: Todo esto ahora se hace en el componente add-contact-form
               let contacto = {} as Contacto;
               contacto.idCliente = this.idCliente;
               contacto.email = this.contactForm.controls['emailContactoForm'].value,
               contacto.nif = this.contactForm.controls['nifContactoForm'].value,
               contacto.nombre = this.contactForm.controls['nombreApellidosContactoForm'].value,
               contacto.cargo = this.contactForm.controls['cargoContactoForm'].value,
               contacto.sede = this.contactForm.controls['sedeForm'].value,
               contacto.departamento = this.contactForm.controls['departamentoForm'].value,
               contacto.telefonoFijo = this.contactForm.controls['telefonoFijoContactoForm'].value,
               contacto.telefonoMovil = this.contactForm.controls['telefonoMovilContactoForm'].value,
               contacto.emailAdicional1 = this.contactForm.controls['emailAdicional1ContactoForm'].value,
               contacto.emailAdicional2 = this.contactForm.controls['emailAdicional2ContactoForm'].value,
               contacto.facebook = this.contactForm.controls['facebookContactoForm'].value,
               contacto.twitter = this.contactForm.controls['twitterContactoForm'].value,
               contacto.linkedin = this.contactForm.controls['linkedinContactoForm'].value,
               contacto.skype = this.contactForm.controls['skypeContactoForm'].value

               this.restApiService.setContactData(contacto,false,false).subscribe(data => {
                    if (data)
                    {
                       this.submit = true;
                       this.restApiService.showSuccess("Contacto dado de alta correctamente.");
                       this.contactosSelected.push(data.Data.IdContacto[0].IdContacto);
                       sessionStorage.setItem('contactosSelected', JSON.stringify(this.contactosSelected));
                    }else{
                       this.restApiService.showError("Ha ocurrido un error al guardar el contacto");
                    }
               });
            }else{
                this.restApiService.showError("Debe completar los campos obligatorios.");
                this.submit = false;
            }
        }else{
            this.getContactosSelected();
            if (this.contactosSelected.length==0){
                this.submit = false;
                this.restApiService.showError("Debe seleccionar al menos 1 contacto.");
            }
        }
    }else{
      this.contactosSelected.push(this.contactos[0].id);
    }

    if (this.submit)
    {
        sessionStorage.setItem('contactosSelected', JSON.stringify(this.contactosSelected));
        this.router.navigate(['event-info-form']);
    }
  }

  formValidator()
  {
    if (this.contactos.length==0)
    {
      if (this.contactForm.get('emailContactoForm').hasError('required') ||
          this.contactForm.get('nifContactoForm').hasError('required') ||
          this.contactForm.get('nombreApellidosContactoForm').hasError('required') ||
          this.contactForm.get('cargoContactoForm').hasError('required') ||
          this.contactForm.get('sedeForm').hasError('required') ||
          this.contactForm.get('telefonoMovilContactoForm').hasError('required'))
            return false;
      else
            return true;
    }else{
      if (this.contactosSelected.length>0)
        return true;
      else{
        return false;
      }
    }
  }

  getContactosSelected()
  {
      this.contactos.forEach(contacto => {
        if (contacto.checked) {
          this.contactosSelected.push(contacto.id);
        }
      });
  }

  cancel()
  {
    if (environment.debug) console.log("Cancel");
    location.reload();
  }

  checkAllRows(event) {
    if (this.dataSource.data)
      this.dataSource.data.forEach(val => { val.checked = event.checked });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openModalAddContacto(){
    this.modal = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
        idCliente: this.idCliente,
        modal: true
    };
    //dialogConfig.width = "70%";
    dialogConfig.height ="90%";
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(AddContactFormComponent, dialogConfig);
    sessionStorage.setItem("emailAdicional2",this.email);
    sessionStorage.removeItem("email");
    dialogRef.afterClosed().subscribe(modalClose => {
        this.modal = false;
        this.spinner.show();
        this.getContactData();
        this.spinner.hide();
    });
  }
}
