<ngx-spinner bdColor="rgba(0, 0, 0, 0.1)" size="large" color="var(--blue)" type="square-jelly-box"
             [fullScreen]="true">

  <br/><br/><p style="color: var(--blue); font-weight: bolder; font-size: large"> Cargando... </p>
</ngx-spinner>

<!-- FORMULARIO INICIAL -->
<div id="init-form" class="container">
  <p style="text-align:center;padding-top:20px;">
    <img class="logo" src="/assets/img/aee.png" alt="AEE" align="middle">
  </p>

  <h1>{{titleRegistro}}</h1>

  <hr/>
  <app-progress-bar-cont step="1"></app-progress-bar-cont>
  <hr/>


  <h3 class="">Introduzca el código de validación</h3>

  <div class="alert alert-info" *ngIf="!this.submit">
      <i class="material-icons">info</i>
      <strong>&nbsp;IMPORTANTE:&nbsp;</strong>
      Recibira un correo con el código de validación al e-mail introducido en su ficha.<br/>
      Este correo puede tardar entre 1 y 5 minutos dependiendo de su servidor.
  </div>
  <hr/>
  <div class="centro">
    <code-input [isCodeHidden]="false"
      #codeInput
      [codeLength]="6"
      (codeChanged)="onCodeChanged($event)"
      (codeCompleted)="onCodeCompleted($event)">
    </code-input>
    <br/>
    <input type="button" class="btn btn-default btn-secondary" id="btn-submit-cancel" value="Cancelar" (click)="cancel()">
  </div>
</div>
