import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { RestApiService } from '../services/rest-api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ChangeDetectorRef } from '@angular/core';
import { environment } from '../../environments/environment';
import { Evento } from '../Model/Evento';
import { CifValidator } from "../validators/cif.validator";

@Component({
  selector: 'app-init-form',
  templateUrl: './init-form.component.html',
  styleUrls: ['./init-form.component.scss']
})
export class InitFormComponent implements OnInit {
  environment = environment;
  title = environment.app.title;
  initForm: FormGroup;
  campaignForm: FormGroup;
  submit: boolean = false;
  idProyecto: number;
  evento: Evento;
  displayInitForm: boolean = false;
  displayCampaignForm: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private restApiService: RestApiService,
              private spinner: NgxSpinnerService,
              private cdRef:ChangeDetectorRef) { }

  //Evita que se produzca el ERROR Error: ExpressionChangedAfterItHasBeenCheckedError
  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
        this.spinner.show();
        this.initializeForm();

        this.evento = {} as Evento;

        let auxIdProyecto = sessionStorage.getItem('idProyecto');
        if (auxIdProyecto!==undefined) {
          this.evento.id = +auxIdProyecto;
        }
        sessionStorage.clear();

        /*if (environment.production){
          //this.evento.id=72;
          this.route.queryParams.subscribe(params => {if (params.id!==undefined) this.evento.id = params.id;}); //OK cuando se arregle el Apache
          //this.route.paramMap.pipe(switchMap((params: ParamMap) => this.evento.id=params.get('id')));
        }else{
          this.route.params.subscribe(params => {
            if (params['id']!==undefined)
               this.evento.id = params['id'];
          });
        }*/
        var paramsString = window.location.search;
        var searchParams = new URLSearchParams(paramsString);
        if (searchParams.has("id")) this.evento.id = +searchParams.get("id");

        if (environment.debug) console.log("idProyecto="+this.evento.id); // id del evento

        if (this.evento.id!==undefined)
        {
            sessionStorage.setItem("idProyecto",this.evento.id.toString());
                  //Al inicializar la aplicacion es cuando obtenemos el token.
                  this.restApiService.getToken().subscribe( data => {
                     if (data && data.access_token!==null){
                       if (environment.debug) console.log("token="+data.access_token);
                       sessionStorage.setItem('token',data.access_token);

                       //Despues de obtener el token vamos a por la información del evento
                       if (this.evento!==null && this.evento.id!==0)
                       {
                          this.restApiService.getEventData(this.evento.id).subscribe( dataEvent => {
                              if (dataEvent && dataEvent.Properties?.Descrip?.Text!==''){
                                if (environment.debug) console.log("Información del evento obtenida");
                                this.evento.descripcion=dataEvent.Properties.Descrip.Text;
                                if (environment.debug) console.log(this.evento.descripcion);
                              }else{
                                if (environment.debug) console.log("Error al obtener la información del evento");
                                this.restApiService.showError("Error al obtener la información del evento. Vuelva a intentarlo más tarde.");
                              }
                          });
                       }
                     }else{
                      this.restApiService.showError("Error al obtener el token. Vuelva a intentarlo más tarde.");
                     }
                  });
       }else{
          this.restApiService.showError("El evento no existe. Vuelva a intentarlo más tarde.");
       }
       this.spinner.hide();
  }

  initializeForm() {
    this.campaignForm = new FormGroup({
          donacion: new FormControl('1')
    });

    this.initForm = this.formBuilder.group({
          cifForm: ['',[Validators.required,Validators.pattern(environment.app.cifPattern)]]
    });

    this.displayInitForm = true;
    this.displayCampaignForm = false; //Deshabilitamos donaciones a petición de AEE 23/08/2022
    sessionStorage.setItem("donacion", 'false');
  }

  onSubmit()
  {
    let cif = this.initForm.get('cifForm').value;
    //if (environment.debug) console.log("Submit - Cif="+cif);

      if (this.evento.id!==0) {
          if (cif !== undefined && cif !== '') {
              sessionStorage.setItem("idProyecto", this.evento.id.toString());
              sessionStorage.setItem("cif", cif);
              this.submit = true;
          } else {
              this.restApiService.showError("Debe indicar el cif de la empresa.");
          }

      } else {
          this.restApiService.showError("Debe existir un evento, no puede estar vacío.");
      }

  }

  selectDonacion()
  {
    this.spinner.show();
    let donacion = this.campaignForm.get('donacion').value;
    console.log("Ha seleccionado el tipo de donacion > " + donacion);
    switch ( donacion ) {
       case '1':
           sessionStorage.setItem("donacion", 'true');
           this.displayInitForm=true;
           this.displayCampaignForm=false;
           break;
       case 2:
           window.location.href = this.environment.campaignURL;
           break;
       case 3:
           sessionStorage.setItem("donacion", 'false');
           this.displayInitForm=true;
           this.displayCampaignForm=false;
           break;
    }
    this.spinner.hide();
  }

}
