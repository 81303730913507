<div class="row">
    <div class="col" >
        <div class="wrapper-progressBar">
            <ul class="progressBar">
                <li [ngClass]="{active: step.toString() === '1' ||  step.toString() === '2' ||  step.toString() === '3'  ||  step.toString() === '4'  ||  step.toString() === '5' }">
                    <span>Seleccionar Empresa</span>
                  </li>
                <li [ngClass]="{active: step.toString() === '2' ||  step.toString() === '3' ||  step.toString() === '4'  ||  step.toString() === '5' }">
                    <span>Seleccionar Asistente/s</span>
                  </li>
                <li [ngClass]="{active: step.toString() === '3' ||  step.toString() === '4' ||  step.toString() === '5' }">
                    <span>Información del Evento</span>
                </li>
                <li [ngClass]="{active: step.toString() === '4' ||  step.toString() === '5'  }">
                  <span>Realizar el Pago</span>
                </li>
                <li [ngClass]="{active: step.toString() === '5'}">
                  <span>Finalizar Inscripción</span>
                </li>
            </ul>
        </div>
    </div>
</div>
