<ngx-spinner bdColor="rgba(0, 0, 0, 0.1)" size="large" color="var(--blue)" type="square-jelly-box"
             [fullScreen]="true">

  <br/><br/><p style="color: var(--blue); font-weight: bolder; font-size: large"> Cargando... </p>
</ngx-spinner>

<!-- FORMULARIO DE EMPRESA-->
<div id="enterprise-form" class="container">
  <!-- Formulario -->

    <!-- Datos Empresa -->
    <h3 class="eb-heading" id="field_datosempresa" style="display: block;">Datos de la Empresa</h3>
    <div class="alert alert-info" *ngIf="this.empresa!==undefined && this.empresa.id!==undefined">
      <i class="material-icons">info</i>
      <strong> NOTA: </strong>
      <p>
        Este formulario no permite la modificación de los datos del cliente.<br/>
      </p>
      <p style="font-size:small">
        Si detecta algún error: Póngase en contacto con AEE en el teléfono (+34)-917-451-276.
      </p>
    </div>

  <form [formGroup]="enterpriseForm" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>CIF de la Empresa <span class="star">&nbsp;*</span></mat-label>
            <input matInput type="text" formControlName="cifForm" id="cifForm" value="{{this.empresa.cif}}">
          </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>Razón Social <span class="star">&nbsp;*</span></mat-label>
          <input matInput type="text" formControlName="razonSocialForm" id="razonSocialForm" size="50" value="{{this.empresa.razonSocial}}">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>Nombre Comercial <span class="star">&nbsp;*</span></mat-label>
          <input matInput type="text" formControlName="nombreComercialForm" id="nombreComercialForm" size="50" value="{{this.empresa.nombreComercial}}">
        </mat-form-field>
      </div>
    </div>

    <!-- Domicilio fiscal -->
    <h4 class="eb-heading" id="field_domiciliofiscal" style="display: block;">Domicilio Fiscal</h4>

    <app-pais-input [enterpriseForm]="enterpriseForm" (paisSelected)="paisSelected = $event"></app-pais-input>
    <app-provincia-input [enterpriseForm]="enterpriseForm" (provinciaSelected)="provinciaSelected = $event" [idPaisSelected]="paisSelected"></app-provincia-input>
    <app-poblacion-input [enterpriseForm]="enterpriseForm" (poblacionSelected)="poblacionSelected = $event" [idPaisSelected]="paisSelected" [idProvinciaSelected]="provinciaSelected"></app-poblacion-input>
    <app-codigo-postal-input [enterpriseForm]="enterpriseForm" [idPaisSelected]="paisSelected" [idProvinciaSelected]="provinciaSelected" [idPoblacionSelected]="poblacionSelected"></app-codigo-postal-input>

    <div class="row" style=" width:100%;">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>Dirección <span class="star">&nbsp;*</span></mat-label>
          <input matInput type="text" formControlName="direccionForm" id="direccionForm" size="50" value="{{this.empresa.direccion}}">
        </mat-form-field>
      </div>
    </div>

    <!--div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>Email <span class="star">&nbsp;*</span></mat-label>
          <input matInput type="text" formControlName="emailEmpresaForm" id="emailEmpresaForm" value="{{this.empresa.email}}" size="50">
        </mat-form-field>
      </div>
    </div-->

    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>Telefono </mat-label>
          <input matInput type="text" formControlName="telefonoEmpresaForm" id="telefonoEmpresaForm" value="{{this.empresa.telefono}}" size="50">
        </mat-form-field>
      </div>
    </div>

    <!-- Mensajes de Error -->
    <mat-error *ngIf="enterpriseForm.get('cifForm').hasError('required') && (enterpriseForm.get('cifForm').touched || this.submit)">
      <strong>&nbsp;<i class="material-icons">warning</i>&nbsp;ERROR:&nbsp;</strong><span>CIF es Obligatorio</span>
    </mat-error>

    <mat-error *ngIf="enterpriseForm.get('razonSocialForm').hasError('required') && (enterpriseForm.get('razonSocialForm').touched || this.submit)">
      <strong>&nbsp;<i class="material-icons">warning</i>&nbsp;ERROR:&nbsp;</strong><span>Razón Social es Obligatorio</span>
    </mat-error>

    <mat-error *ngIf="enterpriseForm.get('nombreComercialForm').hasError('required') && (enterpriseForm.get('nombreComercialForm').touched || this.submit)">
      <strong>&nbsp;<i class="material-icons">warning</i>&nbsp;ERROR:&nbsp;</strong><span>Nombre Comercial es Obligatorio</span>
    </mat-error>

    <mat-error *ngIf="enterpriseForm.get('paisForm').hasError('required') && (enterpriseForm.get('paisForm').touched || this.submit)">
      <strong>&nbsp;<i class="material-icons">warning</i>&nbsp;ERROR:&nbsp;</strong><span>País es Obligatorio</span>
    </mat-error>

    <mat-error *ngIf="enterpriseForm.get('codigoPostalForm').hasError('required') && (enterpriseForm.get('codigoPostalForm').touched || this.submit)">
      <strong>&nbsp;<i class="material-icons">warning</i>&nbsp;ERROR:&nbsp;</strong><span>Código Postal es Obligatorio</span>
    </mat-error>

    <mat-error *ngIf="enterpriseForm.get('provinciaForm').hasError('required') && (enterpriseForm.get('provinciaForm').touched || this.submit)">
      <strong>&nbsp;<i class="material-icons">warning</i>&nbsp;ERROR:&nbsp;</strong><span>Provincia es Obligatorio</span>
    </mat-error>

    <mat-error *ngIf="enterpriseForm.get('poblacionForm').hasError('required') && (enterpriseForm.get('poblacionForm').touched || this.submit)">
      <strong>&nbsp;<i class="material-icons">warning</i>&nbsp;ERROR:&nbsp;</strong><span>Población es Obligatorio</span>
    </mat-error>

    <mat-error *ngIf="enterpriseForm.get('direccionForm').hasError('required') && (enterpriseForm.get('direccionForm').touched || this.submit)">
      <strong>&nbsp;<i class="material-icons">warning</i>&nbsp;ERROR:&nbsp;</strong><span>Dirección es Obligatorio</span>
    </mat-error>

    <!--mat-error *ngIf="enterpriseForm.get('emailEmpresaForm').hasError('required') && (enterpriseForm.get('emailEmpresaForm').touched || this.submit)">
      <strong>&nbsp;<i class="material-icons">warning</i>&nbsp;ERROR:&nbsp;</strong><span>Email Obligatorio</span>
    </mat-error>
    <mat-error *ngIf="enterpriseForm.get('emailEmpresaForm').hasError('email')">
      <strong>&nbsp;<i class="material-icons">warning</i>&nbsp;ERROR:&nbsp;</strong><span>Email No Valido</span>
    </mat-error-->

    <hr>
    <!-- Botones de accion -->

    <div class="row" style="text-align:center;">
      <div id="botones" class="col">
        <input type="submit" class="btn btn-default btn-primary" id="btn-submit-doInscription" value="Siguiente" ><!--disabled-->
        &nbsp;
        <input type="button" class="btn btn-default btn-secondary" id="btn-submit-cancel" value="Cancelar" (click)="cancel()">
      </div>
    </div>
    <br/><br/>
  </form>
</div>
