import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RestApiService } from '../services/rest-api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { ChangeDetectorRef } from '@angular/core';
import { environment } from '../../environments/environment';
import { Empresa } from '../Model/Empresa';
import { Pais } from '../Model/Pais';
//import { PaisInputComponent } from '../components/pais-input/pais-input.component';

@Component({
  selector: 'app-enterprise-form',
  templateUrl: './enterprise-form.component.html',
  styleUrls: ['./enterprise-form.component.scss']
})

export class EnterpriseFormComponent implements OnInit {
  environment = environment;
  title = environment.app.title;

  @Input() tomaDeDatos: boolean = false;
  @Output() clienteAsociado = new EventEmitter<Boolean>();
  enterpriseForm: FormGroup;
  empresa: Empresa;
  cif: string;
  submit = false;
  paisSelected: string;
  provinciaSelected: string;
  poblacionSelected: string;
  codigoPostalSelected: number;

//  @ViewChild(PaisInputComponent) paisComponent: PaisInputComponent;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private restApiService: RestApiService,
              private spinner: NgxSpinnerService,
              private cdRef:ChangeDetectorRef) { }

  //Evita que se produzca el ERROR Error: ExpressionChangedAfterItHasBeenCheckedError
  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    this.spinner.show();
    this.initializeForm();
    this.empresa = {} as Empresa;
    this.cif = sessionStorage.getItem("cif");
    if (environment.debug) console.log("cif="+this.cif);

    sessionStorage.setItem("idCliente",'');

    this.restApiService.getEnterpriseData(this.cif).subscribe( dataEnterprise => {
        if (dataEnterprise !== undefined && dataEnterprise.length > 0){
          if (environment.debug) console.log("Información de la Empresa obtenida");
          this.empresa.id = dataEnterprise[0]?.IdCliente;
          this.empresa.cif = this.cif; //dataEnterprise[0]?.Nif;
          this.empresa.razonSocial = dataEnterprise[0]?.RazonSocial;
          this.empresa.nombreComercial = dataEnterprise[0]?.Cliente;
          this.empresa.idPais = dataEnterprise[0]?.IdPais;
          this.empresa.pais = dataEnterprise[0]?.Pais;
          this.empresa.idProvincia = dataEnterprise[0]?.IdProvincia;
          this.empresa.provincia = dataEnterprise[0]?.Provincia;
          this.empresa.idPoblacion = dataEnterprise[0]?.IdPoblacion;
          this.empresa.poblacion = dataEnterprise[0]?.Ciudad;
          this.empresa.direccion = dataEnterprise[0]?.Direccion;
          this.empresa.codigoPostal = dataEnterprise[0]?.CodPostal;
          //this.empresa.email = dataEnterprise[0]?.E_Mail;
          this.empresa.telefono = dataEnterprise[0]?.NumTelefono;

          sessionStorage.setItem("idCliente",dataEnterprise[0].IdCliente);

          //Actualizamos los campos del formulario.
          this.enterpriseForm.controls['cifForm'].patchValue(this.empresa.cif);
          this.enterpriseForm.controls['razonSocialForm'].patchValue(this.empresa.razonSocial);
          this.enterpriseForm.controls['nombreComercialForm'].patchValue(this.empresa.nombreComercial);
          //this.enterpriseForm.controls['emailEmpresaForm'].patchValue(this.empresa.email);
          this.enterpriseForm.controls['telefonoEmpresaForm'].patchValue(this.empresa.telefono);

          if (this.empresa.idPais == null)
            this.paisSelected = this.empresa.pais;

          this.enterpriseForm.controls['paisForm'].patchValue(this.empresa.pais);

          if (this.empresa.idProvincia == null)
              this.provinciaSelected = this.empresa.provincia;

          this.enterpriseForm.controls['provinciaForm'].patchValue(this.empresa.provincia);

          if (this.empresa.idPoblacion == null)
              this.poblacionSelected = this.empresa.poblacion;

          this.enterpriseForm.controls['poblacionForm'].patchValue(this.empresa.poblacion);

          if (this.empresa.codigoPostal !== null)
              this.codigoPostalSelected = this.empresa.codigoPostal;

          this.enterpriseForm.controls['codigoPostalForm'].patchValue(this.empresa.codigoPostal);

          if (this.empresa.direccion !== null)
              this.enterpriseForm.controls['direccionForm'].patchValue(this.empresa.direccion);

          this.enterpriseForm.controls['cifForm'].disable();
          this.enterpriseForm.controls['razonSocialForm'].disable();
          this.enterpriseForm.controls['nombreComercialForm'].disable();
          this.enterpriseForm.controls['paisForm'].disable();
          this.enterpriseForm.controls['provinciaForm'].disable();
          this.enterpriseForm.controls['poblacionForm'].disable();
          this.enterpriseForm.controls['codigoPostalForm'].disable();
          this.enterpriseForm.controls['direccionForm'].disable();
          //this.enterpriseForm.controls['emailEmpresaForm'].disable();
          this.enterpriseForm.controls['telefonoEmpresaForm'].disable();
        }else{
          if (environment.debug) console.log("Error al obtener la información de la Empresa");
          this.empresa.cif = this.cif;
          this.enterpriseForm.controls['cifForm'].patchValue(this.empresa.cif);

          this.enterpriseForm.controls['cifForm'].enable();
          this.enterpriseForm.controls['razonSocialForm'].enable();
          this.enterpriseForm.controls['nombreComercialForm'].enable();
          this.enterpriseForm.controls['paisForm'].enable();
          this.enterpriseForm.controls['provinciaForm'].enable();
          this.enterpriseForm.controls['poblacionForm'].enable();
          this.enterpriseForm.controls['codigoPostalForm'].enable();
          this.enterpriseForm.controls['direccionForm'].enable();
          //this.enterpriseForm.controls['emailEmpresaForm'].enable();
          this.enterpriseForm.controls['telefonoEmpresaForm'].enable();
        }
        this.spinner.hide();
    });
  }

  initializeForm() {
    this.enterpriseForm = this.formBuilder.group({
      cifForm: new FormControl('',Validators.required),
      razonSocialForm: new FormControl('',Validators.required),
      nombreComercialForm: new FormControl('',Validators.required),
      paisForm: new FormControl('',Validators.required),
      codigoPostalForm: new FormControl('',Validators.required),
      provinciaForm: new FormControl('',Validators.required),
      poblacionForm: new FormControl('',Validators.required),
      direccionForm: new FormControl('',Validators.required),
      //emailEmpresaForm: new FormControl('',[Validators.required, Validators.email]),
      telefonoEmpresaForm: new FormControl('')
    });
  }

  onSubmit() {
    if (this.empresa.id == undefined) {
        if (environment.debug) console.log("Submit Empresa");
        this.submit = true;
        if (this.formValidator()) {
           let empresa = {} as Empresa;
           empresa.cif = this.cif,//this.enterpriseForm.controls['cifForm'].value,
           empresa.razonSocial = this.enterpriseForm.controls['razonSocialForm'].value,
           empresa.nombreComercial = this.enterpriseForm.controls['nombreComercialForm'].value,
           empresa.pais = this.enterpriseForm.controls['paisForm'].value,
           empresa.codigoPostal = this.enterpriseForm.controls['codigoPostalForm'].value,
           empresa.provincia = this.enterpriseForm.controls['provinciaForm'].value,
           empresa.poblacion = this.enterpriseForm.controls['poblacionForm'].value,
           empresa.direccion = this.enterpriseForm.controls['direccionForm'].value,
           //empresa.email = this.enterpriseForm.controls['emailEmpresaForm'].value,
           empresa.telefono = this.enterpriseForm.controls['telefonoEmpresaForm'].value

          this.restApiService.setEnterpriseData(empresa).subscribe(dataEnterprise => {
            if (dataEnterprise) {
                this.restApiService.showSuccess("Empresa guardada correctamente.");
                sessionStorage.setItem("idCliente",dataEnterprise.Data.IdCliente[0].IdCliente);
            }else{
                sessionStorage.setItem("idCliente",'');
                this.restApiService.showError("Ha ocurrido un error al guardar la empresa");
            }

            if(!this.tomaDeDatos)
                this.router.navigate(['search-contact-form']);
            else
                this.clienteAsociado.emit(true);

          });
        }else{
          this.restApiService.showError("Debe completar los campos obligatorios.");
        }
    }else{
        sessionStorage.setItem("idCliente",this.empresa.id);
        if(!this.tomaDeDatos)
            this.router.navigate(['search-contact-form']);
        else
            this.clienteAsociado.emit(true);
    }
  }

  formValidator() {
    if (this.enterpriseForm.get('cifForm').hasError('required') ||
        this.enterpriseForm.get('razonSocialForm').hasError('required') ||
        this.enterpriseForm.get('nombreComercialForm').hasError('required') ||
        this.enterpriseForm.get('paisForm').hasError('required') ||
        this.enterpriseForm.get('codigoPostalForm').hasError('required') ||
        this.enterpriseForm.get('provinciaForm').hasError('required')||
        this.enterpriseForm.get('poblacionForm').hasError('required')||
        this.enterpriseForm.get('direccionForm').hasError('required')) //this.enterpriseForm.get('emailEmpresaForm').hasError('required')
          return false;
    else
          return true;
  }

  cancel() {
    if (environment.debug) console.log("Cancel");
    location.reload();
  }
}
