import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RestApiService } from '../services/rest-api.service';
import { RedsysApiService } from '../services/redsys-api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef } from '@angular/core';

import { environment } from '../../environments/environment';

import { CountdownComponent } from 'ngx-countdown';

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss']
})
export class PaymentFormComponent implements OnInit {
  environment = environment;
  title = environment.app.title;
  //paymentForm: FormGroup;
  submit: boolean = false;
  error: boolean = false;

  idProyecto: any;
  idCliente:any;
  contactosSelected: any;
  totalContactos: number;
  transferencia: boolean;
  tipoPago: string = 'TPV';
  paymentForm: FormGroup;
  @ViewChild('tpvPaymentForm') tpvPaymentForm: ElementRef;
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;

  //Parametros para la pasarela de pago.
  importe: any = '';
  merchantSignature: any = '';
  merchantParameters: any = '';

  bPagoActivo = true;


  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private http: HttpClient,
              private restApiService: RestApiService,
              private redsysApiService: RedsysApiService,
              private spinner: NgxSpinnerService,
              private cdRef:ChangeDetectorRef) {
             }

  //Evita que se produzca el ERROR Error: ExpressionChangedAfterItHasBeenCheckedError
  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
        this.spinner.show();
        this.initializeForm();
        this.spinner.hide();

        this.idProyecto = sessionStorage.getItem("idProyecto");
        this.idCliente = sessionStorage.getItem("idCliente");
        this.contactosSelected = JSON.parse(sessionStorage.getItem("contactosSelected"));
        this.transferencia = JSON.parse(sessionStorage.getItem("transferencia"));
        if (environment.debug) console.log("Transferencia > "+this.transferencia);

        //if (this.evento)
        this.submit = false;

        if (this.contactosSelected!==null)
        {
          let contactoInicial = this.contactosSelected[0];
          this.totalContactos = this.contactosSelected.length;
          let idDocs = sessionStorage.getItem("idDocs");

          if (this.idProyecto!==null && contactoInicial!==null && this.totalContactos>0)
          {
              //this.importe = 123;
              this.restApiService.calcularPrecio( this.idProyecto,
                                                  contactoInicial,
                                                  this.idCliente,
                                                  idDocs
                                                ).subscribe(dataPrecio =>
              {
                    //Obtenemos el importe por unidad.
                    this.importe = dataPrecio.Data.PrecioTotal[0].PrecioTotal;
                    if (this.importe!==null && this.importe>0)
                    {
                      //Multiplicamos el importe unitario por el por número de contactos seleccionados.
                      this.importe = this.totalContactos * this.importe;
                    }else{
                      this.error = true;
                      if (environment.debug) console.log("Error al obtener la información del importe a pagar");
                      this.restApiService.showError("Error al obtener la información del importe a pagar. Vuelva a intentarlo más tarde.");
                    }
              });
          }else{
              this.error = true;
              this.restApiService.showError("Se ha producido un error en el proceso de inscripcion.");
          }
        }else{
            this.error = true;
            this.restApiService.showError("Se ha producido un error en el proceso de inscripcion.");
        }
  }

  initializeForm()
  {
    this.paymentForm = this.formBuilder.group({
      tipoPagoForm: new FormControl('TPV')
    });

    let timerId = setInterval(() => this.bPagoActivo=false, this.environment.timeout);
  }

  selectTipoPago()
  {
    if (this.paymentForm.controls['tipoPagoForm'].value!=='')
    {
      this.tipoPago = this.paymentForm.controls['tipoPagoForm'].value;

      if (this.tipoPago === 'TPV')
      {
        sessionStorage.setItem("tipoPago","2"); //TPV
        this.redsysApiService.getRedsysApiData(this.importe,this.contactosSelected,this.idProyecto,this.idCliente).subscribe(data => {
          if (data==undefined)
          {
              this.error = true;
              this.restApiService.showError("No se puede conectar con la Pasarela de Pago. Vuelva a intentarlo más tarde.");
          }else{
            this.merchantSignature = data.merchantSignature;
            this.merchantParameters = data.merchantParameters;
            this.error = false;
          }
        });
      }else{
        sessionStorage.setItem("tipoPago","1"); //Transferencia
      }
      this.submit = true;
    }else{
      this.restApiService.showError("Debe seleccionar la forma de pago.");
    }
  }



  changeTipoPago()
  {
    this.submit = false;
  }

  onSubmit()
  {
    if (environment.debug) console.log("Submit Payment");
    this.router.navigate(['end-form']);
  }

  cancel()
  {
    if (environment.debug) console.log("Cancel");
    this.router.navigate(['init-form']);
    //location.reload();
  }
}
