import { Component, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from "@angular/material/select"
import { MatOption } from "@angular/material/core"
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { Pais } from '../../Model/Pais';
import { RestApiService } from '../../services/rest-api.service';

@Component({
  selector: 'app-pais-input',
  templateUrl: './pais-input.component.html',
  styleUrls: ['./pais-input.component.css']
})

export class PaisInputComponent {
  @Output() paises: Pais[] = [];
  paisesStr: string[] = [];
  filteredPaises: Observable<string[]>;
  @Input() enterpriseForm: FormGroup;
  @Input() @Output() paisSelected = new EventEmitter<any>();

  constructor(private restApiService: RestApiService) {
  }

  ngOnInit(): void {
      this.restApiService.getCountriesData().subscribe( dataCountry => {
          dataCountry.forEach(country => {
            let pais = {} as Pais;
            pais.id = country.IdPais;
            pais.nombre = country.Pais;
            pais.nacionalidad = country.Nacionalidad;
            this.paises.push(pais);
            this.paisesStr.push(pais.nombre);
          });
          this.filteredPaises = this.enterpriseForm.controls['paisForm'].valueChanges
            .pipe(
              startWith(''),
              map(value => this._filterPais(value))
            );

          this._filterPais(this.enterpriseForm.controls['paisForm'].value);
      });
  }

  private _filterPais(value: string): string[] {
    const filterValue = value.toLowerCase();
    let returnValue = this.paisesStr.filter(option => option.toLowerCase().includes(filterValue));
    if (returnValue.length==1)
    {
      let pais = this.paises.find(pais => pais.nombre == returnValue[0]);
      if (pais!=undefined) this.paisSelected.emit(pais.id);
    }
    return returnValue;
  }

}
