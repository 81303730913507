<div class="row" [formGroup]="enterpriseForm">
  <div class="col">
    <mat-form-field appearance="outline" class="autocomplete-full-width">
      <mat-label>Provincia <span class="star">&nbsp;*</span></mat-label>
      <input matInput
             type="text"
             placeholder="Seleccione una provincia"
             aria-label="Provincia"
             formControlName="provinciaForm"
             [matAutocomplete]="auto"
             autocomplete="off">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let provincia of filteredProvincias | async" [value]="provincia">
          {{provincia}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</div>
