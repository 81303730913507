<ngx-spinner bdColor="rgba(0, 0, 0, 0.1)" size="large" color="var(--blue)" type="square-jelly-box"
             [fullScreen]="true">

  <br/><br/><p style="color: var(--blue); font-weight: bolder; font-size: large"> Cargando... </p>
</ngx-spinner>

<!-- FORMULARIO DE BUSQUEDA DE ASISTENTE-->
<div id="search-contact-form" class="container">

  <p style="text-align:center;padding-top:20px;">
    <img class="logo" src="/assets/img/aee.png" alt="AEE" align="middle">
  </p>

  <h1>
    {{title}}
  </h1>

  <hr/>
  <app-progress-bar step="2"></app-progress-bar>
  <hr/>

  <!-- Info. del asistente -->
  <h3 class="eb-heading" id="field_datosinscrito" style="display: block;" *ngIf="!this.submit">Buscar Asistente</h3>

  <!-- Busqueda de Asistente -->
  <div class="alert alert-info" *ngIf="!this.submit && !this.error">
    <i class="material-icons">info</i>
    Introduzca email y pulse<strong>&nbsp;Buscar</strong>
    <p style="font-size:small">
      Si es un contacto nuevo introduzca el email y después de la busqueda podrá rellenar el resto de datos
    </p>
  </div>
  <hr *ngIf="!this.submit  && !this.error"/>

  <form [formGroup]="searchContactForm" (ngSubmit)="onSubmit()">
    <div class="row" *ngIf="!this.submit  && !this.error">
      <div class="col" style="text-align:center;">
        <mat-form-field appearance="outline">
          <mat-label>Email <span class="star">&nbsp;*</span></mat-label>
          <input matInput type="text" formControlName="emailAsistenteForm" id="emailAsistenteForm" size="50">
        </mat-form-field>
        &nbsp;
        <input type="submit" value="Buscar" id="btn-submit-email" class="btn btn-default btn-primary" [disabled]="!searchContactForm.valid">&nbsp;
        <input type="button" class="btn btn-default btn-secondary" id="btn-submit-cancel" value="Cancelar" (click)="cancel()">

        <!--Texto Error -->
        <mat-error *ngIf="searchContactForm.get('emailAsistenteForm').hasError('required') && searchContactForm.get('emailAsistenteForm').touched">
          <strong>&nbsp;<i class="material-icons">warning</i>&nbsp;ERROR:&nbsp;</strong><span>Email Obligatorio</span>
        </mat-error>
        <mat-error *ngIf="searchContactForm.get('emailAsistenteForm').hasError('email')">
          <strong>&nbsp;<i class="material-icons">warning</i>&nbsp;ERROR:&nbsp;</strong><span>Email No Valido</span>
        </mat-error>
      </div>
    </div>

    <!-- En caso de error -->
    <div *ngIf="this.error">
      <div class="row">
        <div class="alert alert-danger col" style="text-align:center;">
          <i class="material-icons">error</i>
          Se ha producido un error en el proceso de inscripción y no se puede continuar. Por favor vuelva a intentar realizar la inscripción.
        </div>
      </div>
      <div class="row" style="text-align:center;">
        <div id="botones" class="col">
          <input type="button" class="btn btn-default btn-danger" id="btn-submit-cancel2" value="Salir" (click)="cancel()">
        </div>
      </div>
    </div>
  </form>

  <div class="row" *ngIf="this.submit">
    <app-contact-form></app-contact-form>
  </div>

</div>
