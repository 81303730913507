<ngx-spinner bdColor="rgba(0, 0, 0, 0.1)" size="large" color="var(--blue)" type="square-jelly-box"
             [fullScreen]="true">

  <br/><br/><p style="color: var(--blue); font-weight: bolder; font-size: large"> Cargando... </p>
</ngx-spinner>

<!-- FORMULARIO DE CONTACTO-->
<div id="contact-form" class="container">
  <!-- Datos Contacto -->
    <h3 class="eb-heading" id="field_datos_contacto" style="display: block;">Introduzca Datos del Contacto</h3>
    <!-- Formulario -->

    <div class="alert alert-info" *ngIf="this.contactoModify!==undefined && this.contactoModify.id!==undefined">
      <i class="material-icons">info</i>
      <strong> NOTA: </strong>
      <p>
        Este formulario no permite la modificación de los datos del contacto.<br/>
      </p>
      <p style="font-size:small">
        Si detecta algún error: Póngase en contacto con AEE en el teléfono (+34)-917-451-276.
      </p>
    </div>

    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">

      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>NIF <span class="star">&nbsp;*</span></mat-label>
            <input matInput type="text" formControlName="nifContactoForm" id="nifContactoForm" value="{{this.nif}}">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Email <span class="star">&nbsp;*</span></mat-label>
            <input matInput type="text" formControlName="emailContactoForm" id="emailContactoForm" value="{{this.contactoModify?.email=='' ? 'null' : this.contactoModify?.email}}" size="50">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Apellidos, Nombre <span class="star">&nbsp;*</span></mat-label>
            <input matInput type="text" formControlName="nombreApellidosContactoForm" id="nombreApellidosContactoForm" value="{{this.contactoModify?.nombre == 'null' ? '' : this.contactoModify?.nombre}}" size="50">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Cargo <span class="star">&nbsp;*</span></mat-label>
            <input matInput type="text" formControlName="cargoContactoForm" id="cargoContactoForm" value="{{this.contactoModify?.cargo=='null' ? '' : this.contactoModify?.cargo}}" size="50">
          </mat-form-field>
        </div>
      </div>


      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Teléfono Móvil <span class="star">&nbsp;*</span></mat-label>
            <input matInput type="text" formControlName="telefonoMovilContactoForm" id="telefonoMovilContactoForm" value="{{this.contactoModify?.telefonoMovil=='null' ? '' : this.contactoModify?.telefonoMovil}}">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Teléfono Fijo</mat-label>
            <input matInput type="text" formControlName="telefonoFijoContactoForm" id="telefonoFijoContactoForm" value="{{this.contactoModify?.telefonoFijo=='null' ? '' : this.contactoModify?.telefonoFijo}}">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Email Adicional</mat-label>
            <input matInput type="text" formControlName="emailAdicional1ContactoForm" id="emailAdicional1ContactoForm" value="{{this.contactoModify?.emailAdicional1}}" size="50">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>2º Email Adicional</mat-label>
            <input matInput type="text" formControlName="emailAdicional2ContactoForm" id="emailAdicional2ContactoForm" value="{{this.contactoModify?.emailAdicional2=='null' ? '' : this.contactoModify?.emailAdicional2}}" size="50">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Facebook</mat-label>
            <input matInput type="text" formControlName="facebookContactoForm" id="facebookContactoForm" value="{{this.contactoModify?.facebook=='null' ? '' : this.contactoModify?.facebook}}" size="50">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Twitter</mat-label>
            <input matInput type="text" formControlName="twitterContactoForm" id="twitterContactoForm" value="{{this.contactoModify?.twitter=='null' ? '' : this.contactoModify?.twitter}}" size="50">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>LinkedIn</mat-label>
            <input matInput type="text" formControlName="linkedinContactoForm" id="linkedinContactoForm" value="{{this.contactoModify?.linkedin=='null' ? '' : this.contactoModify?.linkedin}}" size="50">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Skype</mat-label>
            <input matInput type="text" formControlName="skypeContactoForm" id="skypeContactoForm" value="{{this.contactoModify?.skype=='null' ? '' : this.contactoModify?.skype}}" size="50">
          </mat-form-field>
        </div>
      </div>

      <hr>

      <!-- Politica privacidad -->
      <div class="row">
        <div class="col">
            <mat-checkbox formControlName="rgpdForm" id="rgpdForm" required></mat-checkbox>&nbsp;Acepto la política de Privacidad de AEE
        </div>
      </div>

      <div class="form-group" style="display: block; text-align:justify;">
        <p>La entidad ASOCIACIÓN EMPRESARIAL EÓLICA con domicilio en CALLE SOR ANGELA DE LA CRUZ, 2, PLANTA 14D, 28020, MADRID le informa que la finalidad de los datos recogidos es gestionar la solicitud de inscripción y establecer una relación profesional , mercantil y/o comercial,&nbsp; en base a su consentimiento, procediendo estos datos del propio interesado titular de los datos. Sus datos no serán cedidos ni comunicados a terceras empresas. Sus datos serán conservados todo el tiempo que usted decida y mientras este vigente la relación profesional, mercantil y/o comercial vigente. Puede ejercer sus derechos de acceso, rectificación, supresión, limitación u oposición a su tratamiento, derecho a no ser objeto de decisiones automatizadas, así como a obtener información clara y transparente sobre el tratamiento de sus datos. Desde ASOCIACIÓN EMPRESARIAL EÓLICA ponemos el máximo empeño para cumplir con la normativa de protección de datos dado que es el activo más valioso para nosotros. No obstante, le informamos que en caso de que usted entienda que sus derechos se han visto menoscabados, puede presentar una reclamación ante la Agencia Española de Protección de Datos (AEPD).&nbsp; Puede consultar la información adicional y detallada sobre protección de Datos solicitándolo a la dirección de correo electrónico <a href="mailto:datos@aeeolica.org">datos@aeeolica.org</a>.
        </p>
        <p>De acuerdo con la RGPD, le informamos que sus datos personales y dirección de correo electrónico forman parte de un fichero automatizado, cuyo responsable es AEE, siendo la finalidad del fichero la gestión de la relación mercantil que mantiene con AEE. Los datos han sido facilitados por el propio interesado. Si lo desea, puede ejercer sus derechos de acceso, rectificación, cancelación y oposición de sus datos enviando un correo electrónico a <a href="mailto:datos@aeeolica.org">datos@aeeolica.org</a>. Deberá indicar en el asunto del correo electrónico el derecho que desea ejercitar y adjuntar la documentación acreditativa que facilite su identificación Para más información sobre nuestra Política de Protección de Datos, pinche <a href="https://www.aeeolica.org/es/politica-de-privacidad/" target="_blank" rel="noopener noreferrer">aquí</a>.
        </p>
      </div>


      <!-- Mensajes de Error -->
      <mat-error *ngIf="contactForm.get('emailContactoForm').hasError('required') && (contactForm.get('emailContactoForm').touched || this.submit)">
        <strong>&nbsp;<i class="material-icons">warning</i>&nbsp;ERROR:&nbsp;</strong><span>Email Obligatorio</span>
      </mat-error>

      <mat-error *ngIf="contactForm.get('nifContactoForm').hasError('required') && (contactForm.get('nifContactoForm').touched || this.submit)">
        <strong>&nbsp;<i class="material-icons">warning</i>&nbsp;ERROR:&nbsp;</strong><span>NIF es Obligatorio</span>
      </mat-error>

      <mat-error *ngIf="contactForm.get('nombreApellidosContactoForm').hasError('required') && (contactForm.get('nombreApellidosContactoForm').touched || this.submit)">
        <strong>&nbsp;<i class="material-icons">warning</i>&nbsp;ERROR:&nbsp;</strong><span>Nombre y Apellidos es Obligatorio</span>
      </mat-error>

      <mat-error *ngIf="contactForm.get('cargoContactoForm').hasError('required') && (contactForm.get('cargoContactoForm').touched || this.submit)">
        <strong>&nbsp;<i class="material-icons">warning</i>&nbsp;ERROR:&nbsp;</strong><span>Cargo es Obligatorio</span>
      </mat-error>

      <mat-error *ngIf="contactForm.get('sedeForm').hasError('required') && (contactForm.get('sedeForm').touched || this.submit)">
        <strong>&nbsp;<i class="material-icons">warning</i>&nbsp;ERROR:&nbsp;</strong><span>Sede es Obligatorio</span>
      </mat-error>

      <mat-error *ngIf="contactForm.get('telefonoMovilContactoForm').hasError('required') && (contactForm.get('telefonoMovilContactoForm').touched || this.submit)">
        <strong>&nbsp;<i class="material-icons">warning</i>&nbsp;ERROR:&nbsp;</strong><span>Teléfono Móvil es Obligatorio</span>
      </mat-error>

      <mat-error *ngIf="contactForm.get('rgpdForm').hasError('required') && (contactForm.get('rgpdForm').touched || this.submit)">
        <strong>&nbsp;<i class="material-icons">warning</i>&nbsp;ERROR:&nbsp;</strong><span>Debe aceptar la Política de Protección de Datos de AEE.</span>
      </mat-error>

      <hr>
      <!-- Botones de accion -->
      <div class="row" style="text-align:center;">
        <div id="botones" class="col">
          <input type="submit" class="btn btn-default btn-primary" id="btn-submit-doInscription" value="Siguiente" [disabled]="!contactForm.valid"><!--disabled-->&nbsp;
          <input type="button" class="btn btn-default btn-secondary" id="btn-submit-cancel" value="Cancelar" (click)="cancel()">
        </div>
      </div>
      <br/><br/>
    </form>
</div>

