import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { RestApiService } from '../services/rest-api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ChangeDetectorRef } from '@angular/core';
import { environment } from '../../environments/environment';
import { Evento } from '../Model/Evento';
import { NifValidator } from "../validators/nif.validator";
import { CodeInputModule } from 'angular-code-input';
import {CodeInputComponent} from 'angular-code-input';

@Component({
  selector: 'app-confirm-form-cont',
  templateUrl: './confirm-form-cont.component.html',
  styleUrls: ['./confirm-form-cont.component.scss']
})
export class ConfirmFormContComponent implements OnInit {
  @ViewChild('codeInput') codeInput !: CodeInputComponent;
  environment = environment;
  titleRegistro = environment.app.titleRegistro;
  confirmForm: FormGroup;
  submit: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private restApiService: RestApiService,
              private spinner: NgxSpinnerService,
              private cdRef:ChangeDetectorRef) { }

  //Evita que se produzca el ERROR Error: ExpressionChangedAfterItHasBeenCheckedError
  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
        this.spinner.show();

       this.spinner.hide();
  }

  // this called every time when user changed the code
  onCodeChanged(code: string) {
  }

  // this called only if user entered full code
  onCodeCompleted(code: string) {
    let idContacto = sessionStorage.getItem("idContacto");

      if (environment.pruebas && code=="123456"){
          this.router.navigate(['contact-form-cont']);
      }else{
        this.restApiService.comprobarCodigoVerificacion(idContacto,code).subscribe( data => {
              if (data.Success && data.Data.Validacion[0].Validacion){
                this.router.navigate(['contact-form-cont']);
              } else{
                this.restApiService.showError("Código erroneo, intentelo de nuevo");
                this.codeInput.reset();
              }
        });
      }
  }

  onSubmit() {
    
      this.submit = true;
      this.router.navigate(['contact-form-cont']);

  }

  cancel(){
    this.router.navigate(['init-form-cont']);
  }

}
