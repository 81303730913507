import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { RestApiService } from '../services/rest-api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ChangeDetectorRef } from '@angular/core';
import { environment } from '../../environments/environment';
import { Evento } from '../Model/Evento';
import { NifValidator } from "../validators/nif.validator";


@Component({
  selector: 'app-init-form-cont',
  templateUrl: './init-form-cont.component.html',
  styleUrls: ['./init-form-cont.component.scss']
})
export class InitFormContComponent implements OnInit {
  environment = environment;
  titleRegistro = environment.app.titleRegistro;
  initForm: FormGroup;
  searchEmailForm: FormGroup;
  submit: boolean = false;
  requestEmail: boolean = false;
  error: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private restApiService: RestApiService,
              private spinner: NgxSpinnerService,
              private cdRef:ChangeDetectorRef) { }

  //Evita que se produzca el ERROR Error: ExpressionChangedAfterItHasBeenCheckedError
  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
        this.spinner.show();
        this.initializeForm();

        sessionStorage.clear();
        sessionStorage.setItem('nifExist', "si");
        sessionStorage.setItem('emailExist', "si");
        //Al inicializar la aplicacion es cuando obtenemos el token.
        this.restApiService.getToken().subscribe( data => {
            if (data && data.access_token!==null){
                if (environment.debug) console.log("token="+data.access_token);
                sessionStorage.setItem('token',data.access_token);
            }else{
                this.restApiService.showError("Error al obtener el token. Vuelva a intentarlo más tarde.");
            }
        });
       this.spinner.hide();
  }

  initializeForm() {
    this.initForm = this.formBuilder.group({
          cifForm: ['',[Validators.required, NifValidator(), Validators.pattern(environment.app.cifPattern)]] //TODO REVISAR CON AEE
    });
    this.searchEmailForm = this.formBuilder.group({
      emailAsistenteForm: new FormControl('', [Validators.required, Validators.email])
    });
  }

  sendMailValidation(idContacto)
    {
        sessionStorage.removeItem("idContacto");
        this.restApiService.enviarCodigoVerificacion(idContacto).subscribe( data => {
          if (data.Success && idContacto !== null && idContacto !== undefined && idContacto !== 'undefined'){
            sessionStorage.setItem("idContacto", idContacto);
            this.router.navigate(['confirm-form-cont']);
          } else{
            this.restApiService.showError("No se ha podido enviar el código. Vuelva a intentarlo más tarde");
          }
        });
  }

  onSubmit() {
    let cif = this.initForm.get('cifForm').value;
    //if (environment.debug) console.log("Submit - Cif="+cif);
    if (cif !== 'undefined' && cif !== '') {
      sessionStorage.setItem("nif", cif);
      this.restApiService.getContactDataByNif(cif).subscribe( dataContact => {
        if (dataContact.length > 0){
            this.sendMailValidation(dataContact[0].IdContacto); //Siempre se manda el primer contacto porque solo debería existir 1.
        } else{
          sessionStorage.setItem('nifExist', "no");
          this.requestEmail=true;
        }
      });
    } else {
      this.restApiService.showError("Debe indicar el NIF del contacto.");
    }
  }

  onSubmitEmail(){
    let email = this.searchEmailForm.get('emailAsistenteForm').value;

    if (email!=='undefined' && email!=='')
    {
      sessionStorage.setItem("email",email);
      this.restApiService.getContactDataByEmail(email).subscribe( dataContact => {
        if (dataContact.length == 0){
          sessionStorage.setItem('emailExist', "no");
          this.router.navigate(['contact-form-cont']);
        } else {
          this.sendMailValidation(dataContact[0].IdContacto); //Siempre se manda el primer contacto porque solo debería existir 1.
        }
      });
      
    } else {
      this.restApiService.showError("Debe indicar un email para el/los asistente/s.");
    }
  }
  cancel(){
    this.requestEmail = true;
  }

}
