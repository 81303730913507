import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RestApiService } from '../services/rest-api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { ChangeDetectorRef } from '@angular/core';
import { environment } from '../../environments/environment';
import { Empresa } from '../Model/Empresa';
import { Pais } from '../Model/Pais';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-add-sede-form',
  templateUrl: './modal-add-sede-form.component.html',
  styleUrls: ['./modal-add-sede-form.component.scss']
})

export class ModalAddSedeFormComponent implements OnInit {
  environment = environment;
  title = environment.app.title;

  enterpriseForm: FormGroup;
  empresa: Empresa;
  cif: string;
  submit = false;
  paisSelected: string;
  provinciaSelected: string;
  poblacionSelected: string;
  codigoPostalSelected: number;

//  @ViewChild(PaisInputComponent) paisComponent: PaisInputComponent;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private restApiService: RestApiService,
              private spinner: NgxSpinnerService,
              private cdRef:ChangeDetectorRef,
              private modal: MatDialogRef<ModalAddSedeFormComponent>) { }

  //Evita que se produzca el ERROR Error: ExpressionChangedAfterItHasBeenCheckedError
  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
        this.initializeForm();
  }

  initializeForm() {
    this.enterpriseForm = this.formBuilder.group({
      paisForm: new FormControl('',Validators.required),
      codigoPostalForm: new FormControl('',Validators.required),
      provinciaForm: new FormControl('',Validators.required),
      poblacionForm: new FormControl('',Validators.required),
      direccionForm: new FormControl('',Validators.required),
      emailEmpresaForm: new FormControl('',[Validators.required, Validators.email]),
    });
  }

  onSubmit() {
    if (environment.debug) console.log("Submit Sede");
    this.submit = true;
    if (this.formValidator()) {
       let empresa = {} as Empresa;
       empresa.id = sessionStorage.getItem("idCliente");
       empresa.pais = this.enterpriseForm.controls['paisForm'].value,
       empresa.codigoPostal = this.enterpriseForm.controls['codigoPostalForm'].value,
       empresa.provincia = this.enterpriseForm.controls['provinciaForm'].value,
       empresa.poblacion = this.enterpriseForm.controls['poblacionForm'].value,
       empresa.direccion = this.enterpriseForm.controls['direccionForm'].value,
       empresa.email = this.enterpriseForm.controls['emailEmpresaForm'].value;
       

      this.restApiService.setSedeData(empresa).subscribe(dataEnterprise => {
        if (dataEnterprise) {
            this.restApiService.showSuccess("Sede guardada correctamente.");
        }else{
            this.restApiService.showError("Se ha producido un error al guardar la sede.");
        }
        this.closeModal();
      });
    }else{
      this.restApiService.showError("Debe completar los campos obligatorios.");
    }
  }

  formValidator() {
    if (this.enterpriseForm.get('paisForm').hasError('required') ||
        this.enterpriseForm.get('codigoPostalForm').hasError('required') ||
        this.enterpriseForm.get('provinciaForm').hasError('required')||
        this.enterpriseForm.get('poblacionForm').hasError('required')||
        this.enterpriseForm.get('direccionForm').hasError('required')) //|| this.enterpriseForm.get('emailEmpresaForm').hasError('required')
          return false;
    else
          return true;
  }

  cancel() {
    if (environment.debug) console.log("Cancel");
    this.closeModal();
  }

  onNoClick(): void {
    this.cancel();
  }

  closeModal() {
    this.modal.close();
  }

}
