import { Component, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from "@angular/material/select"
import { MatOption } from "@angular/material/core"
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { Poblacion } from '../../Model/Poblacion';
import { RestApiService } from '../../services/rest-api.service';

@Component({
  selector: 'app-poblacion-input',
  templateUrl: './poblacion-input.component.html',
  styleUrls: ['./poblacion-input.component.css']
})

export class PoblacionInputComponent {
  poblaciones: Poblacion[] = [];
  poblacionesStr: string[] = [];
  filteredPoblaciones: Observable<string[]>;
  @Input() enterpriseForm: FormGroup;
  @Input() idPaisSelected: string;
  @Input() idProvinciaSelected: string;
  @Output() poblacionSelected = new EventEmitter<any>();

  constructor(private restApiService: RestApiService) {
  }

  ngOnInit(): void {
    if (this.idPaisSelected!==undefined &&
        this.idPaisSelected!==null &&
        this.idPaisSelected!=='' &&
        this.idProvinciaSelected!==undefined &&
        this.idProvinciaSelected!==null &&
        this.idProvinciaSelected!=='')
    {
      this.getPopulations();
    }
  }

  ngOnChanges(){
    if (this.idPaisSelected!==undefined &&
        this.idPaisSelected!==null &&
        this.idPaisSelected!=='' &&
        this.idProvinciaSelected!==undefined &&
        this.idProvinciaSelected!==null &&
        this.idProvinciaSelected!=='')
    {
      this.getPopulations();
    }
  }

  private getPopulations()
  {
    this.poblaciones = [];
    this.poblacionesStr = [];

    this.restApiService.getPopulationsData(this.idPaisSelected, this.idProvinciaSelected).subscribe( dataPopulations => {
        dataPopulations.forEach(population => {
          let poblacion = {} as Poblacion;
          poblacion.idPais = population.IdPais;
          poblacion.idProvincia = population.IdProvincia;
          poblacion.id = population.IdPoblacion;
          poblacion.nombre = population.Poblacion;
          //if (environment.debug) console.log(poblacion.nombre);
          this.poblaciones.push(poblacion);
          this.poblacionesStr.push(poblacion.nombre);
        });
        this.filteredPoblaciones = this.enterpriseForm.controls['poblacionForm'].valueChanges
          .pipe(
            startWith(''),
            map(value => this._filterPoblacion(value))
          );

        this._filterPoblacion(this.enterpriseForm.controls['poblacionForm'].value);
    });
  }

  private _filterPoblacion(value: string): string[] {
    const filterValue = value.toLowerCase();

    let returnValue = this.poblacionesStr.filter(option => option?.toLowerCase().includes(filterValue));
    /*if (returnValue.length==1)
    {*/
      let poblacion = this.poblaciones.find(poblacion => poblacion.nombre == returnValue[0]);
      //this.enterpriseForm.controls['codigoPostalForm'].enable();
      if (poblacion!=undefined) this.poblacionSelected.emit(poblacion.id);
    //}
    return returnValue;
  }

}
