import { Component, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from "@angular/material/select"
import { MatOption } from "@angular/material/core"
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { Pais } from '../../Model/Pais';
import { Provincia } from '../../Model/Provincia';
import { RestApiService } from '../../services/rest-api.service';

@Component({
  selector: 'app-provincia-input',
  templateUrl: './provincia-input.component.html',
  styleUrls: ['./provincia-input.component.css']

})

export class ProvinciaInputComponent {
  provincias: Provincia[] = [];
  provinciasStr: string[] = [];
  filteredProvincias: Observable<string[]>;
  @Input() enterpriseForm: FormGroup;
  @Input() idPaisSelected: string;
  @Output() provinciaSelected = new EventEmitter<any>();

  constructor(private restApiService: RestApiService) {
  }

  ngOnInit(): void {
    if (this.idPaisSelected!==undefined &&
        this.idPaisSelected!==null &&
        this.idPaisSelected!=='')
    {
      this.getProvinces();
    }
  }

  ngOnChanges(){
    if (this.idPaisSelected!==undefined &&
        this.idPaisSelected!==null &&
        this.idPaisSelected!=='')
    {
      this.getProvinces();
    }
  }

  private getProvinces()
  {
    this.provincias = [];
    this.provinciasStr = [];

    this.restApiService.getProvincesData(this.idPaisSelected).subscribe( dataProvinces => {
        dataProvinces.forEach(province => {
          let provincia = {} as Provincia;
          provincia.idPais = province.IdPais;
          provincia.id = province.IdProvincia;
          provincia.nombre = province.Provincia;
          //if (environment.debug) console.log(provincia.nombre);
          this.provincias.push(provincia);
          this.provinciasStr.push(provincia.nombre);
        });
        this.filteredProvincias = this.enterpriseForm.controls['provinciaForm'].valueChanges
          .pipe(
            startWith(''),
            map(value => this._filterProvincia(value))
          );

        this._filterProvincia(this.enterpriseForm.controls['provinciaForm'].value);
    });
  }

  private _filterProvincia(value: string): string[] {
    if (value!==null)
    {
      const filterValue = value.toLowerCase();
      let returnValue = this.provinciasStr.filter(option => option?.toLowerCase().includes(filterValue));
      if (returnValue.length==1)
      {
        let provincia = this.provincias.find(provincia => provincia.nombre == returnValue[0]);
        //this.enterpriseForm.controls['poblacionForm'].enable();
        if (provincia!=undefined) this.provinciaSelected.emit(provincia.id);
      }
      return returnValue;
    }else{
      return [];
    }
  }

}
