<div class="row" [formGroup]="enterpriseForm">
  <div class="col" *ngIf="sedes!==null && sedes!==undefined && sedes.length>0">
    <mat-form-field appearance="outline">
      <mat-label>Sede&nbsp;<span class="star" *ngIf="this.required">&nbsp;*</span></mat-label>
      <mat-select formControlName="sedeForm" class="multiple-selector" [multiple]="multiple"  [disabled]="this.disabled"
                  [disableOptionCentering]="true" #selectSedes style="width:350px;">
        <mat-option value="0">
          <ngx-mat-select-search placeholderLabel="Buscar"
                                 [showToggleAllCheckbox]="false"
                                 [formControl]="sedeFilter"
                                 noEntriesFoundLabel="No se han encontrado datos">
          </ngx-mat-select-search>
        </mat-option>

        <ng-container *ngFor="let sede of sedes  | searchFilter:sedeFilter.value">
          <mat-option [value]="sede" title="{{sede.direccion}}">
            {{sede.direccion}}
          </mat-option>
        </ng-container>
        <!--/div-->
      </mat-select>
    </mat-form-field>
    &nbsp;&nbsp;
    <i *ngIf="this.disabled==false" class="material-icons md-24" matTooltip="Añadir Nueva Sede" matTooltipClass="example-tooltip-red1" (click)="openModalAddSede()">add_circle_outline</i>
  </div>
</div>
