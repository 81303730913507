import { Component, ViewChild, Input, Output, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from "@angular/material/select"
import { MatOption } from "@angular/material/core"
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Empresa } from '../../Model/Empresa';
import { environment } from '../../../environments/environment';
import { RestApiService } from '../../services/rest-api.service';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalAddSedeFormComponent } from '../../modal-add-sede-form/modal-add-sede-form.component';

import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sede-select',
  templateUrl: './sede-select.component.html',
  styleUrls: ['./sede-select.component.css']
})

export class SedeSelectComponent {
  idCliente: any;
  environment = environment;
  @Output() sedes: Empresa[] = [];
  @Input() enterpriseForm: FormGroup;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() idClienteSede: string;
  @ViewChild('selectSedes') selectSedes: MatSelect;

  @Input() multiple: Boolean = false;

  public sedeFilter: FormControl = new FormControl();
  /** list of sedes filtered by search keyword */
  public filteredSedesMulti: ReplaySubject<Empresa[]> = new ReplaySubject<Empresa[]>(1);
  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  constructor(private restApiService: RestApiService,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
      this.getSedes();
  }

  getSedes() {
    if(this.idClienteSede)
        this.idCliente = this.idClienteSede;
    else
        this.idCliente = sessionStorage.getItem('idCliente');
    this.sedes = [];
    if (this.idCliente !== null && this.idCliente !== undefined && this.idCliente !== '') {
      this.restApiService.getSedesData(this.idCliente).subscribe( dataSedes => {
          dataSedes.forEach(sedeData => {
            let sede = {} as Empresa;
            if (environment.debug) console.log("Información de las Sedes obtenidas");
            sede.id = sedeData.IdCliente;
            sede.cif = sedeData?.Nif;
            sede.razonSocial = sedeData?.RazonSocial;
            sede.nombreComercial = sedeData?.Cliente;
            sede.pais = sedeData?.Pais;
            sede.codigoPostal = sedeData?.CodPostal;
            sede.provincia = sedeData?.Provincia;
            sede.poblacion = sedeData?.Ciudad;
            sede.direccion = sedeData?.Direccion;
            sede.email = sedeData?.E_Mail;
            sede.idDocSede = sedeData?.IdDoc;

            this.sedes.push(sede);
          });

          if (this.sedes != null && this.sedes.length > 0) {
            this.filteredSedesMulti.next(this.sedes.slice());

            // listen for search field value changes
            this.sedeFilter.valueChanges
              .pipe(takeUntil(this._onDestroy))
              .subscribe(() => {
                this.filterSedesMulti();
            });
          }
      });
    }
  }

  protected filterSedesMulti() {
    if (!this.sedes) {
      return;
    }
    // get the search keyword
    let search = this.sedeFilter.value;
    if (!search) {
      this.filteredSedesMulti.next(this.sedes.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the sedes
    this.filteredSedesMulti.next(

      this.sedes.filter(sede => {
          if (sede?.direccion?.toLowerCase().indexOf(search) > -1) {
            return true;
          }
          return false;
      })

      //this.sedes.filter(sede => sede?.direccion?.toLowerCase().indexOf(search) > -1)
    );

  }

  /*toggleSelectAll(selectAllValue: boolean) {
    if (selectAllValue)
      this.selectSedes.options.forEach( (item : MatOption) => item.select());
    else
      this.selectSedes.options.forEach( (item : MatOption) => item.deselect());
  }*/

  openModalAddSede(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
        idCliente: this.idCliente
    };
    //dialogConfig.width = "70%";
    //dialogConfig.height ="90%";
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(ModalAddSedeFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(modalClose => {
        this.getSedes();
    });
  }
}
