<ngx-spinner bdColor="rgba(0, 0, 0, 0.1)" size="large" color="var(--blue)" type="square-jelly-box"
             [fullScreen]="true">

  <br/><br/><p style="color: var(--blue); font-weight: bolder; font-size: large"> Cargando... </p>
</ngx-spinner>

<!-- FORMULARIO PREVIO A REALIZAR EL PAGO-->
  <div id="payment-form" class="container">

  <p style="text-align:center;padding-top:20px;">
    <img class="logo" src="/assets/img/aee.png" alt="AEE" align="middle">
  </p>

  <h1>
    {{title}}
  </h1>

  <hr/>
  <app-progress-bar step="4"></app-progress-bar>
  <hr/>

  <!-- PREVIO AL PAGO -->
  <h3 class="eb-page-heading">Pasarela de pago AEE</h3>

    <div class="row">
      <div class="alert alert-warning col" style="text-align:center;">
        <i class="material-icons">warning</i> <h3><b>¡¡¡IMPORTANTE!!!</b></h3>
        <b>Dispone de 7 minutos</b> para realizar el pago antes de que su pre-inscripción sea cancelada.<br/>
        Por favor no cierre el navegador durante el proceso. <br/><br/>
        <countdown #cd [config]="{leftTime: 420}"></countdown>
      </div>
    </div>

  <div *ngIf="!this.submit && this.importe!==null && !this.error && this.bPagoActivo" >
      <div class="eb-message">
        <h4 class="sppb-addon-title">Seleccione el modo de pago</h4>
      </div>
      <div class="row">
        <div class="col" [formGroup]="paymentForm">
          <mat-form-field appearance="outline">
            <mat-label>Forma de Pago</mat-label>
            <mat-select formControlName="tipoPagoForm" class="multiple-selector" style="width:350px;">
                <mat-option value="TPV" title="Tarjeta de Crédito / TPV" selected>
                  Tarjeta de Crédito / TPV
                </mat-option>
                <mat-option value="Transferencia" title="Transferencia Bancaria" *ngIf="this.transferencia">
                  Transferencia Bancaria
                </mat-option>
              <!--/div-->
            </mat-select>
          </mat-form-field>
          &nbsp;&nbsp;
          <input type="button" value="Continuar" id="btn-submit-email" class="btn btn-success" (click)="selectTipoPago()">
        </div>
      </div>
  </div>

  <div *ngIf="this.submit && this.importe!==null && !this.error && this.bPagoActivo">
    <div class="eb-message">
      <h4 class="sppb-addon-title">Se va a proceder a realizar el siguiente pago:</h4>
    </div>

    <hr/>
    <div class="row">
      <div class="col">
        <span class="title">Forma de pago:</span>&nbsp;
        <span  *ngIf="this.tipoPago === 'TPV'">Tarjeta de Crédito / TPV</span>
        <span  *ngIf="this.tipoPago === 'Transferencia'">Transferencia bancaria</span>
        &nbsp;&nbsp;
        <!--input type="button" value="Modificar" id="btn-submit-change" class="btn btn-info" (click)="changeTipoPago()"-->
      </div>
    </div>

    <br/>
    <div class="row">
      <div class="col">
        <span class="title">Importe (total a pagar):</span>&nbsp;
        {{this.importe}} €
      </div>
    </div>

    <hr/>

    <div  *ngIf="this.tipoPago === 'TPV'">
        <div class="row">
          <div class="alert alert-info col" style="text-align:center;">
            <i class="material-icons">info</i>
            Si está de acuerdo, pulse en el botón "Realizar Pago" para ser redirigido a la paserela de pago del banco.
          </div>
        </div>

        <div class="row">
          <div class="col" style="text-align:center;">
            <form ngNoForm #tpvPaymentForm name="frm"
                  action="{{environment.redsys.url}}"
                  method="POST" class="form form-horizontal">
                <input type="hidden" name="Ds_SignatureVersion" value="{{environment.redsys.api.version}}">
                <input type="hidden" name="Ds_MerchantParameters" value="{{this.merchantParameters}}">
                <input type="hidden" name="Ds_Signature" value="{{this.merchantSignature}}">
                <!--input type="hidden" name="idProyecto" value="{{this.idProyecto}}">
                <input type="hidden" name="idCliente" value="{{this.idCliente}}">
                <input type="hidden" name="contactosSelected" value="{{this.contactosSelected}}">
                <input type="hidden" name="tipoPago" value="2"-->
                <input type="submit" class="btn btn-success btn-lg" id="btn-submit-tpv" value="Realizar Pago TPV" *ngIf="this.bPagoActivo">
            </form>
          </div>
        </div>
    </div>

    <div *ngIf="this.tipoPago === 'Transferencia'">
      <div class="row">
        <div class="alert alert-info col" style="text-align:center;">

          <i class="material-icons">info</i>
          En breve recibirá la factura correspondiente a su inscripción en la cual se le indicará la cuenta bancaria para realizar el pago por Transferencia Bancaria.<br/>
          No olvide indicar en el concepto de la transferencia el número de factura que se abona.
          <!--Puede enviar su transferencia a la siguiente cuenta bancaria:<br/><br/>
              Titular: <strong>Asociación Empresarial Eólica</strong><br/>
              Bank: <strong>La Caixabank, S.A.</strong><br/>
              SWIFT Code: <strong>CAIXESBB</strong><br/>
              IBAN: <strong>ES66 2100 2333 6302 0003 9249</strong-->
        </div>
      </div>
      <div class="row">
        <div class="col" style="text-align:center;">
          <input type="submit" class="btn btn-success btn-lg" id="btn-submit-transfer" value="Continuar" (click)="onSubmit()">
        </div>
      </div>
    </div>

  </div>

  <div *ngIf="this.importe==null || this.error || !this.bPagoActivo">
    <div class="row">
      <div class="alert alert-danger col" style="text-align:center;">
        <i class="material-icons">error</i>
        Se ha producido un error en el proceso de inscripción (o ha superado el límite de tiempo) y no se puede proceder al pago. Por favor vuelva a intentar realizar la inscripción.
      </div>
    </div>
    <div class="row" style="text-align:center;">
      <div id="botones" class="col">
        <input type="button" class="btn btn-default btn-danger" id="btn-submit-cancel" value="Salir" (click)="cancel()">
      </div>
    </div>
  </div>
</div>
