import { Routes, RouterModule,ActivatedRoute} from '@angular/router';
import { NgModule, Component } from '@angular/core';
import { InitFormComponent } from './init-form/init-form.component';
import { EnterpriseFormComponent } from './enterprise-form/enterprise-form.component';
import { SearchContactFormComponent } from './search-contact-form/search-contact-form.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { EventInfoFormComponent } from './event-info-form/event-info-form.component';
import { PaymentFormComponent } from './payment-form/payment-form.component';
import { EndFormComponent } from './end-form/end-form.component';
import { InitFormContComponent } from './init-form-cont/init-form-cont.component';
import { ConfirmFormContComponent } from './confirm-form-cont/confirm-form-cont.component';
import { ContactFormContComponent } from './contact-form-cont/contact-form-cont.component';
import { EnterpriseFormContComponent } from './enterprise-form-cont/enterprise-form-cont.component';
import { WorkgroupFormCont } from './workgroup-form-cont/workgroup-form-cont.component';


const AEERoutes: Routes = [
      { path: '', redirectTo: '/init-form', pathMatch: 'full' },
      { path: 'init-form/:id', component: InitFormComponent,  pathMatch: 'full'},
      { path: 'init-form', component: InitFormComponent ,  pathMatch: 'full'},
      { path: 'enterprise-form', component: EnterpriseFormComponent},
      { path: 'search-contact-form', component: SearchContactFormComponent},
      { path: 'contact-form', component: ContactFormComponent},
      { path: 'event-info-form', component: EventInfoFormComponent},
      { path: 'payment-form', component: PaymentFormComponent},
      { path: 'end-form', component: EndFormComponent},
      { path: 'init-form-cont', component: InitFormContComponent},
      { path: 'confirm-form-cont', component: ConfirmFormContComponent},
      { path: 'contact-form-cont', component: ContactFormContComponent},
      { path: 'enterprise-form-cont', component: EnterpriseFormContComponent},
      { path: 'workgroup-form-cont', component: WorkgroupFormCont},
]

@NgModule({
  imports: [RouterModule.forRoot(AEERoutes)],
  exports: [RouterModule]
})

export class AEERoutingModule { }
