<ngx-spinner bdColor="rgba(0, 0, 0, 0.1)" size="large" color="var(--blue)" type="square-jelly-box"
             [fullScreen]="true">

  <br/><br/><p style="color: var(--blue); font-weight: bolder; font-size: large"> Cargando... </p>
</ngx-spinner>

<!-- FINAL DEL PROCESO DE INSCRIPCIÓN-->
<div id="end-form" class="container">
  <p style="text-align:center;padding-top:20px;">
    <img class="logo" src="/assets/img/aee.png" alt="AEE" align="middle">
  </p>

  <h1>
    {{title}}
  </h1>

  <hr/>
  <app-progress-bar step="5"></app-progress-bar>
  <hr/>

  <h2 style="color:#2DC163; text-align:center;" *ngIf="!this.error">Su inscripción ha sido procesada correctamente</h2>
  <h2 style="color:red; text-align:center;" *ngIf="this.error">Se ha producido un error al finalizar la inscripcion. Pongase en contacto con AEE.</h2>

  <div *ngIf="this.tipoPago=='1'">
    <h3 style="text-align:center;">Siga las instrucciones del correo que se le ha enviado para realizar el pago por Transferencia Bancaria</h3><br/>
    <h3 style="text-align:center;">En breve recibirá la factura correspondiente a su inscripción en la cual se le indicará la cuenta bancaria para realizar el pago por Transferencia Bancaria.<br/>
    No olvide indicar en el concepto de la transferencia el número de factura que se abona.</h3>
  </div>
  <br/>
  <div class="row">
    <div class="col" style="text-align:center;" *ngIf="!this.donacion">
      <form [formGroup]="endForm" (ngSubmit)="onSubmit()">
        <input type="submit" class="btn btn-default btn-primary" id="btn-submit-endInscription" value="Finalizar" >
      </form>
    </div>
    <!--div class="col" style="text-align:center;" *ngIf="this.donacion">
      <input type="button" class="btn btn-default btn-primary" id="btn-submit-Donation" value="Realizar Donación" (click)="this.redirect()">
    </div-->
  </div>
</div>
