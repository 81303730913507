import { Component, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { MatSelect } from "@angular/material/select"
import { MatOption } from "@angular/material/core"

@Component({
  selector: 'app-progress-bar-cont',
  templateUrl: './progress-bar-cont.component.html',
  styleUrls: ['./progress-bar-cont.component.css']

})

export class ProgressBarContComponent {

  @Input() step: any[];

  constructor(private router: Router,
              private route: ActivatedRoute) {}

  navigate(destino){
     this.router.navigate([destino]);
  }

}
