<ngx-spinner bdColor="rgba(0, 0, 0, 0.1)" size="large" color="var(--blue)" type="square-jelly-box"
             [fullScreen]="true">

  <br/><br/><p style="color: var(--blue); font-weight: bolder; font-size: large"> Cargando... </p>
</ngx-spinner>

<!-- FORMULARIO INICIAL -->
<div id="init-form" class="container">
  <p style="text-align:center;padding-top:20px;">
    <img class="logo" src="/assets/img/aee.png" alt="AEE" align="middle">
  </p>

  <h1>{{titleRegistro}}</h1>

  <hr/>
  <app-progress-bar-cont step="1"></app-progress-bar-cont>
  <hr/>

  <div *ngIf="!this.requestEmail">
    <h3 class="">Introduzca su NIF</h3>

    <div class="alert alert-info" *ngIf="!this.submit">
        <i class="material-icons">info</i>
        <strong>&nbsp;IMPORTANTE:&nbsp;</strong>
        El NIF del contacto debe seguir el formato: Número + Letra SIN guiones ni espacio.<br/>

    </div>
    <hr/>

    <!--Formulario-->
    <form [formGroup]="initForm" (ngSubmit)="onSubmit()">
        <div class="row" *ngIf="!this.submit">
            <div class="col" style="text-align:center;">
                <mat-form-field appearance="outline">
                    <mat-label>NIF del contacto <span class="star">&nbsp;*</span></mat-label>
                    <input matInput type="text" formControlName="cifForm" id="cifForm">
                </mat-form-field>

                <!--Boton -->
                &nbsp;<input type="submit" value="Buscar" id="btn-submit-init" class="btn btn-default btn-primary" [disabled]="!initForm.valid">
                <!--Texto Error -->
              <mat-error *ngIf="initForm.get('cifForm').hasError('required') && initForm.get('cifForm').touched">
                    <strong>&nbsp;<i class="material-icons">warning</i>&nbsp;ERROR:&nbsp;</strong><span>NIF Obligatorio</span>
                </mat-error>
              <mat-error *ngIf="initForm.get('cifForm').hasError('cifInvalid') && initForm.get('cifForm').touched">
                  <strong>&nbsp;<i class="material-icons">warning</i>&nbsp;ERROR:&nbsp;</strong><span>NIF no valido</span>
                </mat-error>
            </div>
      </div>
    </form>
  </div>
  <div *ngIf="this.requestEmail">
    <div class="alert alert-info">
      <i class="material-icons">info</i>
      Introduzca email y pulse<strong>&nbsp;Buscar</strong>
    </div>
    <form [formGroup]="searchEmailForm" (ngSubmit)="onSubmitEmail()">
      <div class="row" >
        <div class="col" style="text-align:center;">
          <mat-form-field appearance="outline">
            <mat-label>Email <span class="star">&nbsp;*</span></mat-label>
            <input matInput type="text" formControlName="emailAsistenteForm" id="emailAsistenteForm" size="50">
          </mat-form-field>
          &nbsp;
          <input type="submit" value="Buscar" id="btn-submit-email" class="btn btn-default btn-primary" [disabled]="!searchEmailForm.valid">&nbsp;
          <input type="button" class="btn btn-default btn-secondary" id="btn-submit-cancel" value="Cancelar" (click)="cancel()">
  
          <!--Texto Error -->
          <mat-error *ngIf="searchEmailForm.get('emailAsistenteForm').hasError('required') && searchEmailForm.get('emailAsistenteForm').touched">
            <strong>&nbsp;<i class="material-icons">warning</i>&nbsp;ERROR:&nbsp;</strong><span>Email Obligatorio</span>
          </mat-error>
          <mat-error *ngIf="searchEmailForm.get('emailAsistenteForm').hasError('email')">
            <strong>&nbsp;<i class="material-icons">warning</i>&nbsp;ERROR:&nbsp;</strong><span>Email No Valido</span>
          </mat-error>
        </div>
      </div>
  
      <!-- En caso de error -->
      <div *ngIf="this.error">
        <div class="row">
          <div class="alert alert-danger col" style="text-align:center;">
            <i class="material-icons">error</i>
            Se ha producido un error en el proceso de inscripción y no se puede continuar. Por favor vuelva a intentar realizar la inscripción.
          </div>
        </div>
        <div class="row" style="text-align:center;">
          <div id="botones" class="col">
            <input type="button" class="btn btn-default btn-danger" id="btn-submit-cancel2" value="Salir" (click)="cancel()">
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
