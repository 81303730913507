import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RestApiService } from '../services/rest-api.service';
import { RedsysApiService } from '../services/redsys-api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { ChangeDetectorRef } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-end-form',
  templateUrl: './end-form.component.html',
  styleUrls: ['./end-form.component.scss']
})
export class EndFormComponent implements OnInit {
  environment = environment;
  title = environment.app.title;
  endForm: FormGroup;
  submit: boolean = false;
  error: boolean = false;
  tipoPago: any;
  idProyecto: any;
  contactosSelected: any;
  idCliente: any;
  idTicket: any;
  idCompra: any;
  idTransaccion: any;
  merchantParameters: any;
  emailEnvio: any;
  donacion: any;

  action: any;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private restApiService: RestApiService,
              private redsysApiService: RedsysApiService,
              private spinner: NgxSpinnerService,
              private cdRef:ChangeDetectorRef) { }

  //Evita que se produzca el ERROR Error: ExpressionChangedAfterItHasBeenCheckedError
  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
        this.spinner.show();
        this.initializeForm();
        this.finalizarInscripcion();
        this.spinner.hide();
  }

  initializeForm()
  {
    this.endForm = this.formBuilder.group({

    });
  }

  finalizarInscripcion()
  {
    if (environment.debug) console.log("En FinalizarInscripcion");

    this.idProyecto = sessionStorage.getItem("idProyecto");
    this.contactosSelected = JSON.parse(sessionStorage.getItem("contactosSelected"));
    this.tipoPago = sessionStorage.getItem("tipoPago");
    this.idCliente = sessionStorage.getItem("idCliente");
    this.donacion = sessionStorage.getItem("donacion");

    this.idTicket = '';
    this.idCompra = '';
    this.idTransaccion = '';

    this.merchantParameters = '';

    this.route.queryParams.subscribe(params => {
        if (params.Ds_MerchantParameters!==undefined) this.merchantParameters = params.Ds_MerchantParameters;
        if (params.idProyecto!==undefined) this.idProyecto = params.idProyecto;
        if (params.idCliente!==undefined) this.idCliente = params.idCliente;
        if (params.contactosSelected!==undefined) this.contactosSelected = params.contactosSelected;
        if (params.tipoPago!==undefined) this.tipoPago = params.tipoPago;
        if (params.action!==undefined) this.action = params.action;
        if (params.emailEnvio!==undefined) this.emailEnvio = params.emailEnvio;
        if (params.donacion!==undefined) this.donacion = params.donacion;
     });

    //DONACIONES
    if (this.donacion=="false" || this.donacion==undefined) this.donacion = false
    else this.donacion = true;

    this.submit = false;

    if (this.contactosSelected!==null && this.contactosSelected.length>0)
    {
      if (this.idProyecto!==null)
      {
          if (this.tipoPago == "2")
          {
            if (this.action == 'paymentOK')
            {
              //TPV
              if (environment.debug) console.log("Pago realizado por TPV");
              if (environment.debug) console.log("merchantParameters->"+this.merchantParameters);

              if (this.merchantParameters == null || this.merchantParameters=='')
              {
                  this.error = true;
                  if (environment.debug) console.log("Error. MerchantParameters");
                  this.restApiService.showError("Se ha producido un error en el proceso de pago. Ponagase en contacto con AEE.");
              }else{
                this.redsysApiService.decodeRedsysApiData(this.merchantParameters).subscribe(data => {
                  if (data==undefined)
                  {
                      if (environment.debug) console.log("Error. Data Decode");
                      this.error = true;
                      this.restApiService.showError("Se ha producido un error en el proceso de pago. Ponagase en contacto con AEE.");
                  }else{
                    this.idTicket = data.idTicket;
                    this.idCompra = data.idCompra;
                    this.idTransaccion = data.idTransaccion;
                    this.finalizarInscripcionService();
                    this.error = false;
                  }
                });
              }
            }else{
              if (environment.debug) console.log("Error. Pasarela de Pago devuelve error.");
              this.error = true;
              this.restApiService.showError("Se ha producido un error en el proceso de pago. Ponagase en contacto con AEE.");
            }
          }else{
            //Transferencia
            if (environment.debug) console.log("Pago realizado por Transferencia");
            this.emailEnvio = sessionStorage.getItem("email");
            this.finalizarInscripcionService();
          }

      }else{
          this.error = true;
          if (environment.debug) console.log("Error. IdProyecto");
          this.restApiService.showError("Se ha producido un error al finalizar la inscripcion. Pongase en contacto con AEE.");
      }
    }else{
        this.error = true;
        if (environment.debug) console.log("Error. Contactos");
        this.restApiService.showError("Se ha producido un error en el proceso de inscripcion.");
    }
  }

  finalizarInscripcionService()
  {
      this.restApiService.finalizarInscripcion(this.contactosSelected,
                                               this.idProyecto,
                                               this.tipoPago,
                                               this.idCliente,
                                               this.idTicket,
                                               this.idCompra,
                                               this.idTransaccion,
                                               this.emailEnvio)
        .subscribe(data =>
        {
              if (data!==null)
              {
                if (data.Success || data.Data?.retorno[0].Estado==1)
                {
                  if (environment.debug) console.log("Inscripción finalizada con éxito.");
                  this.restApiService.showSuccess("Inscripción finalizada con éxito.");
                }else{
                  if (environment.debug) console.log("Error al finalizar la inscripción: "+data.Data.retorno[0].Mensaje);
                  this.restApiService.showError("Error al finalizar la inscripción. Pongase en contacto con AEE. Error: "+data.Data.retorno[0].Mensaje);
                  this.error = true;
                }
              }else{
                this.error = true;
                if (environment.debug) console.log("Error al finalizar la inscripción");
                this.restApiService.showError("Error al finalizar la inscripción. Pongase en contacto con AEE.");
              }
        });
  }

  onSubmit()
  {
    if (environment.debug) console.log("Submit End Form");
    //this.router.navigate(['init-form']);

    this.router.navigate(['init-form','?id='+this.idProyecto]);
  }

  redirect()
  {
    window.location.href = this.environment.campaignURL;
  }
}
