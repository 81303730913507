import { Component, ViewChild, Input, Output, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from "@angular/material/select"
import { MatOption } from "@angular/material/core"
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Departamento } from '../../Model/Departamento';
import { environment } from '../../../environments/environment';
import { RestApiService } from '../../services/rest-api.service';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-departamento-select',
  templateUrl: './departamento-select.component.html',
  styleUrls: ['./departamento-select.component.css']
})

export class DepartamentoSelectComponent {
  environment = environment;
  @Output() departamentos: Departamento[] = [];
  @Input() departamentoForm: FormGroup;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @ViewChild('selectDepartamentos') selectDepartamentos: MatSelect;

  @Input() multiple: Boolean = false;

  public departamentoFilter: FormControl = new FormControl();
  /** list of departamentos filtered by search keyword */
  public filteredDepartamentosMulti: ReplaySubject<Departamento[]> = new ReplaySubject<Departamento[]>(1);
  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  constructor(private restApiService: RestApiService,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
      this.getDepartamentos();
  }

  getDepartamentos() {
    this.departamentos = [];
    this.restApiService.getDepartamentosData().subscribe( dataDepartamentos => {
      dataDepartamentos.forEach(departamentoData => {
        let departamento = {} as Departamento;
        if (environment.debug) console.log("Información de las Departamentos obtenidas");
        departamento.id = departamentoData.IdDepartamento;
        departamento.nombre = departamentoData?.Descrip;

        this.departamentos.push(departamento);
      });

      if (this.departamentos != null && this.departamentos.length > 0) {
        this.filteredDepartamentosMulti.next(this.departamentos.slice());

        // listen for search field value changes
        this.departamentoFilter.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterDepartamentosMulti();
        });
      }
    });
  }

  protected filterDepartamentosMulti() {
    if (!this.departamentos) {
      return;
    }
    // get the search keyword
    let search = this.departamentoFilter.value;
    if (!search) {
      this.filteredDepartamentosMulti.next(this.departamentos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the departamentos
    this.filteredDepartamentosMulti.next(

      this.departamentos.filter(departamento => {
          if (departamento?.nombre?.toLowerCase().indexOf(search) > -1) {
            return true;
          }
          return false;
      })

      //this.departamentos.filter(departamento => departamento?.direccion?.toLowerCase().indexOf(search) > -1)
    );

  }

  /*toggleSelectAll(selectAllValue: boolean) {
    if (selectAllValue)
      this.selectDepartamentos.options.forEach( (item : MatOption) => item.select());
    else
      this.selectDepartamentos.options.forEach( (item : MatOption) => item.deselect());
  }*/

}
