import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RestApiService } from '../services/rest-api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { ChangeDetectorRef } from '@angular/core';
import { environment } from '../../environments/environment';

import { map } from 'rxjs/operators';

import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as _moment from 'moment';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import { MY_FORMATS } from '../../config/config';

import { Empresa } from '../Model/Empresa';
import { Evento } from '../Model/Evento';
import { Programa } from '../Model/Programa';
import { Jornada } from '../Model/Jornada';
import { Sesion } from '../Model/Sesion';

@Component({
  selector: 'app-event-info-form',
  templateUrl: './event-info-form.component.html',
  styleUrls: ['./event-info-form.component.scss'],
  providers: [
   {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
   {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class EventInfoFormComponent implements OnInit {
  environment = environment;
  title = environment.app.title;
  eventInfoForm: FormGroup;
  submit: boolean = false;
  idProyecto: any;
  idDocs: any = '';
  evento: Evento;
  empresa: Empresa;
  error: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private restApiService: RestApiService,
              private spinner: NgxSpinnerService,
              private cdRef:ChangeDetectorRef) { }

  //Evita que se produzca el ERROR Error: ExpressionChangedAfterItHasBeenCheckedError
  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }

   ngOnInit(): void {
        this.spinner.show();
        this.initializeForm();
        this.spinner.hide();

        this.idProyecto = sessionStorage.getItem("idProyecto");
        this.restApiService.getEventInfoData(this.idProyecto,null).subscribe( dataEvent => {
            if (dataEvent && dataEvent.length>0){
              if (environment.debug) console.log("Información del evento obtenida");
              this.getEventInfo(dataEvent);
              this.error = false;
              if (environment.debug) console.log(this.evento.descripcion);
            }else{
              if (environment.debug) console.log("Error al obtener la información del evento");
              this.error = true;
              this.restApiService.showError("Error al obtener la información del evento. Vuelva a intentarlo más tarde.");
            }
        });
  }

  getEventInfo(dataEvent)
  {
      this.evento = {} as Evento;
      this.evento.id = dataEvent[0].IdEvento;
      this.evento.idDoc = dataEvent[0].IdDocEvento;
      this.evento.descripcion=dataEvent[0].Evento;
      this.evento.tipo=dataEvent[0].IdTipoEvento;
      this.evento.programas = [] as Programa[];
      this.evento.adicionales = [] as Programa[];
      this.evento.transferencia = dataEvent[0].Transferencia;
      sessionStorage.setItem("transferencia",this.evento.transferencia.toString());

      dataEvent.forEach (element => {
        if (!element.ProgramaAdicional)
        {
          let programa = this.evento.programas.find(programa => programa.id == element.IdPrograma);
          if (programa == null)
          {
            this.addPrograma(element);
          }else{
            this.updatePrograma(element, programa);
          }
        }else{
          let adicional = this.evento.adicionales.find(adicional => adicional.id == element.IdPrograma);
          if (adicional == null)
          {
            this.addAdicional(element);
          }else{
            this.updateAdicional(element, adicional);
          }
        }
      });
  }

  addPrograma(element)
  {
     if (environment.debug) console.log("Programa ID = "+element.IdPrograma);
      let programa = {} as Programa;
      programa.id = element.IdPrograma;
      programa.idDoc = element.IdDocPrograma;
      programa.descripcion = element.Programa;
      programa.jornadas = [];


      this.addJornada(element,programa);

      this.evento.programas.push(programa);
  }

  updatePrograma(element,programa)
  {
      let jornada = programa.jornadas.find(jornada => jornada.id == element.IdJornada);

      if (jornada == null)
      {
        this.addJornada(element,programa);
      }else{
        this.updateJornada(element,jornada);
      }
  }

  addAdicional(element)
  {
    if (environment.debug) console.log("Adicional ID = "+element.IdPrograma);
      let adicional = {} as Programa;
      adicional.id = element.IdPrograma;

      adicional.descripcion = element.Programa;
      adicional.jornadas = [];
      adicional.checked = false;

      this.addJornada(element,adicional);

      if (this.evento.tipo==1) adicional.idDoc = element.IdDocPrograma;
      else if (this.evento.tipo==2) adicional.idDoc = adicional.jornadas[0].idDoc;
      else if (this.evento.tipo==3) adicional.idDoc = adicional.jornadas[0].sesiones[0].idDoc;

      this.evento.adicionales.push(adicional);
  }

  updateAdicional(element,adicional)
  {
      let jornada = adicional.jornadas.find(jornada => jornada.id == element.IdJornada);

      if (jornada == null)
      {
        this.addJornada(element,adicional);
      }else{
        this.updateJornada(element,jornada);
      }
  }

  addJornada(element,programa)
  {
     if (environment.debug) console.log("Jornada ID = "+element.IdJornada);
      let jornada = {} as Jornada;
      jornada.id = element.IdJornada;
      jornada.idDoc = element.IdDocJornada;
      jornada.descripcion = element.Jornada;
      jornada.fecha = element.FechaJornada;
      jornada.sesiones = [];
      this.addSesion(element,jornada);
      programa.jornadas.push(jornada);
  }

  updateJornada(element,jornada)
  {
        let sesion = jornada.sesiones.find(sesion => sesion.id == element.IdSesion);

        if (sesion == null)
        {
          this.addSesion(element,jornada);
        }else{
          this.updateSesion(element,sesion);
        }
  }

  addSesion(element,jornada){
      if (environment.debug) console.log("Sesion ID = "+element.IdSesion);
      let sesion = {} as Sesion;
      sesion.id = element.IdSesion;
      sesion.idDoc = element.IdDocSesion;
      sesion.descripcion = element.Sesion;
      var fechaInicio = new Date(jornada.fecha);
      var fechaFin = new Date(jornada.fecha);
      var horaInicio = element.HoraInicioSesion.split(':');
      fechaInicio.setHours(+horaInicio[0],+horaInicio[1],+horaInicio[2]);

      var horaFin = element.HoraFinSesion.split(':');
      fechaFin.setHours(+horaFin[0],+horaFin[1],+horaFin[2]);

      sesion.horaInicio = fechaInicio;
      sesion.horaFin = fechaFin;
      this.validaCoincidentes(jornada,sesion)
      if (environment.debug) console.log("Grupo Coincidencia = "+sesion.grupoCoincidencia);
      jornada.sesiones.push(sesion);
  }

  validaCoincidentes(jornada,nuevaSesion){
    let grupo = "0";
    jornada.sesiones.forEach(sesionExistente => {
      if (this.horaEnRango(nuevaSesion.horaInicio,sesionExistente.horaInicio,sesionExistente.horaFin) ||
          this.horaEnRango(nuevaSesion.horaFin,sesionExistente.horaInicio,sesionExistente.horaFin) ||
          this.horaEnRango(sesionExistente.horaInicio,nuevaSesion.horaInicio,nuevaSesion.horaFin) ||
          this.horaEnRango(sesionExistente.horaFin,nuevaSesion.horaInicio,nuevaSesion.horaFin))
      {
        //COINCIDEN
        if (grupo=="0")
        {
          grupo = jornada.id+"."+sesionExistente.id;
        }

        nuevaSesion.grupoCoincidencia = grupo;
        sesionExistente.grupoCoincidencia = grupo;
      }else{
        nuevaSesion.grupoCoincidencia = null;
      }
    });
  }

  horaEnRango (hora, horaInicio, horaFin)
  {
    return (horaInicio <= hora && hora <= horaFin) || (horaInicio <= hora && hora <= horaFin);
  }

  updateSesion(element,sesion)
  {
    if (environment.debug) console.log("sesion duplicada en el programa");
    //do nothing
  }

  checkAllJornadas(event,programa) {
    if (programa.jornadas)
      programa.jornadas.forEach(jornada => {
        jornada.checked = event.checked;
        if (environment.debug) console.log("marcando jornada "+jornada.descripcion);
        this.checkAllSesiones(event,jornada);
      });
  }

  checkAllSesiones(event,jornada) {
    if (jornada.sesiones)
    {
      let grupo = 0;
      jornada.sesiones.forEach(sesion => {
        if (sesion.grupoCoincidencia == null)
        {
          if (environment.debug) console.log("marcando sesion "+sesion.descripcion);
          sesion.checked = event.checked;
        }else{
          if (grupo==0 || grupo!==sesion.grupoCoincidencia)
          {
            if (environment.debug) console.log("Marco la sesion:"+sesion.descripcion+" del grupo:"+sesion.grupoCoincidencia);
            grupo = sesion.grupoCoincidencia;
            sesion.checked = event.checked;
          }else{
            if (environment.debug) console.log("No marco la sesion:"+sesion.descripcion+" del grupo:"+sesion.grupoCoincidencia);
          }
        }
      });
    }
  }

  checkAllAdicionales(event) {
    if (this.evento.adicionales)
      this.evento.adicionales.forEach(adicional => {
        adicional.checked = event.checked;
      });
  }


  initializeForm()
  {
    this.eventInfoForm = this.formBuilder.group({
      comentariosForm: new FormControl('')
    });
  }

  onSubmit()
  {
    if (environment.debug) console.log("Submit Event Info");
    let evento = this.evento;
    switch ( this.evento.tipo ) {
       case 1:
           this.getProgramasSelected();
           break;
       case 2:
           this.getJornadasSelected();
           break;
       case 3:
           this.getSesionesSelected();
           break;
    }

    this.getAdicionalesSelected();
    if (this.idDocs!==''){
        sessionStorage.setItem("idDocs",this.idDocs);
        this.guardarInscripcion();
    }
    else
    {
       this.restApiService.showError("Debe seleccionar los apartados para los que desea realizar la inscripción.");
    }
  }

  guardarInscripcion()
  {
    let idProyecto = sessionStorage.getItem("idProyecto");
    let contactosSelected = JSON.parse(sessionStorage.getItem("contactosSelected"));
    let idCliente = sessionStorage.getItem("idCliente");

    //if (this.evento)
    this.submit = false;

    if (contactosSelected!==null && contactosSelected.length>0)
    {
      let idDocs = sessionStorage.getItem("idDocs");
      let comentarios = this.eventInfoForm.controls['comentariosForm'].value;

      if (environment.pruebas){
          this.router.navigate(['payment-form']);
      }else{
        if (idProyecto!==null)
        {
              this.restApiService.guardarInscripcion( idProyecto,
                                                  contactosSelected,
                                                  idDocs, idCliente, comentarios
                                                ).subscribe(data =>
              {
                    if (data!==null)
                    {
                      if (data.Data.retorno[0].Estado==1)
                      {
                        if (environment.debug) console.log("Pre-Inscripción guardada correctamente.");
                        //Quitamos que se muestre el mensaje de guardada correctamente a petición de AEE. 07/02/2022
                        //this.restApiService.showSuccess("Pre-Inscripción guardada correctamente. Realice el pago para terminar el proceso de inscripción.");

                        this.router.navigate(['payment-form']);

                      }else{
                        if (environment.debug) console.log("Error al guardar la inscripción: "+data.Data.retorno[0].Mensaje);
                        this.restApiService.showError("Error al guardar la inscripción. Vuelva a intentarlo más tarde. Error: "+data.Data.retorno[0].Mensaje);
                        this.error = true;
                      }
                    }else{
                      this.error = true;
                      if (environment.debug) console.log("Error al guardar la inscripción");
                      this.restApiService.showError("Error al guardar la inscripción. Vuelva a intentarlo más tarde.");
                    }
              });
        }else{
              this.error = true;
              this.restApiService.showError("Se ha producido un error en el proceso de inscripcion.");
        }
      }
    }else{
        this.error = true;
        this.restApiService.showError("Se ha producido un error en el proceso de inscripcion.");
    }
}

  getProgramasSelected()
  {
    this.evento.programas.forEach(programa => {
       if(programa.checked)
       {
          this.addIdDoc(programa);
       }
    });
  }

  getJornadasSelected()
  {
    this.evento.programas.forEach(programa => {
      programa.jornadas.forEach(jornada => {
         if(jornada.checked)
         {
            this.addIdDoc(jornada);
         }
      });
    });
  }

  getSesionesSelected()
  {
    this.evento.programas.forEach(programa => {
      programa.jornadas.forEach(jornada => {
        jornada.sesiones.forEach(sesion => {
           if(sesion.checked)
           {
              this.addIdDoc(sesion);
           }
        });
      });
    });
  }

  getAdicionalesSelected()
  {
    this.evento.adicionales.forEach(adicional => {
       if(adicional.checked)
       {
          this.addIdDoc(adicional);
       }
    });
  }

  addIdDoc(element)
  {
    if (this.idDocs==='')
    {
      this.idDocs = element.idDoc;
    }else{
      this.idDocs = this.idDocs + ","+element.idDoc;
    }
  }

  cancel()
  {
    if (environment.debug) console.log("Cancel");
    this.router.navigate(['init-form']);
    //location.reload();
  }

  checkRadio(jornada,sesion)
  {
    jornada.sesiones.forEach(sesionAux => {
      if (sesionAux.grupoCoincidencia == sesion.grupoCoincidencia)
      {
        if (sesionAux.id !== sesion.id)
          sesionAux.checked = false;
        else
          sesionAux.checked = true;
      }
    });
  }
}
