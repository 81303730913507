<div class="row" [formGroup]="enterpriseForm">
  <div class="col">
    <mat-form-field appearance="outline" class="autocomplete-full-width">
      <mat-label>Población <span class="star">&nbsp;*</span></mat-label>
      <input matInput
             type="text"
             placeholder="Seleccione una población"
             aria-label="Población"
             formControlName="poblacionForm"
             [matAutocomplete]="auto"
             autocomplete="off">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let poblacion of filteredPoblaciones | async" [value]="poblacion">
          {{poblacion}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</div>
