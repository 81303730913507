<ngx-spinner bdColor="rgba(0, 0, 0, 0.1)" size="large" color="var(--blue)" type="square-jelly-box"
             [fullScreen]="true">

  <br/><br/><p style="color: var(--blue); font-weight: bolder; font-size: large"> Cargando... </p>
</ngx-spinner>

<!-- FORMULARIO DE CONTACTO-->
<div id="contact-form" class="container">
    <!--Listado de Contactos -->
    <div *ngIf="this.contactos.length>0">
        <h3 class="eb-heading" id="field_listado_contactos" style="display: block;">Seleccione asistente/s</h3>
        <div class="alert alert-info">
          <i class="material-icons">info</i>
          Seleccione los asistentes que coinciden con el Email: <strong>{{this.email}}</strong>
          <p style="font-size:small">
            Si desea inscribir asistentes con otra dirección de email deberá rellenar un nuevo formulario de inscripción.
          </p>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field>
              <mat-label>Filtrar</mat-label>
              <input matInput (keyup)="applyFilter($event)" placeholder="Filtrar por nombre" #input size="40">
            </mat-form-field>
          </div>
          <div class="col" style="text-align:right">
            <button class="btn btn-success" type="button"(click)="openModalAddContacto()">
              <i class="material-icons" style="vertical-align: middle;" >person_add</i> Añadir Nuevo Contacto
            </button>
          </div>
        </div>

        <div class="row">
          <div class="col" #TABLECONTACTOS>
            <div class="table-responsive">
                <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z2 table" cdkDropList cdkDropListOrientation="horizontal"
                       (cdkDropListDropped)="this.utils.drop($event, this.tableHeaders)" multiTemplateDataRows>
                  <ng-container matColumnDef="checklist">
                    <th mat-header-cell *matHeaderCellDef>
                      <mat-checkbox (change)="checkAllRows($event)"></mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <mat-checkbox [(ngModel)]="element.checked"></mat-checkbox>
                    </td>
                  </ng-container>

                  <!-- Name Column -->
                  <ng-container matColumnDef="nombre" cdkDrag mat-sort-header>
                    <th mat-header-cell *matHeaderCellDef> Apellidos, Nombre </th>
                    <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="tableHeaders"></tr>
                  <tr mat-row *matRowDef="let row; columns: tableHeaders;"></tr>
                </table>
                <div>
                  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>
                </div>
            </div>
          </div>
        </div>

        <hr>

        <!-- Botones de accion -->
        <div class="row" style="text-align:center;">
          <div id="botonesListado" class="col">
            <input type="submit" class="btn btn-default btn-primary" id="btn-submit-listado" value="Siguiente" (click)="onSubmit()"><!--disabled-->&nbsp;
            <input type="button" class="btn btn-default btn-secondary" id="btn-submit-cancelListado" value="Cancelar" (click)="cancel()">
          </div>
        </div>
        <br/><br/>
    </div>

    <!-- Datos Contacto -->
    <div *ngIf="this.contactos == undefined || this.contactos.length==0">
      <app-add-contact-form [contactoModify]="this.contactoModify" [disabled]="this.disabled" [modal]="this.modal" class="col"></app-add-contact-form>
    </div>
</div>
