import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { getPaginatorIntl } from './angular-utils/paginator-intl';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgOtpInputModule } from  'ng-otp-input';
import { CodeInputModule } from 'angular-code-input';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AEERoutingModule } from './aee-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { InitFormComponent } from './init-form/init-form.component';
import { EnterpriseFormComponent } from './enterprise-form/enterprise-form.component';
import { ModalAddSedeFormComponent } from './modal-add-sede-form/modal-add-sede-form.component';
import { SearchContactFormComponent } from './search-contact-form/search-contact-form.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { AddContactFormComponent } from './components/add-contact-form/add-contact-form.component';
import { EventInfoFormComponent } from './event-info-form/event-info-form.component';
import { PaymentFormComponent } from './payment-form/payment-form.component';
import { EndFormComponent } from './end-form/end-form.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { PaisInputComponent } from './components/pais-input/pais-input.component';
import { ProvinciaInputComponent } from './components/provincia-input/provincia-input.component';
import { PoblacionInputComponent } from './components/poblacion-input/poblacion-input.component';
import { CodigoPostalInputComponent } from './components/codigo-postal-input/codigo-postal-input.component';
import { SedeSelectComponent } from './components/sede-select/sede-select.component';
import { DepartamentoSelectComponent } from './components/departamento-select/departamento-select.component';
import { InitFormContComponent } from './init-form-cont/init-form-cont.component';
import { ConfirmFormContComponent } from './confirm-form-cont/confirm-form-cont.component';
import { ProgressBarContComponent } from './components/progress-bar-cont/progress-bar-cont.component';
import { ContactFormContComponent } from './contact-form-cont/contact-form-cont.component';
import { AddContactFormContComponent } from './components/add-contact-form-cont/add-contact-form-cont.component';
import { EnterpriseFormContComponent } from './enterprise-form-cont/enterprise-form-cont.component';
import { AddEnterpriseFormContComponent } from './components/add-enterprise-form-cont/add-enterprise-form-cont.component';
import { ModalSelectEnterpriseFormContComponent } from './modal-select-enterprise-form-cont/modal-select-enterprise-form-cont.component';
import { WorkgroupFormCont } from './workgroup-form-cont/workgroup-form-cont.component';

import { searchFilterPipe } from "./angular-utils/search-filter.pipe";
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { MatDialogModule } from '@angular/material/dialog';

import { CountdownModule } from 'ngx-countdown';

@NgModule({
  declarations: [
    AppComponent,
    InitFormComponent,
    EnterpriseFormComponent,
    ModalAddSedeFormComponent,
    SearchContactFormComponent,
    ContactFormComponent,
    AddContactFormComponent,
    EventInfoFormComponent,
    PaymentFormComponent,
    EndFormComponent,
    ProgressBarComponent,
    PaisInputComponent,
    ProvinciaInputComponent,
    PoblacionInputComponent,
    CodigoPostalInputComponent,
    SedeSelectComponent,
    DepartamentoSelectComponent,
    searchFilterPipe,
    InitFormContComponent,
    ConfirmFormContComponent,
    ProgressBarContComponent,
    ContactFormContComponent,
    AddContactFormContComponent,
    EnterpriseFormContComponent,
    AddEnterpriseFormContComponent,
    ModalSelectEnterpriseFormContComponent,
    WorkgroupFormCont
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatCheckboxModule,
    MatRadioModule,
    MatTabsModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    MatSelectModule,
    MatTooltipModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    HttpClientModule,
    NgxSpinnerModule,
    NgxMatSelectSearchModule,
    FontAwesomeModule,
    AEERoutingModule,
    MatDialogModule,
    NgOtpInputModule,
    CodeInputModule,
    CalendarModule.forRoot({
     provide: DateAdapter,
     useFactory: adapterFactory,
    }),
    CountdownModule,
  ],
  providers: [
    { provide: MatPaginatorIntl, useValue: getPaginatorIntl()},
    { provide: MAT_DATE_LOCALE, useValue: 'es-Es'},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

