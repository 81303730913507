import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RestApiService } from '../services/rest-api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { ChangeDetectorRef } from '@angular/core';
import { environment } from '../../environments/environment';
import { Empresa } from '../Model/Empresa';
import { Evento } from '../Model/Evento';

@Component({
  selector: 'app-search-contact-form',
  templateUrl: './search-contact-form.component.html',
  styleUrls: ['./search-contact-form.component.scss']
})
export class SearchContactFormComponent implements OnInit {
  environment = environment;
  title = environment.app.title;
  searchContactForm: FormGroup;
  submit: boolean = false;
  error: boolean = false;
  evento: Evento;
  idCliente: any;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private restApiService: RestApiService,
              private spinner: NgxSpinnerService,
              private cdRef:ChangeDetectorRef) { }

  //Evita que se produzca el ERROR Error: ExpressionChangedAfterItHasBeenCheckedError
  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
        this.spinner.show();
        this.initializeForm();
        this.spinner.hide();
        /*this.restApiService.getEnterpriseData().subscribe( dataEnterprise => {
            if (dataEnterprise){
              if (environment.debug) console.log("Información de la Empresa obtenida");
              this.empresa.cif=dataEnterprise[0]?.Nif;
              this.empresa.razonSocial=dataEnterprise[0]?.RazonSocial;
              this.empresa.pais=dataEnterprise[0]?.Pais;
              this.empresa.codigoPostal=dataEnterprise[0]?.CodPostal;
              this.empresa.provincia=dataEnterprise[0]?.Provincia;
              this.empresa.poblacion=dataEnterprise[0]?.Ciudad;
              this.empresa.direccion=dataEnterprise[0]?.Direccion;
            }else{
              if (environment.debug) console.log("Error al obtener la información de la Empresa");
            }
            this.spinner.hide();
        });*/
  }

  initializeForm()
  {
    this.idCliente = sessionStorage.getItem("idCliente");
    if (this.idCliente!==undefined && this.idCliente!==null && this.idCliente!=='')
    {
      this.searchContactForm = this.formBuilder.group({
            emailAsistenteForm: new FormControl('', [Validators.required, Validators.email])
      });
      this.error = false;
    }else{
      this.error = true;
      this.restApiService.showError("Debe seleccionar o dar de alta la empresa.");
    }
  }

  onSubmit()
  {
    if (environment.debug) console.log("Submit Search Contact");
    //this.router.navigate(['search-contact-form']);
        let email = this.searchContactForm.get('emailAsistenteForm').value;

        if (email!==undefined && email!=='')
        {
          sessionStorage.setItem("email",email);
          sessionStorage.removeItem("emailAdicional2");
          this.submit=true;
        }else{
          this.restApiService.showError("Debe indicar un email para el/los asistente/s.");
        }
  }

  cancel()
  {
    if (environment.debug) console.log("Cancel");
    this.router.navigate(['init-form']);
    //location.reload();
  }
}
