<ngx-spinner bdColor="rgba(0, 0, 0, 0.1)" size="large" color="var(--blue)" type="square-jelly-box"
             [fullScreen]="true">

  <br/><br/><p style="color: var(--blue); font-weight: bolder; font-size: large"> Cargando... </p>
</ngx-spinner>

<!-- FORMULARIO DE CONTACTO-->
<div id="contact-form" class="container">
    <p style="text-align:center;padding-top:20px;">
        <img class="logo" src="/assets/img/aee.png" alt="AEE" align="middle">
    </p>

    <h1>{{titleRegistro}}</h1>

    <hr/>
    <app-progress-bar-cont step="2"></app-progress-bar-cont>
    <hr/>
    <!-- Datos Contacto -->
    <div>
      <app-add-contact-form-cont [contactoModify]="this.contactoModify" [modal]="this.modal" class="col"></app-add-contact-form-cont>
    </div>
</div>
