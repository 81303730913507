import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'searchFilter' })
export class searchFilterPipe implements PipeTransform {
    transform(objArray: any[], filter: string): any[] {
        if (!filter)
            return objArray;

        return objArray.filter(item => {
                    if (item.nombre!==undefined)
                    {
                      if (item.nombre.toLowerCase().includes(filter.toLowerCase()))
                        return true;
                    }else if (item.direccion!==undefined){
                      if (item.direccion.toLowerCase().includes(filter.toLowerCase()))
                        return true;
                    }
                    return false;
                });
    }
}
